<template>
    <a-result :status="status" :title="title" :sub-title="subTitle">
      <template #extra>
        <a-button type="primary" @click="route" v-if="viewButton">
          {{ buttonContent }}
        </a-button>
      </template>
    </a-result>
</template>

<script>
export default {
  name: "Result",
  props: {
    status: {
      type: Number,
      default: 403
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    buttonContent: {
      type: String
    },
    routePath: {
      type: String
    },
    enableRoute: {
      type: Boolean,
      default: false
    },
    viewButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    route () {
      if (this.enableRoute) {
        this.$router.push(this.routePath)
      }
    }
  }
}
</script>

<style scoped>

</style>
