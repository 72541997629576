<template>
  <div class="main_page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MainPage'
}
</script>

<style scoped>
.main_page {
  height: 100%;
  width: 100%;
}
</style>
