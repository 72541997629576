<template>

  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
    <el-breadcrumb-item>结算</el-breadcrumb-item>
  </el-breadcrumb>

  <div class="title" style="text-align: center;margin-top:3%;font-size:30px">我的积分
  </div>

  <el-row>
  <el-input v-model="user.credit" placeholder="我的积分" disabled  style="margin-top:20px;margin-left:15%;width:40%"/>
    <el-button style="height:50%;margin-top:20px;margin-left:5%;" @click="getCredit">查询</el-button>
    <el-button type="success" style="height:50%;margin-top:20px;margin-left:1%;" @click="dialogVisible = true">提现</el-button>
  </el-row>
  <div class="title" style="text-align: center;margin-top:2%;font-size:30px">提现记录
  </div>
<div class="history" v-for="item in list" :key="item">
  <el-card class="box-card" style="width:70%;height:150px;text-align: center">
    <template #header>
      <div class="card-header">
        <span>{{ '时间 : '+item.withdraw_time }}</span>
      </div>
    </template>
    <div style="font-size: 25px">
      {{ '积分  - ' + item.withdraw_credit }}</div>
  </el-card>

</div>


  <el-dialog
    v-model="dialogVisible"
    title="请输入提现积分"
    width="30%"
  >
    <el-input v-model="user.money" placeholder="提现积分"  style="margin-top:20px;margin-left:10%;width:80%"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="checkVisible = true"
        >提现</el-button
        >
      </span>
    </template>
  </el-dialog>



  <el-dialog
    v-model="checkVisible"
    title="确认密码"
    width="30%"

  >
    <el-input   v-model="input" placeholder="请输入密码" show-password/>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkVisible=false;dialogVisible = false">取消</el-button>
        <el-button type="success" @click="withdraw"
        >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  toRaw,
  ref,
  reactive,
  defineComponent,
  h,
  createVNode,
  watch,
  toRefs
} from "vue";

import { Form, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import moment from "moment";
import axios from 'axios'
import {post,get} from '../../main.js';
import { message } from 'ant-design-vue'
import { ElMessageBox } from 'element-plus'
import router from '@/router'
const dialogVisible = ref(false);
const checkVisible = ref(false);
const input=ref('');
const user = reactive({
  user_id: "",
  money: "",
  credit:""
});
const list = ref([]);


const getCredit = ()=>{
  user.user_id=JSON.parse(window.sessionStorage.getItem('user')).user_id;
  get("/statistics/credit" ,{user_id:user.user_id}).then((res)=>{
    user.credit = res.data.data;
    message.success("查询成功");
  })
};

// 前端密码加密
var keyStr = 'ABCDEFGHIJKLMNOP' + 'QRSTUVWXYZabcdef' + 'ghijklmnopqrstuv' + 'wxyz0123456789+/' + '='
function encode64 (input) {
  var output = ''
  var chr1; var chr2; var chr3 = ''
  var enc1; var enc2; var enc3; var enc4 = ''
  var i = 0
  do {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4)
    chr1 = chr2 = chr3 = ''
    enc1 = enc2 = enc3 = enc4 = ''
  } while (i < input.length)
  return output
}


const withdraw =() =>{
  if(encode64(input.value)==window.sessionStorage.getItem('pwd')){
    checkVisible.value=false;dialogVisible.value = false;
    user.user_id=JSON.parse(window.sessionStorage.getItem('user')).user_id;
    post("/statistics/withdraw",user).then((ref)=>{
      if(ref.data.message=="提取积分不能为负数"){
        message.error("提取积分不能为负数");
      }
      else{
        if(ref.data.message=="success"){
          message.success("提现成功");
          user.money="";
          location.reload();
        }
        else{
          message.error("积分不足");
        }
      }


    })
  }
  else{
    checkVisible.value=false;dialogVisible.value = false;
    message.error("密码错误");
  }

}

const getHistory =()=>{
  user.user_id=JSON.parse(window.sessionStorage.getItem('user')).user_id;
  get("/statistics/withdrawHistory",{user_id:user.user_id}).then((res)=>{
      list.value=res.data.data;
  })
}

getHistory();

</script>




<style  scoped>
html {
  height: auto;
  width: 100%;
}

.history{
  display: flex;
  flex-direction: row;
  /*主轴为水平方向*/
  justify-content: center;
  align-items: center;

}

</style>
