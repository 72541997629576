<template>
  <!--  顶部面包屑-->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }"
    >返回主页</el-breadcrumb-item
    >
    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
    <el-breadcrumb-item>所有任务</el-breadcrumb-item>
  </el-breadcrumb>
  <div style="width: 80%;margin-left: 50px; margin-top: 30px">
    <div>
      <div class="grid-content bg-purple">
        <el-table :data="tableData.filter((data) =>!search || data.name.toLowerCase().includes(search.toLowerCase()))"
                  style="width: 100%" border :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column  :show-overflow-tooltip="true" align="center" label="发布日期" prop="task_publish_time" sortable width="170px" />
          <el-table-column  :show-overflow-tooltip="true" align="center" label="任务" prop="task_title" sortable width="120px"/>
          <el-table-column  :show-overflow-tooltip="true" align="center" label="分发数" prop="task_max_distribution" sortable  />
          <el-table-column  :show-overflow-tooltip="true" align="center" label="标注次数" prop="task_mark_count" sortable />
          <el-table-column  :show-overflow-tooltip="true" align="center" label="限时" prop="task_limit_time" sortable  />
          <el-table-column  :show-overflow-tooltip="true" align="center" label="状态" prop="task_status" sortable >
          </el-table-column>
          <el-table-column align="center" width="150">
            <template #header>
              操作
              <!-- <el-input v-model="search" size="mini" placeholder="Search" /> -->
            </template>
            <template #default="scope">
<!--              查看领取详情-->
              <el-tooltip
                class="item"
                effect="dark"
                content="查看该任务的领取详情"
                placement="top"
                :enterable="false">
                <el-button circle icon="el-icon-view" size="mini" type="success" @click="handleDetail(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
<!--              导出任务结果-->
              <el-tooltip
                class="item"
                effect="dark"
                content="导出该任务的标注结果"
                placement="top"
                :enterable="false">
                <el-button :disabled="!(scope.row.task_status == '已结束')" circle icon="el-icon-download" size="mini" type="primary" @click="exportResult(scope.row)">
                </el-button>
              </el-tooltip>
<!--              结束该任务-->
              <el-tooltip
                class="item"
                effect="dark"
                content="结束已发布的该任务"
                placement="top"
                :enterable="false">
                <el-button :disabled="(scope.row.task_status == '已结束')" size="mini" type="danger" circle icon="el-icon-check" @click="handleEnd(scope.$index, scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
<!--        分页-->
        <el-row justify="center">
          <el-pagination
            v-model:currentPage="leftParams.currentPage"
            :page-sizes="[1, 5, 10, 20]"
            :page-size="leftParams.pageSize"
            layout="sizes, prev, pager, next"
            :total="total1"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </div>
    </div>

   <el-dialog v-model="detailVis" title="详细信息" width="80%" center>
      <!-- <h3 style="font-weight: bolder;color: #13ce66;font-size: larger">任务一所有标注者详细信息:</h3> -->

          <div class="grid-content bg-purple">
            <el-table
              :data="tableData2.filter((data) =>!search || data.name.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%" border :default-sort="{ prop: 'date', order: 'descending' }">
              <el-table-column align="center" label="标注者" prop="user_name" sortable />
              <el-table-column align="center" label="领取时间" prop="start_time" sortable />
              <el-table-column align="center" label="结束时间" prop="end_time" sortable >
                <template #default="scope">
                  {{scope.row.end_time==null?'未完成':scope.row.end_time}}
                </template>
              </el-table-column>
              <el-table-column align="center" label="状态" prop="status" sortable />
              <el-table-column align="center">
                <template #header>
                <!-- <el-input v-model="search" size="mini" placeholder="Search" /> -->
                操作
              </template>
                <template #default="scope">
                  <el-button :disabled="!(scope.row.status=='未完成' && scope.row.showCancel)" size="mini" type="danger" @click="handleCancel(scope.$index, scope.row)">撤回</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row justify="center">
              <el-pagination style="margin-top: 1.25rem;" background layout="prev, pager, next"
                             :page-size="rightParams.pageSize" @current-change="currentChange2" :total="total2">
              </el-pagination>
            </el-row>
          </div>
          <template #footer>
          <span class="dialog-footer">
            <el-button @click="detailVis = false">退出</el-button>
          </span>
          </template>
   </el-dialog>


    </div>

</template>

<script>
import { View } from '@element-plus/icons'
  export default {
  components: {View},
    data() {
      return {
        detailVis: false,
        tableData: [],
        total1: 1000,
        tableData2: [],
        total2: 10,
        search: '',
        leftParams: {
          currentPage: 1,
          pageSize: 5,
          keyword: ''
        },
        rightParams: {
          currentPage: 1,
          pageSize: 5,
          taskId: ''
        },
        form: {
          name: '',
          desc: '',
          number: '',
          reward: '',
          maxNumber: '',
          date1: '',
          date2: ''
        },
      }
    },
    created() {
      this.loadTaskList();
    },
    methods: {
    // 导出标记结果
      async exportResult(row) {
        console.log(row)
        const message= this.$message.success('数据正在导出中，请您稍等片刻，准备完成后将自动下载')

        let task_id = row.task_id
        const res = await this.$http.get("/getLabelResult?taskId=" + task_id)
        let binaryData = [];
        binaryData.push(res.data);
        let objectUrl  = window.URL.createObjectURL(new Blob(binaryData));

        let a = document.createElement('a');
        a.href = objectUrl;
        a.download = '下载.csv';
        a.click();
        message.close()

        // 显示缓冲界面
      },
      // 结束任务
      handleEnd(index, row) {

        this.$confirm('确认结束任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _this = this;
          // 根据id查询所有的右侧列表
          this.$http({
            method: "get",
            url: "/task/end",
            params: {
              "taskId": row.task_id
            }
          }).then(function(result) {
            if (result.data.status == 200) {
              _this.$message({
                showClose: true,
                message: '成功',
                type: 'success'
              });
              location.reload();
            } else {
              _this.$message({
                showClose: true,
                message: '很遗憾:' + result.data.msg,
                type: 'error'
              });
            }
          })
        }).catch(() => {

        });

      },
      loadTaskList: function() {
        let _this = this;
        this.$http({
          method: "get",
          url: "/task/taskList?userId="+JSON.parse(window.sessionStorage.getItem("user")).user_id,
          params: this.leftParams
        }).then(function(result) {
          console.log(result.data)
          if (result.data.status == 200) {
            // _this.$message({
            //   showClose: true,
            //   message: '成功',
            //   type: 'success'
            // });
            _this.tableData = result.data.data.list
            _this.total1 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },
      // 右侧列表
      handleDetail(index, row) {
        let _this = this;
        this.rightParams.taskId = row.task_id;
        // 根据id查询所有的右侧列表
        this.$http({
          method: "get",
          url: "/task/drawList",
          params: this.rightParams
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            let list = result.data.data.list
            let curTime = new Date()

            for(let i=0;i<list.length;i++){
                let data = list[i];
                let startTime = new Date(data.start_time)
                startTime.setHours(startTime.getHours()+row.task_limit_time)
                if(curTime.getTime() > startTime.getTime()){
                    data.showCancel = true
                }
            }
            _this.tableData2 =list
            _this.total2 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
        this.detailVis = true
      },
      // 右侧列表
      handleDetailHasTask(index, row) {
        let _this = this;
        // 根据id查询所有的右侧列表
        this.$http({
          method: "get",
          url: "/task/drawList",
          params: this.rightParams
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            _this.tableData2 = result.data.data.list
            _this.total2 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },
      // 撤回
      handleCancel(index, row) {

        this.$confirm('确定撤回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _this = this;
          // 根据id查询所有的右侧列表
          this.$http({
            method: "get",
            url: "/task/cancel",
            params: {
              "drawId": row.draw_id
            }
          }).then(function(result) {
            if (result.data.status == 200) {
              _this.$message({
                showClose: true,
                message: '成功',
                type: 'success'
              });
              this.rightParams.currentPage = 1;
              this.handleDetailHasTask();
            } else {
              _this.$message({
                showClose: true,
                message: '很遗憾:' + result.data.msg,
                type: 'error'
              });
            }
          })
        }).catch(() => {

        });



      },

      currentChange1: function(val) {
        this.leftParams.currentPage = val;
        this.loadTaskList();
        this.tableData2 = [];
      },

      currentChange2: function(val) {
        this.rightParams.currentPage = val;
        this.handleDetailHasTask();
      },
      // 当页面大小变化时触发
      async handleSizeChange(newSize) {
        this.leftParams.pageSize = newSize
        await this.loadTaskList()
      },
      // 当页面编号变化时触发
      async handleCurrentChange(newPage) {
        this.leftParams.currentPage = newPage
        await this.loadTaskList()
      },
    },
  }
</script>

<style scoped>

</style>
