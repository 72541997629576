<template>
  <!--  顶部面包屑-->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }"
      >返回主页</el-breadcrumb-item
    >
    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
    <el-breadcrumb-item>标注任务</el-breadcrumb-item>
  </el-breadcrumb>
  <!--  卡片视图区-->
  <el-row justify="center">

    <!--      任务表格-->
    <el-row justify="center" class="task_table">
      <el-table
        border
        :highlight-current-row="true"
        :data="drawList"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <!--          任务名称-->
        <el-table-column
          :show-overflow-tooltip="true"
          prop="taskInfo.taskTitle"
          align="center"
          label="任务名称"
          width="220"
        >
        </el-table-column>
        <!--          截止时间-->
        <el-table-column
          sortable
          :show-overflow-tooltip="true"
          prop="expectedEndTime"
          align="center"
          label="截止时间"
          width="160"
        >
        </el-table-column>
        <!--          剩余样本-->
        <el-table-column
          sortable
          :show-overflow-tooltip="true"
          prop="unFinishNum"
          align="center"
          label="剩余"
          width="80"
        >
        </el-table-column>
        <!--          进度条-->
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          align="center"
          label="剩余比例"
        >
          <template #default="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="18"
              :percentage="scope.row.finishRatio"
              :status="scope.row.finishStatus"
            />
          </template>
        </el-table-column>
        <!--          紧急程度-->
        <el-table-column
          :show-overflow-tooltip="true"
          prop="urgentLevel"
          align="center"
          width="120"
          :filters="[
              { text: '不紧急', value: 'BJJ' },
              { text: '紧急', value: 'JJ' },
              { text: '非常紧急', value: 'FCJJ' },
            ]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
          label="紧急程度"
        >
          <template #default="scope">
            <el-tag
              type="primary"
              v-if="scope.row.urgentLevel == 'BJJ'"
            >不紧急</el-tag
            >
            <el-tag
              type="warning"
              v-if="scope.row.urgentLevel == 'JJ'"
            >紧急</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.urgentLevel == 'FCJJ'"
            >非常紧急</el-tag
            >
          </template>
        </el-table-column>
        <!--          操作-->
        <el-table-column
          :show-overflow-tooltip="true"
          width="130"
          align="center"
          label="操作">
          <template #default="scope">
            <!--         任务详情-->
            <el-tooltip
              class="item"
              effect="dark"
              content="查看任务详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-view"
                circle
                size="mini"
                @click="viewTaskInfo(scope.row)"
              ></el-button>
            </el-tooltip>
            <!--        开始标注-->
            <el-tooltip
              class="item"
              effect="dark"
              content="开始任务标注"
              placement="top"
              :enterable="false">
              <el-button
                type="success"
                icon="el-icon-edit"
                circle
                @click="beginAnnotate(scope.row)"
                size="mini"
              ></el-button>
            </el-tooltip>
            <!--        提交标注/再次领取-->
              <el-popconfirm title="提交后不能继续修改标注内容，您确定要提交吗?" @confirm="commitAnnotate(scope.row)">
                <template #reference>
                    <el-button
                      type="danger"
                      icon="el-icon-check"
                      circle
                      size="mini"
                    ></el-button>
                </template>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!--      分页区-->
    <el-row justify="center" style="width: 100%">
      <el-pagination
        v-model:currentPage="queryInfo.pageNum"
        :page-sizes="[1, 5, 10, 20]"
        :page-size="queryInfo.pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
  </el-row>
  <!--  查看任务详情的对话框-->
  <el-dialog v-model="taskInfoVis" width="40%">
    <one-task-info :task-info="taskInfo"></one-task-info>
    <template #footer>
      <el-row justify="center">
        <el-button @click="taskInfoVis = false">退出查看</el-button>
      </el-row>
    </template>
  </el-dialog>
  <!--  成功提交领取之后，进行下一次领取的对话框-->
  <el-dialog v-model="drawNextVis" width="30%" title="再次领取" center>
    <el-row justify="center" style="font-size: 16px">
      您已完成本次领取的所有标注，并已成功提交，您可以再次领取该任务~
    </el-row>
    <template #footer>
      <el-row justify="center">
        <el-button size="mini" @click="drawNextVis = false" type="warning">暂不领取</el-button>
        <el-button size="mini" @click="drawTask" type="primary">立即领取</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import OneTaskInfo from "@/components/task/OneTaskInfo";
export default {
  async mounted() {
    await this.getDrawList()
  },
  name: "DrawList",
  components: {
    "one-task-info": OneTaskInfo,
  },
  data() {
    return {
      // 任务信息
      drawList: [],
      // 任务详情对话框
      taskInfoVis: false,
      // 领取下一次对话框
      drawNextVis: false,
      // 当前任务详情
      taskInfo: {},
      total: 1,
      queryInfo: {
        pageNum: 1,
        pageSize: 5
      },
      taskId: null
    };
  },
  methods: {
    // 获取领取信息
    async getDrawList() {
      let userId = JSON.parse(window.sessionStorage.getItem('user')).user_id
      const { data: res } = await this.$http.post("/task/annotater/labelTask/getDrawInfo?userId=" + userId, this.queryInfo);
      if (res.code === 200) {
        this.drawList = res.data;
        for (let i=0; i<this.drawList.length; i++){
          // 添加finishRatio字段
          this.drawList[i].finishRatio = parseInt(100*(this.drawList[i].unFinishNum/this.drawList[i].totalNum))
          // 添加finishStatus字段
          if (this.drawList[i].urgentLevel == 'BJJ') this.drawList[i].finishStatus = ''
          if (this.drawList[i].urgentLevel == 'JJ') this.drawList[i].finishStatus = 'warning'
          if (this.drawList[i].urgentLevel == 'FCJJ') this.drawList[i].finishStatus = 'exception'
        }
      } else {
        this.$message.error('获取领取记录失败');
      }
      // 获取分页总数
      let arg = {
        pageSize: this.queryInfo.pageSize,
        userId: JSON.parse(window.sessionStorage.getItem('user')).user_id,
        pageKind: "drawList"
      }
      const { data: res2 } = await this.$http.get("/template/getPageTotalNum", {params: arg});
      this.total = res2.data
    },
    // 筛选消息状态
    filterStatus(value, row) {
      return row.urgentLevel === value;
    },
    // 查看任务详情
    viewTaskInfo(row) {
      this.taskInfoVis = true;
      this.taskInfo = row.taskInfo;
      this.taskInfo.taskType = this.taskInfo.taskKind
      this.taskInfo.taskReward = this.taskInfo.taskAward
      this.taskInfo.taskReq = this.taskInfo.taskRequire
    },
    // 当页面大小变化时触发
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getDrawList()
    },
    // 当页面编号变化时触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getDrawList()
    },
    // 开始标注，跳转到标注页面
    beginAnnotate (row) {
      let drawId = row.drawId
      this.$router.push({
        path: '/task/annotate',
        query: {
          drawId: drawId
        }
      })
    },
    // 提交单词标注结果
    async commitAnnotate (row) {
      // 检查本次的剩余数量是否为0
      if (row.unFinishNum !== 0) {
        this.$message.error('请先完成所有标注后再提交！')
        return
      }
      // 像后端发请求
      const { data: res } = await this.$http.post("/task/annotater/labelTask/setDrawFinish?drawId=" + row.drawId);
      if (res.code == 200) {
        this.$message.success(res.message)
        // 弹出再次领取对话框
        this.drawNextVis = true
        this.taskId = row.taskId
      } else {
        this.$message.error(res.message)
      }
      // 重新拉取列表内容
      await this.getDrawList()
    },
    // 再次领取任务
    async drawTask (row) {
      const data = {
        user_id: JSON.parse(window.sessionStorage.getItem("user")).user_id,
        task_id: this.taskId,
        start_time: this.getTime()
      };
      const res = await this.$http.post("/task/annotater/manageTask/drawTask", data);
      if (res.data.code == 200) {
        this.$message.success({message: res.data.message, center: true, duration: 1500,});
      } else if (res.data.code == 500) {
        this.$message.error({message: res.data.message, center: true, duration: 1500,});
      }
      // 再次拉取列表内容
      await this.getDrawList()
      // 关闭对话框
      this.drawNextVis = false
    },
    // 获取时间YYYY-MM-DD HH:MM:SS
    getTime() {
      let date=new Date();
      let year=date.getFullYear();
      let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
      let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
      let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
      let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
      // 拼接
      return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    }
  }
};
</script>

<style scoped>
.task_table {
  margin: 30px;
}
</style>
