<template>
  <div id="forget">
    <div class="top">
      <img src="../../assets/imgs/login/banner.png" style="height: 120px; width: 100%" />
    </div>


    <div class="main">
      <div style="margin-top: 20px">
        <a @click="back" style="color: #39c64e; float: left; margin-left: 70px"
        >登录></a
        >
        <div style="float: left; margin-left: 10px">邮箱找回</div>
      </div>

      <div class="forget-content" style="margin-top: 50px">
        <router-view></router-view
        ><!-- 路由视图，动态加载路由对应界面 -->
      </div>

    </div>


    <div class="home-bottom">
      <div class="home-bottom-inform" style="text-align: center;font-size:10px">
        商务合作： 15236149131@xjtu.edu.cn
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;内容合作
        ：lkm@163.com/15236149131
      </div>
      <div class="home-bottom-inform" style="text-align: center;font-size:10px">
        京ICP备16021487号-5 京公网安备11010802027588号
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive,defineComponent } from "vue";

import { useRouter } from "vue-router";

const router = useRouter(); //获取路由器

const formRef = ref();



//const current = ref(0);
const next = () => {
  current.value++;
};



const back = () => {
  router.push({ path: "/login" });
};

</script>

<style scoped>
html {
  height: 100%;
  width: 100%;
}

#forget {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  min-width: 900px;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  overflow: auto;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}



.home-bottom {
  bottom: 0;
  height: 38px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.home-bottom-inform {
  font-size: 10px;
  align-content: center;
  /*color: #e79229;*/
}

.main{
  height:80%;
  width:100%;

}

.top {
  height: 20%;
  width: 100%;
  background-size: cover;
  display: flex;

  flex-direction: column;
}
</style>
