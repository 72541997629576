<template>
  <!--  顶部面包屑-->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
    <el-breadcrumb-item>模板管理</el-breadcrumb-item>
    <el-breadcrumb-item>定制模板</el-breadcrumb-item>
  </el-breadcrumb>
  <div class="main_page">
<!--    进度条-->
    <el-row justify="center" style="margin-top: 10px; margin-bottom: 20px">
      <el-steps :space="180" :active="currentStep" finish-status="success" align-center>
        <el-step title="第一步" description="首先，请您选择该模板适用的数据类型"></el-step>
        <el-step title="第二步" description="请您输入模板的相关信息"></el-step>
        <el-step title="第三步" description="请按照您的需求，为模板添加指定问题"></el-step>
        <el-step title="第四步" description="发布前，请您先预览您的模板"></el-step>
      </el-steps>
    </el-row>
<!--    视图区-->
    <div class="view_zone">
      <el-scrollbar>
<!--        第一步-->
        <el-row justify="center" v-if="currentStep==0" style="margin-top: 30px">
          <el-row justify="space-around">
<!--            图片-->
            <el-col :span="5">
              <el-image :src="imgUrl.image"></el-image>
              <el-row justify="center">
                <el-button v-if="templateInfo.contentKind=='image'" type="success" size="medium" @click="templateInfo.contentKind='image'">图片媒体</el-button>
                <el-button v-else size="medium" @click="templateInfo.contentKind='image'">图片媒体</el-button>
              </el-row>
            </el-col>
<!--            文本-->
            <el-col :span="5">
              <el-image :src="imgUrl.text"></el-image>
              <el-row justify="center">
                <el-button v-if="templateInfo.contentKind=='text'" type="success" size="medium" @click="templateInfo.contentKind='text'">文本媒体</el-button>
                <el-button v-else size="medium" @click="templateInfo.contentKind='text'">文本媒体</el-button>
              </el-row>
            </el-col>
<!--            视频-->
            <el-col :span="5">
              <el-image :src="imgUrl.video"></el-image>
              <el-row justify="center">
                <el-button v-if="templateInfo.contentKind=='video'" type="success" size="medium"  @click="templateInfo.contentKind='video'">视频媒体</el-button>
                <el-button v-else size="medium"  @click="templateInfo.contentKind='video'">视频媒体</el-button>
              </el-row>
            </el-col>
<!--            音频-->
            <el-col :span="5">
              <el-image :src="imgUrl.audio"></el-image>
              <el-row justify="center">
                <el-button v-if="templateInfo.contentKind=='audio'" type="success" size="medium"  @click="templateInfo.contentKind='audio'">音频媒体</el-button>
                <el-button v-else size="medium"  @click="templateInfo.contentKind='audio'">音频媒体</el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
<!--        第二步-->
        <el-row justify="center" v-if="currentStep==1">
          <el-form
            label-position="left"
            label-width="100px"
            style="width:60%"
          >
            <el-form-item label="模板名称 " prop="templateName">
              <el-input v-model="templateInfo.templateName" placeholder="请给模板起一个标题"></el-input>
            </el-form-item>
            <el-form-item label="模板介绍" prop="templateDesc">
              <el-input v-model="templateInfo.templateDesc" type="textarea" placeholder="请输入对模板的介绍文字，将显示在模板海报中"></el-input>
            </el-form-item>
            <el-form-item label="是否公开">
              <el-switch v-model="templateInfo.publicAccess"></el-switch>
            </el-form-item>
          </el-form>
        </el-row>
<!--        第三步-->
        <el-row justify="center" v-if="currentStep==2">
<!--        增加新的问题-->
          <el-row justify="center">
            <el-button size="mini" @click="addAttrVis=true"><el-icon class="plus-icon"><plus /></el-icon>添加问题</el-button>
          </el-row>
          <el-divider></el-divider>
<!--          显示当前问题-->
          <el-row justify="center">
            <!--        每个属性（题目）-->
            <el-row class="attribute"
                    v-for="(attr, idx) in templateInfo.attrs" :key="idx">
              <el-row justify="center" style="margin: 8px">
                <el-tooltip
                  effect="dark"
                  :content="attr.attrDesc"
                  placement="top"
                >
                  <el-button type="info" size="mini">第{{idx+1}}题:{{attr.attrName}}</el-button>
                </el-tooltip>
              </el-row>
              <div style="margin-top: 10px">
                <el-button @click="moveArrForward(idx)" size="mini" type="success" circle style="margin-left: 20px"><el-icon><caret-top /></el-icon></el-button>
                <el-button @click="moveArrBackward(idx)" size="mini" type="warning" circle><el-icon><caret-bottom /></el-icon></el-button>
                <el-popconfirm title="确定删除该问题吗?" @confirm="deleteArr(idx)">
                  <template #reference>
                    <el-button size="mini" type="danger" circle><el-icon><delete /></el-icon></el-button>
                  </template>
                </el-popconfirm>
              </div>
              <!--          选择器-->
              <el-select
                style="width: 85%"
                v-if="attr.attrType=='select'"
                :multiple="attr.attributeProperty.allowMulti"
                clearable
                v-model="value" :placeholder="attr.attrDesc">
                <el-option
                  v-for="item in attr.attrValues"
                  :key="item.valueId"
                  :label="item.valueName"
                  :value="item.valueId"
                >
                </el-option>
              </el-select>
              <!--          输入框-->
              <el-input
                v-if="attr.attrType=='input'"
                style="width: 85%"
                autosize
                type="textarea"
                :placeholder="attr.attributeProperty.placeHolder"
              />
            </el-row>
          </el-row>
        </el-row>
<!--        第四步-->
        <el-row justify="center" v-if="currentStep==3"><el-result
          icon="success"
          title="预览并提交"
          sub-title="您已完成模板创建，提交前可以先预览模板，确认无误后再提交"
        >
          <template #extra>
            <el-button size="mini" @click="previewVis=true">预览模板</el-button>
            <el-button size="mini" @click="commitVis=true">提交模板</el-button>
          </template>
        </el-result>
        </el-row>
      </el-scrollbar>
    </div>
<!--    操作按钮-->
    <el-affix position="bottom" :offset="70">
      <el-row justify="center" class="oparate_zone">
        <el-button @click="prevStep" type="primary" size="mini"> <el-icon><caret-left /></el-icon>上一步</el-button>
        <el-popconfirm
          confirm-button-text="确认退出"
          cancel-button-text="我再想想"
          icon-color="orange"
          title="退出后不会保存编辑内容，您确定放弃编辑吗？"
          @confirm="giveup"
        >
          <template #reference>
            <el-button type="danger" size="mini">放弃编辑</el-button>
          </template>
        </el-popconfirm>
        <el-button @click="nextStep" type="primary" size="mini">下一步<el-icon><caret-right /></el-icon></el-button>
      </el-row>
    </el-affix>
  </div>

<!--    提交对话框-->
  <el-dialog v-model="commitVis" title="提交结果" width="30%" center>
    <span>
      您已完成所有步骤,是否提交创建结果?
    </span>
    <template #footer>
      <span>
        <el-button @click="commitVis = false">暂不提交</el-button>
        <el-button type="primary" @click="commitTemplate">立即提交</el-button>
      </span>
    </template>
  </el-dialog>
<!--    模板预览对话框-->
  <el-dialog v-model="previewVis" center width="70%">
    <span>
      <one-template :template-info="templateInfo"></one-template>
    </span>
    <template #footer>
      <span>
        <el-button @click="previewVis = false">退出预览</el-button>
      </span>
    </template>
  </el-dialog>
<!--    新增问题对话框-->
  <el-dialog v-model="addAttrVis" title="新增问题" width="40%" center @closed="resetNewAttrInfo">
    <el-form :model="newAttrInfo"
             label-position="left"
             label-width="100px">
      <el-form-item label="问题名称">
        <el-input v-model="newAttrInfo.attrName"></el-input>
      </el-form-item>
      <el-form-item label="问题说明">
        <el-input v-model="newAttrInfo.attrDesc" type="textarea" placeholder="请输入对于该问题的说明"></el-input>
      </el-form-item>
      <el-form-item label="问题类型">
        <el-radio-group v-model="newAttrInfo.attrType">
          <el-radio label="select">选择题</el-radio>
          <el-radio label="input">填空题</el-radio>
        </el-radio-group>
      </el-form-item>
<!--      选择题-->
      <el-row v-if="newAttrInfo.attrType=='select'">
        <el-form-item label="选择类型">
          <el-radio-group v-model="newAttrInfo.attributeProperty.allowMulti">
            <el-radio label="false">单选题</el-radio>
            <el-radio label="true">多选题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="添加选项">
          <el-input v-model="newAttrValueName"
                    placeholder="请输入选项名称">
            <template #append>
              <el-button type="success" @click="addAttrValue"><el-icon><plus /></el-icon></el-button>
            </template>
          </el-input>
        </el-form-item>
        <br>
        <el-form-item label="               ">
          <el-popconfirm title="确定删除该选项吗?" @confirm="deleteAttrValue(idx)" v-for="(item, idx) in newAttrInfo.attrValues" :key="idx">
            <template #reference>
              <el-button size="medium" style="margin: 3px">
                {{ item.valueName }}
              </el-button>
            </template>
          </el-popconfirm>
        </el-form-item>
      </el-row>
<!--      填空题-->
      <el-form-item label="提示词" v-if="newAttrInfo.attrType=='input'">
        <el-input v-model="newAttrInfo.attributeProperty.placeHolder" placeholder="请输入提示词"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span>
        <el-button @click="addAttrVis = false">取消</el-button>
        <el-button type="primary" @click="addAttr">增加</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Plus,Edit,CaretLeft,CaretRight, Delete, CaretBottom, CaretTop } from '@element-plus/icons'
import OneTemplate from './common/OneTemplate'
export default {
  components: { Edit, Plus, CaretLeft,CaretRight, OneTemplate, Delete, CaretBottom, CaretTop},
  name: "createNewTemplate",
  data() {
    return {
      currentStep: 0,
      commitVis: false,
      addAttrVis: false,
      previewVis: false,
      templateInfo: {
        "templateId": '',
        "userId": '',
        "userName": '',
        "publicAccess": false,
        // 三个时间一样
        "createTime": '',
        "publishTime": '',
        "editTIme": '',
        "templateName": '',
        "contentKind": '',
        "templateDesc": '',
        "attrs": []
      },
      newAttrInfo: {
          "attrId": '',
          "attrName": '',
          "attributeProperty": {
          },
          "attrDesc": '',
          "attrType": '',
          "attrValues": [
          ]
        },
      newAttrValueName: '',
      imgUrl: {
        image: '/imgs/image-img.png',
        text: '/imgs/text-img.png',
        video: '/imgs/video-img.png',
        audio: '/imgs/audio-img.png'
      }
    }
  },
  methods: {
    // 检查是否已选择媒体类型
    checkContentType () {
      if (this.templateInfo.contentKind == '') {
        this.$message.error('请先选择媒体类型！')
        return false
      }
      else {
        if ((this.templateInfo.templateDesc=='' || this.templateInfo.templateName =='') && this.currentStep > 0)
        {
          this.$message.error('请输入模板信息')
          return false
        }
        return true
      }
    },
    // 点击下一步按钮
    nextStep (){
      if (this.currentStep < 3 && this.checkContentType()){
        this.currentStep++
      } else if (this.currentStep==3) {
        this.commitVis = true
      }
    },
    // 点击上一步按钮
    prevStep (){
      if(this.currentStep >0 ){
        this.currentStep--
      } else {
        this.$message.warning("请从第一步开始！")
      }
    },
    // 点击新增问题按钮
    addAttr () {
      if (this.newAttrInfo.attrName=='' || this.newAttrInfo.attrDesc=='' || this.newAttrInfo.attrType=='') {
        this.$message.warning('请先完善问题信息')
      } else {
        this.templateInfo.attrs.push(this.newAttrInfo)
        this.addAttrVis = false
        this.$message.success('添加问题成功！')
      }
    },
    // 删除问题
    deleteArr(idx) {
      this.templateInfo.attrs.splice(idx, 1)
    },
    // 前移问题
    moveArrForward (idx) {
      if (idx > 0) {
        let temp = this.templateInfo.attrs[idx-1]
        this.templateInfo.attrs[idx-1] = this.templateInfo.attrs[idx]
        this.templateInfo.attrs[idx] = temp
      }
    },
    // 后移问题
    moveArrBackward (idx) {
      if (idx < this.templateInfo.attrs.length-1) {
        let temp = this.templateInfo.attrs[idx+1]
        this.templateInfo.attrs[idx+1] = this.templateInfo.attrs[idx]
        this.templateInfo.attrs[idx] = temp
      }
    },
    // 点击新增属性值选项按钮
    addAttrValue () {
      if (this.newAttrValueName == '') {
        this.$message.warning('选项名不能为空')
      } else {
        let atrr = {
            "valueOrder": this.newAttrInfo.attrValues.length,
            "valueName": this.newAttrValueName
          }
        this.newAttrInfo.attrValues.push(atrr)
        this.newAttrValueName = ''
      }
    },
    // 点击删除属性值选项按钮
    deleteAttrValue (idx) {
      this.newAttrInfo.attrValues.splice(idx, 1)
    },
    // 关闭新增属性对话框后重置
    resetNewAttrInfo () {
      this.newAttrInfo = {
        "attrId": '',
        "attrName": '',
        "attributeProperty": {
        },
        "attrDesc": '',
        "attrType": '',
        "attrValues": [
        ]
      }
    },
    // 放弃编辑
    giveup () {
      this.$router.push('/homepage')
    },
    // 获取时间YYYY-MM-DD HH:MM:SS
    getTime() {
      let date=new Date();
      let year=date.getFullYear();
      let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
      let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
      let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
      let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
      // 拼接
      return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    },
    // 提交模板
    async commitTemplate () {
      this.templateInfo.createTime = this.templateInfo.publishTime = this.templateInfo.editTime = this.getTime()
      // 添加attrOrder
      for (let i=0; i<this.templateInfo.attrs.length; i++) {
        this.templateInfo.attrs[i].attrOrder = i
      }
      this.templateInfo.userId = JSON.parse(window.sessionStorage.getItem('user')).user_id
      const { data: res } = await this.$http.post('/template/addNewTemplate', this.templateInfo)
      if (res.code == 200) {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
      this.commitVis = false
      await this.$router.push('/template/all')
    }
  }
}
</script>

<style scoped>
.main_page {
  height: 80%;
  padding: 20px;
}
.oparate_zone {
  margin-top: 20px;
}
.view_zone {
  height: 80%;
  width: 100%;
}
.el-image {
  height: 90%
}
/*右侧每个属性*/
.attribute {
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
