<template>
  <div class="regist-container"  >
    <div id="register" >
      <div class="security-register" style=" background-color: rgba(255, 255, 255, 0.5);">
        <div class="regist-container-alpha" style="
          margin-top:10px;
          width: 40%;
          box-shadow: rgba(0, 0, 0, 4) 5px 15px 20px;
          border-radius: 5px;
          height: 750px;">

          <div
            class="title1"
            style="
        font-size: 40px;
        text-align: center;
        margin-top: 20px;
        font-family: 黑体;
      "
          >
            注册
          </div>
          <a-form
            ref="registerRef"
            :model="user"
            :rules="rules"
            :wrapper-col="wrapperCol"
            style="
          margin-top: 30px;
          width: 80%;
          border-radius: 5px;
        "
          >
            <a-form-item name="u_name">
              <a-input
                v-model:value="user.u_name"
                placeholder="请输入昵称"
                size="large"
                style="width: 80%"
                float:left
              />

              <a-button
                :disabled="user.u_name == ''"
                type="primary"
                block
                @click="examine"
                style="
              width: 15%;
              background: #39c64e;
              margin-left: 5px;
              border: none;
              text-align: center;
              font-size: 5px;
              border-radius: 15px;
            "
              >
                重名检测</a-button
              >
            </a-form-item>

            <a-form-item name="u_role" style="text-align: center">
              <a-radio-group v-model:value="user.r_id">
                <a-radio value="2">发布方</a-radio>
                <a-radio value="3">批注方</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item name="u_birth">
              <a-date-picker
                v-model:value="user.u_birth"
                :format="dateFormat"
                :disabled-date="disabledDate"
                style="width: 100%"
                size="large"
                placeholder="请选择出生日期"
              />
            </a-form-item>

            <a-form-item name="u_sex" style="text-align: center">
              <a-radio-group v-model:value="user.u_sex">
                <a-radio value="1">男</a-radio>
                <a-radio value="0">女</a-radio>
              </a-radio-group>
            </a-form-item>



            <a-form-item name="u_pwd">
              <a-input-password
                v-model:value="user.u_pwd"
                placeholder="请输入密码"
                size="large"
              />
              <div class="strength">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  style="margin-left: 14%; margin-top: 10px"
                >
                  <tr align="center">
                    <td id="pwd_Weak" class="pwd pwd_c"></td>
                    <td id="pwd_Medium" class="pwd pwd_c pwd_f">无</td>
                    <td id="pwd_Strong" class="pwd pwd_c pwd_c_r"></td>
                  </tr>
                </table>
              </div>
            </a-form-item>

            <!--确认密码 注意判断关系-->
            <a-form-item name="u_pwd2">
              <a-input-password
                v-model:value="user.u_pwd2"
                placeholder="确认密码"
                size="large"
              />
            </a-form-item>

            <!--要看一下数据库有没有邮箱  且要保证邮箱格式正确-->
            <a-form-item>
              <div class="form-group">
                <a-input
                  v-model:value="user.u_eml"
                  type="email"
                  class="radius"
                  name="email"
                  id="emailAd"
                  placeholder="邮箱"
                  size="large"
                  style="width: 80%"
                  float:left
                />

                <a-button
                  :disabled="user.u_eml == ''"
                  type="primary"
                  @click="sendMessage"
                  id="sendCode"
                  block
                  class="btn btn-primary"
                  style="
                width: 18%;
                background: #39c64e;
                margin-left: 5px;
                border: none;
                text-align: center;
                font-size: 3px;
                border-radius: 15px;
              "
                >发送验证码</a-button
                >
              </div>

              <div v-if="user.u_eml != ''" class="form-group">
                <a-input
                  type="text"
                  class="radius"
                  id="code"
                  name="code"
                  placeholder="输入验证码"
                  size="large"
                  style="margin-top: 10px"
                />
              </div>
            </a-form-item>

            <a-form-item>
              <a-checkbox
                v-model:checked="checked"
                style="float: left; margin-left: 20%"
              >我已同意</a-checkbox
              >
              <a
                @click="goToXieyi"
                target="_blank"
                style="float: left; cursor: pointer; color: #237119"
              >《用户协议和隐私政策》</a
              >
            </a-form-item>

            <a-form-item :wrapper-col="wrapperCol">
              <a-button
                :disabled="checked == false || user.u_name=='' || user.u_birth=='' || user.u_sex=='' || user.u_pwd=='' || user.u_pwd2==''|| user.u_question==''|| user.u_answer=='' || user.u_eml==''"
                type="primary"
                block
                @click="register"
                size="large"
                style="
              width: 100%;
              background: #39c64e;

              border: none;
            "
              >
                注册</a-button
              >
            </a-form-item>

            <div>
              <a
                href="#/login"
                style="
              float: right;
              cursor: pointer;
              color: #237119;
              margin-bottom: 10px;
            "
              >>>已有帐号，直接登录</a
              >
            </div>
          </a-form>
        </div>

        <div class="home-bottom" style="margin-top:20px">
          <div class="home-bottom-inform" style="text-align: center;font-size:10px">
            商务合作： 15236149131@xjtu.edu.cn
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;内容合作
            ：lkm@163.com/15236149131
          </div>
          <div class="home-bottom-inform" style="text-align: center;font-size:10px">
            京ICP备16021487号-5 京公网安备11010802027588号
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script setup>
import {
  toRaw,
  ref,
  reactive,
  defineComponent,
  h,
  createVNode,
  watch,
} from "vue";

import { Form, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import moment from "moment";
import axios from 'axios'
import {post,get} from '../../main.js';
import { message } from 'ant-design-vue'
const useForm = Form.useForm;
const router = useRouter(); //获取路由器
const registerRef = ref();
const examineRef = ref();
const checked = ref(false);

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
};
//确认密码
const validatePwd2 = async (rule, value) => {
  if (value === "") {
    return Promise.reject("请确认密码！");
  } else if (value !== user.u_pwd) {
    return Promise.reject("两次输入密码不一致!");
  } else {
    return Promise.resolve();
  }
};
//发送验证码
const sendMessage = () => {
  var emailAd = document.getElementById("emailAd");
  console.log("+++++++++++++++++++++++++" + emailAd.value);
  if (emailAd.value == "") {
    alert("邮箱不能为空");
    return;
  }
  var email_reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

  if (!email_reg.test(emailAd.value)) {
    alert("请输入正确的邮箱地址");
    return;
  }

  get("/user/register/getCode", {email: emailAd.value }).then((ref) => {
    message.success("验证码发送成功^ ^");

    if (ref.data.message == "success") {
      var wait = 60;
      var button = document.getElementById("sendCode");
      (function time(o) {
        if (wait == 0) {
          o.disabled = false;
          o.innerHTML = "发送验证码";
          wait = 60;
        } else {
          o.disabled = true;
          o.innerHTML = wait + "秒后";
          wait--;
          setTimeout(function () {
            time(o);
          }, 1000);
        }
      })(button);
    } else {
      console.log("data.failure");
    }
  });
};

//没有用
const emailRegister = () => {
  var email = document.getElementById("emailAd");
  console.log("++++++++++++++++++++++++++++++这是emailAd" + emailAd.value);
  var code = document.getElementById("code");
  console.log("++++++++++++++++++++++++++++++这是code" + code);
  if (code.value == "") {
    alert("验证码不能为空");
    return;
  }
  get("/user/register/emailRegister", {
    email: emailAd.value,
    code: code.value,
  }).then((ref) => {
    if (ref.data.message == "success") {
      message.success("冲冲冲~");
      //window.location.href = "login";
    } else {
      message.error("小可爱您的验证码错误");
      return;
    }
  });
};
const goToXieyi = () => {
  router.push({ path: "/xieyi" });
};
const modelRef = reactive({
  name: "",
  region: undefined,
});
const rulesRef = reactive({
  u_answer: [
    {
      required: true,
      message: "请输入密保答案",
    },
    {
      min: 3,
      max: 10,
      message: "长度3-10",
      trigger: "blur",
    },
  ],
  u_question: [
    {
      required: true,
      message: "选择密保问题",
    },
  ],
});
const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);

//reactive 适合做双向绑定响应示数据
const user = reactive({
  u_id: "",
  u_name: "",
  u_sex: "",
  u_pwd: "",
  u_pwd2: "",
  u_birth: "",
  r_id: "",
  u_eml: "",
});

const rules = {
  u_name: [
    {
      required: true,
      message: "请输入昵称！",
      trigger: "blur",
    },
    {
      min: 1,
      max: 20,
      message: "昵称必须多于1个少于20个字符！",
      trigger: "blur",
    },
  ],
  u_sex: [{ required: true, message: "请选择性别！", trigger: "blur" }],

  u_eml: [{ required: true, message: "请输入邮箱账号！", trigger: "blur" }],

  u_pwd: [
    {
      required: true,
      message: "请输入密码！",
      trigger: "blur",
    },
    {
      min: 6,
      max: 20,
      message: "密码长度为6-20字符！",
      trigger: "blur",
    },
  ],
  u_pwd2: [
    {
      validator: validatePwd2,
      trigger: "blur",
    },
  ],
};

const openContract = () => {
  window.location.href = "#/contract";
};

const temp = ref();

const success = (temp) => {
  Modal.success({
    title: "注册成功",
    content: h("div", {}, [
      h("p", "尊敬的  " + user.u_name + "  :"),
      h("p", "您的账号为" + temp.value),
    ]),

    // afterClose=()=>{
    //     window.location.href="login";
    // },
  });
};

const examine = () => {
  post("/user/register/nick", user).then((res) => {
    if (res.data.message == "true") {
      message.success("用户名独一无二");
    } else {
      message.error("用户名已存在");
    }
  });
};


//前端加密
var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"+ "wxyz0123456789+/" + "=";
function encode64(input) {
  var output = "";
  var chr1, chr2, chr3 = "";
  var enc1, enc2, enc3, enc4 = "";
  var i = 0;
  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
      + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    chr1 = chr2 = chr3 = "";
    enc1 = enc2 = enc3 = enc4 = "";
  } while (i < input.length);
  return output;
}


const register = () => {
  var user_temp = {
    u_name: user.u_name,
    u_sex: user.u_sex,
    u_pwd: encode64(user.u_pwd),
    u_pwd2: encode64(user.u_pwd2),
    u_birth:user.u_birth,
    u_eml:user.u_eml,
    r_id:user.r_id
  };
  var email = document.getElementById("emailAd");
  console.log("++++++++++++++++++++++++++++++这是emailAd" + emailAd.value);
  var code = document.getElementById("code");
  console.log("++++++++++++++++++++++++++++++这是code" + code);
  if (code.value == "") {
    alert("验证码不能为空");
    return;
  }
  get("/user/register/emailRegister", {
    email: user_temp.u_eml,
    code: code.value,
  }).then((ref) => {
    if (ref.data.message == "success") {
      registerRef.value
        .validate()
        .then(() => {
          //then方法仅接收成功操作的结果
          post("/user/register", user_temp).then((res) => {
            if(res.data.message!="fail"){
              message.success("冲冲冲~");

              temp.value = res.data.message;
              success(temp);
              router.push({ path: "/login" });
            }
            else{
              message.error("昵称或者邮箱已被使用！");
            }

          });
        })
        .catch((error) => {
          message.error("输入信息缺失或不正确！");
        });

      //window.location.href = "login";
    } else {
      message.error("您的验证码错误");
    }
  });
};

var pwd = user.u_pwd;
watch(
  () => user.u_pwd,
  (newVal) => {
    CheckIntensity(newVal);
  }
);

//密码强度函数
function CheckIntensity(pwd) {
  var Mcolor, Wcolor, Scolor, Color_Html;
  var m = 0;
  //匹配数字
  if (/\d+/.test(pwd)) {
    debugger;
    m++;
  }
  //匹配字母
  if (/[A-Za-z]+/.test(pwd)) {
    m++;
  }
  //匹配除数字字母外的特殊符号
  if (/[^0-9a-zA-Z]+/.test(pwd)) {
    m++;
  }

  if (pwd.length <= 6) {
    m = 1;
  }
  if (pwd.length <= 0) {
    m = 0;
  }
  switch (m) {
    case 1:
      Wcolor = "pwd pwd_Weak_c";
      Mcolor = "pwd pwd_c";
      Scolor = "pwd pwd_c pwd_c_r";
      Color_Html = "弱";
      break;
    case 2:
      Wcolor = "pwd pwd_Medium_c";
      Mcolor = "pwd pwd_Medium_c";
      Scolor = "pwd pwd_c pwd_c_r";
      Color_Html = "中";
      break;
    case 3:
      Wcolor = "pwd pwd_Strong_c";
      Mcolor = "pwd pwd_Strong_c";
      Scolor = "pwd pwd_Strong_c pwd_Strong_c_r";
      Color_Html = "强";
      break;
    default:
      Wcolor = "pwd pwd_c";
      Mcolor = "pwd pwd_c pwd_f";
      Scolor = "pwd pwd_c pwd_c_r";
      Color_Html = "无";
      break;
  }
  document.getElementById("pwd_Weak").className = Wcolor;
  document.getElementById("pwd_Medium").className = Mcolor;
  document.getElementById("pwd_Strong").className = Scolor;
  document.getElementById("pwd_Medium").innerHTML = Color_Html;
}
</script>




<style  scoped>
html {
  height: auto;
  width: 100%;
}

#register {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  min-width: 900px;
  min-height: 500px;
  background-image: url("../../assets/imgs/login/bg4.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.security-register {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.regist-container-alpha {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
}
.home-bottom {
  height: 38px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index:9999;
}
.home-bottom-inform {
  font-size: 10px;
  align-content: center;
  /*color: #e79229;*/
}

.pwd {
  width: 120px;
  height: 3px;
  line-height: 7px;
  padding-top: 1px;
}
.pwd_f {
  font-size: 5px;
}
.pwd_c {
  background-color: #f3f3f3;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
  font-size: 5px;
}
.pwd_Weak_c {
  background-color: #ff4545;
  border-top: 1px solid #bb2b2b;
  border-bottom: 1px solid #bb2b2b;
  border-left: 1px solid #bb2b2b;
  font-size: 5px;
}
.pwd_Medium_c {
  background-color: #ffd35e;
  border-top: 1px solid #e9ae10;
  border-bottom: 1px solid #e9ae10;
  border-left: 1px solid #e9ae10;
  font-size: 5px;
}
.pwd_Strong_c {
  background-color: #3abb1c;
  border-top: 1px solid #267a12;
  border-bottom: 1px solid #267a12;
  border-left: 1px solid #267a12;
  font-size: 5px;
}
.pwd_c_r {
  border-right: 1px solid #d0d0d0;
}
.pwd_Weak_c_r {
  border-right: 1px solid #bb2b2b;
}
.pwd_Medium_c_r {
  border-right: 1px solid #e9ae10;
}
.pwd_Strong_c_r {
  border-right: 1px solid #267a12;
}
</style>
