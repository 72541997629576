<!--该组件，用于显示一个模板的详细内容-->
<template>
  <div class="template">
    <!--    模板名称-->
    <el-row justify="center" class="template_title">{{templateInfo.templateName}}
    </el-row>
    <el-divider><el-tag type="success" style="font-weight: bold" @click="taskInfoVis=true">任务详情</el-tag></el-divider>
    <!--    模板内容-->
    <el-row class="template_content">
      <!--      左侧样本区-->
      <el-col :span="12">
<!--        样本展示区-->
        <el-row justify="center">
          <!--          图片媒体-->
          <div v-if="templateInfo.contentKind=='image'">
<!--            标注时，url给定动态值-->
            <el-image
              :src="this.baseUrl + 'img?fileName=' + markInfoList[currentIdx].taskContent.fileUrl"
              class="image_media"
              v-if="markInfoList[currentIdx].taskContent.fileUrl!=''">
            </el-image>
<!--            预览时，url给定默认值-->
            <el-image
              :src="this.baseUrl + 'img?fileName=' + '1.jpg'"
              class="image_media"
              v-else>
            </el-image>
          </div>
          <!--          文字媒体-->
          <el-scrollbar :height="280" v-if="templateInfo.contentKind=='text'">
<!--            标注时的动态值-->
            <div class="text_media" v-if="markInfoList[currentIdx].taskContent.fileContent">
              {{markInfoList[currentIdx].taskContent.fileContent}}
            </div>
<!--            预览时的默认值-->
            <div class="text_media" v-else>
              此处显示文本信息
            </div>
          </el-scrollbar>
          <!--          视频媒体-->
          <div class="image_media" v-if="templateInfo.contentKind=='video'">
<!--            标注时的动态值-->
            <video width="320" height="240" controls id="videos1" v-if="markInfoList[currentIdx].taskContent.fileUrl" ref="videoPlayer"
                   class="image_media">
              <source :src="this.baseUrl + 'video?fileName=' + markInfoList[currentIdx].taskContent.fileUrl" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
<!--            预览时的默认值-->
            <video width="320" height="240" controls id="videos2" v-else
                   class="image_media">
              <source :src="this.baseUrl + 'video?fileName=' + '1.mp4'" type="video/mp4">
              您的浏览器不支持 HTML5 video 标签。
            </video>
          </div>
          <!--          语音媒体-->
          <div v-if="templateInfo.contentKind=='audio'" style="height: 240px">
<!--            标注时的动态值-->
            <audio controls="controls" v-if="markInfoList[currentIdx].taskContent.fileUrl" ref="audioPlayer">
              <source :src="this.baseUrl + 'audio?fileName=' + markInfoList[currentIdx].taskContent.fileUrl" type="audio/mp3" />
              您的浏览器不支持 HTML5 audio 标签。
            </audio>
<!--            预览时的默认值-->
            <audio controls="controls" v-else>
              <source :src="this.baseUrl + 'audio?fileName=' + '1.wav'" type="audio/mp3" />
              您的浏览器不支持 HTML5 audio 标签。
            </audio>
          </div>
        </el-row>
<!--        样本页码区-->
        <el-row justify="center" class="current_total">
          第{{currentIdx+1}}个/共{{this.markInfoList.length}}个
        </el-row>
        <el-row justify="center" v-if="(annotate||buttonEnable)">
          <el-button size="mini" type="danger" @click="prevPage">上一个</el-button>
          <el-button size="mini" type="primary" @click="submitVis = true" v-if="annotate">提交</el-button>
          <el-button size="mini" type="success" @click="nextPage">下一个</el-button>
        </el-row>
      </el-col>
      <!--      右侧题目区-->
      <el-col :span="12">
        <el-scrollbar :height="400">
          <!--        每个属性（题目）-->
          <el-row class="attribute"
                  v-for="(attr, idx) in templateInfo.attrs" :key="idx">
            <!--              题目名称和解释-->
            <el-row justify="center" style="margin: 8px">
              <el-tooltip
                effect="dark"
                :content="attr.attrDesc"
                placement="top"
              >
                <el-button type="info" size="mini">第{{idx+1}}题:{{attr.attrName}}</el-button>
              </el-tooltip>
            </el-row>
            <!--          选择器-->
            <el-select
              style="width: 85%"
              v-model="markInfoList[currentIdx].markDetailList[idx].attrValueList"
              :clearable="annotate"
              v-if="attr.attrType=='select'"
              :multiple="attr.attributeProperty.allowMulti"
              :placeholder="attr.attrDesc">
              <el-option
                :disabled="!annotate"
                v-for="item in attr.attrValues"
                :key="item.valueId"
                :label="item.valueName"
                :value="item.valueId"
              >
              </el-option>
            </el-select>
            <!--          输入框-->
            <el-input
              :disabled="!annotate"
              v-if="attr.attrType=='input'"
              :clearable="annotate"
              v-model="markInfoList[currentIdx].markDetailList[idx].attrValueList[0]"
              style="width: 85%"
              autosize
              type="textarea"
              :placeholder="attr.attributeProperty.placeHolder"
            />
          </el-row>
        </el-scrollbar>
      </el-col>
    </el-row>
    <!--        审核样本时的操作按钮-->
    <el-row justify="center" v-if="check">
      <el-button size="mini" type="danger" @click="unPassCheck">不通过</el-button>
      <el-button size="mini" type="primary" @click="passCheck">通过</el-button>
    </el-row>
  </div>
  <!--  查看任务详情的对话框-->
  <el-dialog
    v-model="taskInfoVis"
    width="40%"
  >
    <one-task-info :task-info="taskInfo"></one-task-info>
    <template #footer>
      <el-row justify="center">
        <el-button @click="taskInfoVis = false">退出查看</el-button>
      </el-row>
    </template>
  </el-dialog>
  <!--  提交标注结果对话框-->
  <el-dialog
    title="提交"
    v-model="submitVis"
    width="30%"
  >
    <span>提交后，在截止时间前可以修改和继续标注，请问您确认要提交吗？</span>
    <template #footer>
      <el-row justify="center">
        <el-button @click="submitVis = false" type="warning">暂不提交</el-button>
        <el-button @click="submit" type="primary">确定提交</el-button>
      </el-row>
    </template>
  </el-dialog>
  <!--  刚进入标注页面时的welcome对话框-->
  <el-dialog
    title="开始标注"
    center
    v-model="welcomeVis"
    width="30%"
    @close="startAnnotate"
  >
    <el-row style="font-size: 16px">
      当前需要标注的是第{{currentIdx + 1}}个样本，还剩余{{markInfoList.length - currentIdx}}个未标注样本，您可以继续上次的标注~
    </el-row>
    <template #footer>
      <el-row justify="center">
        <el-button @click="welcomeVis=false" type="primary">开始标注</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import OneTaskInfo from "@/components/task/OneTaskInfo";
export default {
  name: "OneTemplate",
  emits: ['pass', 'unpass'],
  props: {
    templateInfo: {
      type: Object,
      default: {
        attrs: []
      }
    },
    taskInfo: {
      type: Object,
      default: {
        taskTitle: "任务标题",
        taskType: "样本类型",
        publisher: "西安交通大学软件学院",
        taskReward: 10,
        taskDesc: "如果你能够在规定时间内完成标注任务，您将获得丰厚的积分奖励，同时有机会与本系统开发团队面对面交流哦！",
        taskReq: "请您认真批注，一旦发现作弊，将不予结算积分，同时全平台通报~",
      }
    },
    markInfoList: {
      type: Array,
      default: [{
        taskContent: {
          fileUrl: ""
        },
        markDetailList: [
          {
            attrValueList: []
          }
        ]
      }]
    },
    drawId: {
      type: Number
    },
    current: {
      type: Number,
      default: 0
    },
    annotate: {
      Boolean,
      default: false
    },
    check: {
      Boolean,
      default: false
    },
    buttonEnable: {
      Boolean,
      default: false
    }
  },
  components: {
    "one-task-info": OneTaskInfo
  },
  data() {
    return {
      taskInfoVis: false,
      submitVis: false,
      currentIdx: 0, // 当前看到的样本的下标，随“下一步/上一步变化”
      currentStartIdx: 0, // 本次标注的第一个样本下标，不随“下一步/上一步”变化
      lastFinishIdx: -1, // 上一次标注时完成的最后一个样本下标
      currentMaxIdx: 0, // 本次标注过程中到达过的最大的样本下标--用于修改本次标注结果的判断
      totalNum: '',
      welcomeVis: true,
    }
  },
  mounted() {
    this.currentIdx = this.current
    this.lastFinishIdx = this.current - 1
    this.currentStartIdx = this.current
    this.currentMaxIdx = this.current
    // 如果不是annotate状态（预览状态），需要为赋一些默认初始值
    if (!this.annotate) {
      // 标记不显示welcome弹窗
      this.welcomeVis = false
      // 1. 为markDetailList赋值
      for (let i = 0; i < 100; i++){
        this.markInfoList[0].markDetailList.push({
          "attrValueList": []
        })
      }
    }
  },
  methods: {
    // 检查第i个样本是否全部标注完成
    checkSample (idx) {
      let markDetailList = this.markInfoList[idx].markDetailList
      for (let i = 0; i < markDetailList.length; i++){
        if (markDetailList[i].attrValueList == '' || markDetailList[i].attrValueList == []) {
          return false
        }
      }
      return true
    },
    // 获取时间YYYY-MM-DD HH:MM:SS
    getTime() {
      let date=new Date();
      let year=date.getFullYear();
      let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
      let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
      let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
      let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
      // 拼接
      return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    },
    // 获取毫秒时间
    getMilliTime() {
      let timer = new Date()
      return timer.getTime()
    },
    //  点击下一张按钮
    nextPage () {
      // 若当前样本已标注完成
      if (this.checkSample(this.currentIdx)||!this.annotate) {
        // 如果是第一次到达该样本，都要记录该样本的endTime,warning,useTime
        if (this.currentIdx >= this.currentMaxIdx){
          this.markInfoList[this.currentIdx].endTime = this.getTime()
          this.markInfoList[this.currentIdx].endMilliTime = this.getMilliTime()
          this.markInfoList[this.currentIdx].useTime = this.markInfoList[this.currentIdx].endMilliTime - this.markInfoList[this.currentIdx].startMilliTime
          if (this.markInfoList[this.currentIdx].useTime < parseInt(this.taskInfo.taskExceptionTime)*1000) {
            this.markInfoList[this.currentIdx].warning = true
          }
        }
        // 修改update字段，本次新批注为2，修改之前的批注为1(在prev函数中修改)
        if (this.currentIdx >= this.currentMaxIdx) {
          this.markInfoList[this.currentIdx].update = 2
        }
        // 如果不是最后一个对currentIdx加1，并记录下一个的开始时间
        if (this.currentIdx < this.markInfoList.length - 1) {
          this.currentIdx = this.currentIdx + 1
          this.markInfoList[this.currentIdx].startTime = this.getTime()
          this.markInfoList[this.currentIdx].startMilliTime = this.getMilliTime()
          // 修改currentMaxIdx
          if (this.currentIdx > this.currentMaxIdx) {
            this.currentMaxIdx = this.currentIdx
          }
          // 动态绑定video/audio中的url
          this.$nextTick(()=>{
            if (this.templateInfo.contentKind=='audio') {
              this.$refs.audioPlayer.load()
            }
            if (this.templateInfo.contentKind=='video') {
              this.$refs.videoPlayer.load()
            }
          })
        }
        // 若是最后一个，弹出提交按钮
        else {
          if (this.annotate) {
            this.submitVis = true
          }
          else {
            this.$message.warning('已经到底啦~')
          }
        }
      }
      // 当前样本未标注完成
      else {
        this.$message.warning('请先完成当前样本的所有题目！')
      }
    },
    // 点击上一张按钮
    prevPage () {
      // 修改update字段
      if (this.currentIdx > 0) {
        this.currentIdx = this.currentIdx - 1
        // 修改update字段，本次新批注为2(在next函数中修改)，修改之前的批注为1
        if (this.currentIdx <= this.lastFinishIdx) {
          this.markInfoList[this.currentIdx].update = 1
        }
        // 动态绑定video/audio中的url
        this.$nextTick(()=>{
          if (this.templateInfo.contentKind=='audio') {
            this.$refs.audioPlayer.load()
          }
          if (this.templateInfo.contentKind=='video') {
            this.$refs.videoPlayer.load()
          }
        })
      } else {
        this.$message.warning('当前是第一张!')
      }
    },
    // 开始标注-设置本次第一个样本的开始时间
    startAnnotate () {
      this.welcomeVis = false
      this.markInfoList[this.currentIdx].startTime = this.getTime() // YYYY-MM-DD HH:MM:SS格式
      this.markInfoList[this.currentIdx].startMilliTime = this.getMilliTime() // 毫秒
    },
    // 提交发布结果
    async submit () {
      // 如果当前样本已标注完成
      if (this.checkSample(this.currentIdx)) {
        // 如果是从中途提交标注的，需要记录本次标注最后一个样本的endTime, warning, useTime
        if (this.currentIdx >= this.currentMaxIdx){
          this.markInfoList[this.currentIdx].endTime = this.getTime()
          this.markInfoList[this.currentIdx].endMilliTime = this.getMilliTime()
          this.markInfoList[this.currentIdx].useTime = this.markInfoList[this.currentIdx].endMilliTime - this.markInfoList[this.currentIdx].startMilliTime
          if (this.markInfoList[this.currentIdx].useTime < parseInt(this.taskInfo.taskExceptionTime)*1000) {
            this.markInfoList[this.currentIdx].warning = true
          }
        }
        // 标记update
        if (this.currentIdx < this.currentStartIdx) {
          this.markInfoList[this.currentIdx].update = 1
        } else {
          this.markInfoList[this.currentIdx].update = 2
        }
        let markResult = {
          drawId: this.drawId,
          markInfoList: this.markInfoList
        }
        // 修改select attr的格式为["", ""]
        // 逐个样本
        for (let i = 0; i <= this.currentMaxIdx; i++) {
          // 该样本的逐个attr（题目）
          for (let j = 0; j < this.markInfoList[i].markDetailList.length; j++) {
            // 如果是单选
            if (this.markInfoList[i].markDetailList[j].attrValueList.length == undefined) {
              // 转为String
              this.markInfoList[i].markDetailList[j].attrValueList += ''
              // 转为String Array
              this.markInfoList[i].markDetailList[j].attrValueList = [this.markInfoList[i].markDetailList[j].attrValueList]
            }
            // 多选
            else {
              // 每一个元素由Number转为String
              for (let k = 0; k < this.markInfoList[i].markDetailList[j].attrValueList.length; k++) {
                this.markInfoList[i].markDetailList[j].attrValueList[k] += ''
              }
            }
          }
        }
        // 调用后端接口
        const { data: res } = await this.$http.post('/task/annotater/labelTask/changeMarkResult', markResult)
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
        this.submitVis = false
        // 跳转到标注列表
        await this.$router.push('/task/drawList')
      }
      // 若未标注完成
      else {
        this.submitVis = false
        this.$message.warning('请先完成当前样本的所有题目！')
      }
    },
    // 通过审核该样本
    passCheck() {
      let markId = this.markInfoList[this.currentIdx].markId
      this.$emit("pass", markId)
    },
    // 不通过审核该样本
    unPassCheck() {
      let markId = this.markInfoList[this.currentIdx].markId
      this.$emit("unpass", markId)
    }
  }
}
</script>

<style scoped>
/*整个模板*/
.template {
  height: 90%;
  width: 100%;
}
/*模板标题*/
.template_title {
  font-size: 20px;
  font-weight: bold;
}
/*模板内容*/
.template_content {
  padding-top: 20px;
}
/*图片格式*/
.image_media{
  height: 280px;
  width: 380px;
  border-radius: 5px;
}
/*文字媒体*/
.text_media {
  width: 100%;
  height: 100%;
  font-size: 15px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
}
/*第x个/共x个*/
.current_total {
  font-size: 15px;
  margin: 20px;
}
/*右侧每个属性*/
.attribute {
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
