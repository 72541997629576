import 'ant-design-vue/dist/antd.css'
import { Button, Input,Tabs,Form,message,Modal,Radio,DatePicker,Checkbox,Menu,Table,Result} from 'ant-design-vue'

export default (app) => {
  app.use(Button)
  app.use(Input)
  app.use(Tabs)
  app.use(Form)
  app.use(message)
  app.use(Modal)
  app.use(Radio)
  app.use(DatePicker)
  app.use(Checkbox)
  app.use(Menu)
  app.use(Table)
  app.use(Result)
}
