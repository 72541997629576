<template>
  <div style="overflow: hidden; width: 100%; height: 100%">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table">
      <table border="1px" class="my-table" style="text-align: center">
        <tr>
          <th width="20%"></th>
          <th width="40%">个人信息</th>
          <th width="30%">操作</th>
        </tr>
        <tr>
          <td style="font-weight: bold">用户ID</td>
          <td>{{ currUserInfo.user_id }}</td>
          <td></td>
        </tr>
        <tr>
          <td style="font-weight: bold">用户昵称</td>
          <td>{{currUserInfo.user_name}}</td>
          <td>

          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">用户角色</td>
          <td>{{ currUserInfo.r_id }}</td>
          <td>

          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">用户邮箱</td>
          <td>{{ currUserInfo.mail }}</td>
          <td>

          </td>
        </tr>
        <tr>
          <td style="font-weight: bold;margin-left:20px">用户性别</td>
          <td>{{ currUserInfo.gender == 1 ? "男" : "女" }}</td>
          <td>
            <a-radio-button
              value="large"
              style="color: #f09696;"
              @click="openUpd(currUserInfo)"
            ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
            >&nbsp;&nbsp;修改</a-radio-button
            >
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">出生日期</td>
          <td>{{ currUserInfo.birth }}</td>
          <td>
            <a-radio-button
              value="large"
              style="color: #f09696;"
              @click="openUpd(currUserInfo)"
            ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
            >&nbsp;&nbsp;修改</a-radio-button
            >
          </td>
        </tr>


      </table>
    </div>



  </div>


  <!-- 修改窗口 -->

  <a-modal v-model:visible="visible" title="修改用户信息" @ok="execUpd" ok-text="确认"
           cancel-text="取消">
    <a-form
      :model="updObj"

    >
      <a-form-item label="用户ID">
        <a-input v-model:value="updObj.u_id" disabled />
      </a-form-item>
      <a-form-item label="用户昵称">
        <a-input v-model:value="updObj.u_name_new" disabled />
      </a-form-item>
      <a-form-item label="用户邮箱">
        <a-input v-model:value="updObj.u_eml_new" disabled />
      </a-form-item>
      <a-form-item label="用户性别">
        <a-radio-group v-model:value="updObj.u_sex_new">
          <a-radio value="1">男</a-radio>
          <a-radio value="0">女</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="出生日期">
        <a-date-picker v-model:value="updObj.u_birth_new" style="width: 100%" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from 'ant-design-vue'
const router = useRouter();
import {post,get} from '../../main.js';
var currUserInfo = ref({});
currUserInfo=JSON.parse(window.sessionStorage.getItem('user'));
const translateRid = (u)=>{
  if(u.r_id=="3"){
    u.r_id="标注者";
  }
  else if(u.r_id=="2"){
    u.r_id="发布者";
  }
};
translateRid(currUserInfo);
console.log(currUserInfo);
console.log(currUserInfo.user_name);
var token = window.sessionStorage.getItem('userInfo');

//修改
const updObj = reactive({
  u_id: "",
  u_name_new: "",
  u_sex_new: "",
  u_eml_new:"",
  u_birth_new: null,
  token :""
});
const visible = ref(false);
const visible2 = ref(false);

const openUpd = (data) => {
  updObj.u_id = data.user_id;
  updObj.u_name_new = data.user_name;
  updObj.u_eml_new = data.mail;
  updObj.u_sex_new = data.gender;
  updObj.u_birth_new = data.birth;
  updObj.token = token;
  //打开窗口
  visible.value = true;
};
const back =() =>{
  router.push("/homepage");
}
const execUpd = () => {
  //执行修改
  post("/user/personal/update", updObj
  ).then((res) => {
    currUserInfo.user_name = updObj.u_name_new;
    currUserInfo.mail = updObj.u_eml_new;
    currUserInfo.gender = updObj.u_sex_new;
    currUserInfo.brith = updObj.u_birth_new;
    updObj.u_id = "";
    updObj.u_name_new = "";
    updObj.u_eml_new = "";
    updObj.u_sex_new = "";
    updObj.u_birth_new = null;
    window.sessionStorage.setItem('user',JSON.stringify(currUserInfo));
    message.success("修改用户成功！");
    visible.value = false;
    //getPersonalInfo();
  });
};

</script>

<style scoped>
.my-table {
  margin-top:70px;
  height: 60%;
  width: 50%;
}
.back {
  height: 10%;
  width: 100%;
  margin-left:20px;
  margin-top:3%;
  background-size: cover;
}
.table{
  display: flex;
  flex-direction: row;
  /*主轴为水平方向*/
  justify-content: center;
  align-items: center;

}
td,
th {
  height: 50px;
  font-size: 17px;
}
table {
  border-collapse: collapse;
  border: 1px solid #ffd04b;
}
table td {
  border-top: 0;
  border-right: 1px solid  #ffd04b;
  border-bottom: 1px solid  #ffd04b;
  border-left: 0;
}
table tr.lastrow td {
  border-bottom: 0;
}
table tr td.lastCol {
  border-right: 0;
}



</style>
