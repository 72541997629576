<template>

  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
    <el-breadcrumb-item>统计模块</el-breadcrumb-item>
    <el-breadcrumb-item>统计数据</el-breadcrumb-item>
  </el-breadcrumb>


<div class="head" style="margin-top:2%;margin-left:10%">
  <div>
    <el-row style="margin-top:50px">
      <span style="margin-left:10px;">年份:</span>
      <el-select v-model="year" placeholder="Select"  size="small" style="margin-left: 1%" @change="getMonthNums">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"

        >
        </el-option>
      </el-select>
    </el-row>
  </div>

  <div style="margin-top:-50px">
    <el-card class="box-card" style="height:150px;width:180px;margin-left:150px;">
      <el-col >
        <div style="font-family:'微软雅黑';font-weight: bold ">任务完成数:</div>
        <div style="font-family:'微软雅黑';font-weight: bold;font-size:40px;margin-left:50px;margin-top:20%">{{ this.finishNum }}</div>
      </el-col>

    </el-card>
  </div>

  <div style="margin-top:-50px">
    <el-card class="box-card" style="height:150px;width:200px;margin-left:150px">
     <el-col>
       <div style="font-family:'微软雅黑';font-weight: bold ">任务正确率:</div>
       <div style="margin-left: 40px;margin-top:7px">
         <el-progress type="circle" :percentage="this.totalRightRate" width="90" color="#39c64e" >
           <template #default="{ percentage }">
             <span class="percentage-value" style="font-size: 15px">{{ percentage }}%</span>
           </template>
         </el-progress>
       </div>

     </el-col>

    </el-card>
  </div>


</div>


<el-row style="margin-top:6%;margin-left: 4%">
  <div id="taskNums" style="width: 600px;height:400px;"></div>
  <div id="rightRate" style="width: 600px;height:400px;margin-left: 2%"></div>
</el-row>

</template>

<script>
import {
  toRaw,
  ref,
  reactive,
  defineComponent,
  h,
  createVNode,
  watch,
  toRefs
} from "vue";

import { Form, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import moment from "moment";
import {post,get} from '../../main.js';
import { message } from 'ant-design-vue'
import * as echarts from "echarts";

export default {
  name: 'Echarts',
  data() {
    return {
      year: "2021",
      monthNum:{
      },
      monthSNum:{
      },
      rightRate:[],
      options: ref([
        {
          value: '2020',
          label: '2020',
        },
        {
          value: '2021',
          label: '2021',
        },
        {
          value: '2022',
          label: '2022',
        },
        {
          value: '2023',
          label: '2023',
        },
        {
          value: '2024',
          label: '2024',
        },
      ]),
      user_id:JSON.parse(window.sessionStorage.getItem('user')).user_id,
      finishNum:'',
      totalRightRate:''
    };
  },
  methods: {
    myEcharts(){
      console.log("right"+this.rightRate);
      var myChart1 = echarts.init(document.getElementById('taskNums'));
      var myChart2 = echarts.init(document.getElementById('rightRate'));
      //配置图表
      var option1 = {
        title: {
          text: '月领取完成任务数',
        },
        tooltip: {},
        legend: {
          data: ['月领取任务数','月完成任务数']
        },
        xAxis: {
          data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
        },
        yAxis: {},
        series: [{
          name: '月完成任务数',
          type: 'bar',
          color:"#39c64e",
          data: [this.monthNum.jan,
            this.monthNum.feb,
            this.monthNum.mar,
            this.monthNum.apr,
            this.monthNum.may,
            this.monthNum.jun,
            this.monthNum.jul,
            this.monthNum.aug,
            this.monthNum.sep,
            this.monthNum.oct,
            this.monthNum.nov,
            this.monthNum.dec,]
        },
          {
            name: '月领取任务数',
            type: 'bar',
            data: [this.monthSNum.jan,
              this.monthSNum.feb,
              this.monthSNum.mar,
              this.monthSNum.apr,
              this.monthSNum.may,
              this.monthSNum.jun,
              this.monthSNum.jul,
              this.monthSNum.aug,
              this.monthSNum.sep,
              this.monthSNum.oct,
              this.monthSNum.nov,
              this.monthSNum.dec,]
          }
        ],
      };
      var option2 ={
        title:{
          text:'近十项任务正确率'
        },
        legend: {
          data: ['正确率']
        },
        xAxis: {
          name:'时间',
          axisLabel:{show : false},
          data:[]
        },
        yAxis: {
          name:'正确率%'
        },
        series: [{
          name:'正确率',
          type:'line',
          label:{show:true},
          data:this.rightRate,

        }]
      }
      myChart1.setOption(option1);
      myChart2.setOption(option2);
    },
     getMonthNums(){
       get("/statistics/tasknums",{user_id:this.user_id,year:this.year}).then((res)=>{
         this.monthNum=res.data.data;
         get("/statistics/tasknums2",{user_id:this.user_id,year:this.year}).then((res)=>{
           this.monthSNum = res.data.data;
           this.myEcharts();
         })

      })
    },
     getRightRate(){
      get("/statistics/rightrate",{user_id:this.user_id}).then((res)=>{
        this.rightRate = res.data.data;
        this.myEcharts();
      })
     },
    getCount(){
      get("/statistics/finishnum",{user_id:this.user_id}).then((res)=>{
        this.finishNum = res.data.data;
      })
    },
    getTotalRightRate(){
      get("/statistics/totalrate",{user_id:this.user_id}).then((res)=>{
        this.totalRightRate = res.data.data;
      })
    }
  },
  mounted(){
    this.getMonthNums();
    this.getRightRate();
    this.getCount();
    this.getTotalRightRate();

  }
}
</script>




<style  scoped>
html {
  height: auto;
  width: 100%;
}

.head {
  display: flex;
  flex-direction: row;
}

.home-nav {
  height: 50px;
  width: 100%;
  background-color: #fdf0e0;
  display: flex;
  flex-direction: row;
  /*主轴为水平方向*/
  align-items: center;
  box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.05);
  margin-top: 0%;
}

.security-register {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.regist-container-alpha {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
.check {
  flex-direction: row;
}
.home-bottom {
  height: 38px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:9999;
}
.home-bottom-inform {
  font-size: 10px;
  align-content: center;
  /*color: #e79229;*/
}

.pwd {
  width: 120px;
  height: 3px;
  line-height: 7px;
  padding-top: 1px;
}
.pwd_f {
  font-size: 5px;
}
.pwd_c {
  background-color: #f3f3f3;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
  font-size: 5px;
}
.pwd_Weak_c {
  background-color: #ff4545;
  border-top: 1px solid #bb2b2b;
  border-bottom: 1px solid #bb2b2b;
  border-left: 1px solid #bb2b2b;
  font-size: 5px;
}
.pwd_Medium_c {
  background-color: #ffd35e;
  border-top: 1px solid #e9ae10;
  border-bottom: 1px solid #e9ae10;
  border-left: 1px solid #e9ae10;
  font-size: 5px;
}
.pwd_Strong_c {
  background-color: #3abb1c;
  border-top: 1px solid #267a12;
  border-bottom: 1px solid #267a12;
  border-left: 1px solid #267a12;
  font-size: 5px;
}
.pwd_c_r {
  border-right: 1px solid #d0d0d0;
}
.pwd_Weak_c_r {
  border-right: 1px solid #bb2b2b;
}
.pwd_Medium_c_r {
  border-right: 1px solid #e9ae10;
}
.pwd_Strong_c_r {
  border-right: 1px solid #267a12;
}
</style>
