<!--该组件，用于显示一个模板的详细内容-->
<template>
  <div class="template">
<!--    模板内容-->
    <one-template
      :annotate="false"
      :check="true"
      button-enable="true"
      :current="current"
      :task-info="taskInfo"
      :template-info="templateInfo"
      :mark-info-list="markInfoList"
      @pass="submitPass"
      @unpass="showUnpass"
    >
    </one-template>
<!--    审核按钮-->
<!--    <el-row justify="center">-->
<!--      <el-button size="mini" type="success" @click="submitPass" v-if="markInfoList[current].status =='DYS'">通过</el-button>-->
<!--      <el-button size="mini" type="danger" @click="showUnpass" v-if="markInfoList[current].status =='DYS'">不通过</el-button>-->
<!--      <el-button size="mini" type="info" @click="closeSample">结束抽样</el-button>-->
<!--    </el-row>-->
  </div>
<!--输入不通过理由对话框-->
  <template v-if="dialogUnpassVisible">
    <el-dialog
      center
      title="不通过理由"
      v-model="dialogUnpassVisible"
      width="40%" destroy-on-close append-to-body
    >
      <el-row>
        <el-col>
          <el-input type="textarea" v-model="unpassReason" placeholder="请输入不通过原因"></el-input>
        </el-col>
      </el-row>
      <template #footer>
        <el-row justify="center">
          <el-button @click="dialogUnpassVisible = false">取消</el-button>
          <el-button @click="submitUnpass">确定</el-button>
        </el-row>
      </template>
    </el-dialog>
  </template>
</template>

<script>
import OneTemplate from "@/components/template/common/OneTemplate"
export default {
  name: "TaskSample",
  props: {
    templateInfo: {
      type: Object,
      default: {
        attrs: []
      }
    },
    closeInfo:{
      type: Object,
      default: {

      }
    },
    taskInfo: {
      type: Object,
    },
    markInfoList: {
      type: Array,
      default: [{
        taskContent: {
          fileUrl: "10.jpg"
        },
        markDetailList: [
          {
            attrValueList: []
          }
        ]
      }]
    },
    drawId: {
      type: Number
    },
    current: {
      type: Number
    },
    annotate: {
      Boolean,
      default: false
    }
  },
  components: {
    "one-template": OneTemplate
  },
  data() {
    return {
      dialogUnpassVisible:false,
      unpassReason:'',
      upassId:''
    }
  },
  methods: {
    async closeSample(){
      //let data = {tmarkId:this.markInfoList[this.currentIdx].markId,status:3}
      // 调用后端接口
      const { data: res } = await this.$http.get('/task/rightRate', {params: this.closeInfo})
      if (res.status == 200) {
        this.$message.success("结束成功")
      } else {
        this.$message.error(res.msg)
      }
    },
    async submitPass(markId){
      console.log(markId)
      let data = {tmarkId:markId,status:3}
      // 调用后端接口
      const { data: res } = await this.$http.get('/task/updateStatus', {params: data})
      if (res.status == 200) {
        // this.markInfoList[this.currentIdx].status = 3
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async submitUnpass(){
      let data = {unpass_reason:this.unpassReason,tmarkId:this.unpassId,status:4}
      // 调用后端接口
      const { data: res } = await this.$http.get('/task/updateStatus', {params: data})
      if (res.status == 200) {
        this.dialogUnpassVisible = false
        // this.markInfoList[this.currentIdx].status = 4
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    showUnpass(markId){
        console.log(markId)
        this.unpassId=markId
        this.unpassReason = ''
        this.dialogUnpassVisible = true
      }
  }
}
</script>

<style scoped>
</style>
