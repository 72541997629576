<template>
  <div class="set">
    <a-form
      :model="user"
      :rules="rules"
      :wrapper-col="wrapperCol"
      style="
        margin-top: 70px;
        width: 15%;
        min-width: 500px;
        max-width: 700px;
        overflow: auto;
        border-radius: 5px;
      "
    >
      <a-form-item name="u_pwd">
        <a-input-password
          v-model:value="user.u_pwd"
          placeholder="请输入新密码"
          size="large"
          style="margin-top: 10px"
        />
      </a-form-item>

      <!--确认密码 注意判断关系-->
      <a-form-item name="u_pwd2">
        <a-input-password
          v-model:value="user.u_pwd2"
          placeholder="确认密码"
          size="large"
          style="margin-top: 10px"
        />
      </a-form-item>

      <!--要看一下数据库有没有邮箱  且要保证邮箱格式正确-->
      <a-form-item>
        <div class="form-group">
          <a-input
            v-model:value="user.u_eml"
            type="email"
            class="radius"
            name="email"
            id="emailAd"
            placeholder="邮箱"
            size="large"
            style="width: 80%; margin-top: 10px"
            float:left
          />

          <a-button
            :disabled="user.u_eml == ''"
            type="primary"
            @click="sendMessage"
            id="sendCode"
            block
            class="btn btn-primary"
            style="
              width: 18%;
              background: #39c64e;
              margin-left: 5px;
              border: none;
              text-align: center;
              font-size: 3px;
              border-radius: 15px;
            "
          >发送验证码</a-button
          >
        </div>

        <div v-if="user.u_eml != ''" class="form-group">
          <a-input
            v-model:value="user.code"
            type="text"
            class="radius"
            id="code"
            name="code"
            placeholder="输入验证码"
            size="large"
            style="margin-top: 35px"
          />
        </div>
      </a-form-item>

      <a-form-item :wrapper-col="wrapperCol">
        <a-button
          :disabled="user.code == ''"
          type="primary"
          block
          @click="updatePwd"
          size="large"
          style="
            width: 100%;
            background: #39c64e;

            border: none;
          "
        >
          确认修改</a-button
        >
      </a-form-item>
    </a-form>
  </div>

</template>

<script setup>
import { ref, reactive, toRaw, defineComponent } from "vue";
import { useRouter } from "vue-router";
import {Form,message} from 'ant-design-vue'
import {post,get} from '../../main.js';
const temp = ref(false);
const useForm = Form.useForm;
const modelRef = reactive({
  name: "",
  region: undefined,
});
const rulesRef = reactive({
  u_answer: [
    {
      required: true,
      message: "请输入密保答案",
    },
    {
      min: 3,
      max: 10,
      message: "长度3-10",
      trigger: "blur",
    },
  ],
  u_question: [
    {
      required: true,
      message: "选择密保问题",
    },
  ],
});
const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);


const activeKey = ref("1");

const user = reactive({
  u_id: "",
  u_eml: "",
  u_pwd: "",
  u_pwd2: "",
  u_question: "",
  u_answer: "",
  code: "",
});


const validatePwd2 = async (rule, value) => {
  if (value === "") {
    return Promise.reject("请确认密码！");
  } else if (value !== user.u_pwd) {
    return Promise.reject("两次输入密码不一致!");
  } else {
    return Promise.resolve();
  }
};

const sendMessage = () => {
  var emailAd = document.getElementById("emailAd");
  console.log("+++++++++++++++++++++++++" + emailAd.value);
  if (emailAd.value == "") {
    alert("邮箱不能为空");
    return;
  }
  var email_reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

  if (!email_reg.test(emailAd.value)) {
    alert("请输入正确的邮箱地址");
    return;
  }

  get("/user/forget/getCode", { email: emailAd.value }).then((ref) => {
    message.success("验证码发送成功^ ^");

    if (ref.data.message == "success") {
      var wait = 60;
      var button = document.getElementById("sendCode");
      (function time(o) {
        if (wait == 0) {
          o.disabled = false;
          o.innerHTML = "发送验证码";
          wait = 60;
        } else {
          o.disabled = true;
          o.innerHTML = wait + "秒后";
          wait--;
          setTimeout(function () {
            time(o);
          }, 1000);
        }
      })(button);
    } else {
      console.log("data.failure");
    }
  });
};

const update = (user_temp) => {
  post("/user/forget", user_temp).then((res) => {
    message.success("密码修改成功");
    window.location.href = "#/login";
  });
};

const updatePwd = () => {
  var user_temp = {
    u_eml: user.u_eml,
    u_pwd: encode64(user.u_pwd),
  };
  var email = document.getElementById("emailAd");
  console.log("++++++++++++++++++++++++++++++这是emailAd" + emailAd.value);
  var code = document.getElementById("code");
  console.log("++++++++++++++++++++++++++++++这是code" + code);
  if (code.value == "") {
    alert("验证码不能为空");
    return;
  }
  get("/user/forget/updatePwd", {
    email: emailAd.value,
    code: code.value,
  }).then((ref) => {
    console.log(ref.data.message);
    if (ref.data.message == "success") {

      console.log("!!!!!!!!!!!!!" + user.u_eml); //正常

      update(user_temp);

      //window.location.href = "forget/result";
    } else {
      message.error("验证码错误");
      return;
    }
  });
};

//前端加密
var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"+ "wxyz0123456789+/" + "=";
function encode64(input) {
  var output = "";
  var chr1, chr2, chr3 = "";
  var enc1, enc2, enc3, enc4 = "";
  var i = 0;
  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
      + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    chr1 = chr2 = chr3 = "";
    enc1 = enc2 = enc3 = enc4 = "";
  } while (i < input.length);
  return output;
}

const rules = {
  u_eml: [
    {
      required: true,
      message: "请输入邮箱！",
      trigger: "blur",
    },
  ],
  u_pwd: [
    {
      required: true,
      message: "请输入密码！",
      trigger: "blur",
    },
    {
      min: 6,
      max: 20,
      message: "密码长度为6-20字符！",
      trigger: "blur",
    },
  ],
  u_pwd2: [
    {
      validator: validatePwd2,
      trigger: "blur",
    },
  ],
};
</script>

<style scoped>
html {
  height: 100%;
  width: 100%;
}

.set {
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.ant-tabs-nav .ant-tabs-tab-active :hover {
  color: #ffbd4a;
  font-weight: 500;
}
</style>
