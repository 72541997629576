import { createRouter, createWebHashHistory } from 'vue-router'
import Loginz from '../components/login/Loginz'
import Verify from '../components/login/Verify'
import Forget from '../components/login/Forget'
import Info from '../components/personal/Info'
import GlobalLayout from '../components/common/GlobalLayout'
import HomePage from '../components/common/HomePage'
import TestComponent from '../components/system/TestComponent'
import UserManage from '../components/system/UserManage'
import Register from '@/components/login/Register'
import CommonTemplateList from '@/components/template/PublicTemplateList'
import allTask from '@/components/task/annotater/manageTask/allTask'
import myTask from '@/components/task/annotater/manageTask/myTask'
import Settle from '@/components/bill/Settle'
import Data from '@/components/statistics/Data'

import DrawList from '@/components/task/annotater/annotateTask/DrawList'
import AnnotateTask from '@/components/task/annotater/annotateTask/AnnotateTask'
import MyTemplateList from "@/components/template/MyTemplateList";
import CreateNewTemplate from "@/components/template/CreateNewTemplate";

import CreateTask from "@/components/task/publisher/CreateTask"
import TaskList from "@/components/task/publisher/TaskList"
import TaskCheck from "@/components/task/publisher/TaskCheck"
import TaskExport from "@/components/task/publisher/TaskExport";
import Registerz from"@/components/login/Registerz";
import Xieyi from"@/components/login/Xieyi";


const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Loginz
  },
  {
    path: '/forget',
    component: Forget,
    redirect: '/verify',
    children: [
      {
        path:'/verify',
        component: Verify
      }
    ]
  },
  {
    path: '/register',
    component: Registerz
  },
  {
    path: '/xieyi',
    component: Xieyi
  },

  {
    path: '/home',
    component: GlobalLayout,
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        component: HomePage
      },
      {
        path: '/manage/task',
        component: TestComponent
      },
      {
        path: '/sys/user',
        component: UserManage
      },
      // 任务管理相关
      {
        path: '/task/drawList',
        component: DrawList
      },
      {
        path: '/task/annotate',
        component: AnnotateTask,
        name: "annotate"
      },
      // 模板管理相关路由
      {
        path: '/template/common',
        component: CommonTemplateList
      },
      {
        path: '/template/all',
        component: MyTemplateList
      },
      {
        path: '/template/custom',
        component: CreateNewTemplate
      },
      // 任务列表
      {
        path: '/task/draw',
        component: allTask
      },
      // 我的任务
      {
        path: '/task/history',
        component: myTask
      },
      //个人信息
      {
        path: '/info',
        component: Info,
      },
      //结算
      {
        path: '/bill',
        component: Settle,
      },
      //发布方任务管理
      {
        path: '/task/create',
        component: CreateTask,
        children: [
          {
            path: '/task/create/choosePublicTemplate',
            component: CommonTemplateList
          },
          {
            path: '/task/create/choosePrivateTemplate',
            component: MyTemplateList
          }
        ]
      },
      {
        path: '/task/all',
        component: TaskList
      },
      {
        path: '/task/check',
        component: TaskCheck
      },
      {
        path: '/task/export',
        component: TaskExport
      },
      {
        path: '/stat/data',
        component: Data
      }

    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
