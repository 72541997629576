<template>
  <!--  顶部面包屑-->
  <el-breadcrumb v-if="!isChoose">
    <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
    <el-breadcrumb-item>模板管理</el-breadcrumb-item>
    <el-breadcrumb-item>个人模板</el-breadcrumb-item>
  </el-breadcrumb>
  <!--  预览模板详情对话框-->
  <el-dialog
    center
    v-model="previewVis"
    width="80%"
  >
    <template #footer>
      <div>
        <one-template :template-info="currentTemplateInfo"></one-template>
      </div>
      <el-row justify="center">
        <el-button @click="previewVis = false">退出预览</el-button>
      </el-row>
    </template>
  </el-dialog>
  <!--  编辑模板详情对话框-->
  <el-dialog
    center
    title="修改模板"
    v-model="editVis"
    width="70%"
  >
    <template #footer>
      <el-row justify="center">
        <edit-template
          :template-info="currentTemplateInfo"
          @giveupEdit="finishEdit">
        </edit-template>
      </el-row>
    </template>
  </el-dialog>
<!--  没有模板时，显示结果画面-->
  <div v-if="templateList.length==0">
    <no-template-result
      status="404" view-button button-content="立即创建" enable-route
      route-path="/template/custom"
      title="模板库为空" sub-title="您暂未创建任何模板，快去定制一个您的专属模板吧~">
    </no-template-result>
  </div>
<!--  有模板时，显示模板-->
<el-row justify="center" style="height: 100%" v-else>
  <!--    卡片视图区-->
  <div class="main_card">
    <!--      模板列表-->
    <div>
      <el-row justify="space-between" style="height: 100%; width: 100%">
        <!--        循环显示每一个模板的小卡片-->
        <template-card v-for="(templateInfo, idx) in templateList"
                       style="height: 305px"
                       :key="idx"
                       :view-publish-status="true"
                       :view-edit-time="true"
                       :view-operate="true"
                       :use-operate="isChoose"
                       :edit-operate="!isChoose"
                       :publish-operate="!isChoose"
                       :recall-operate="!isChoose"
                       :delete-operate="!isChoose"
                       :template-info="templateInfo"
                       @editTemplate="editTemplate"
                       @changePublicStatus="changePublishStatus"
                       @deleteTemplate="deleteTemplate"
                       @choseTemplate="choseTemplate"
                       @previewDetail="previewTemplate">
        </template-card>
      </el-row>
    </div>
    <!--      分页区-->
    <div style="width: 100%">
      <el-row justify="center" style="width: 100%">
        <el-pagination
          v-model:currentPage="queryInfo.pageNum"
          :page-sizes="[1, 5, 10, 20]"
          :page-size="queryInfo.pageSize"
          layout="total, jumper, sizes, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</el-row>
</template>

<script>
import TemplateCard from "@/components/template/common/TemplateCard";
import OneTemplate from "@/components/template/common/OneTemplate"
import * as qs from "qs";
import CreateNewTemplate from "@/components/template/EditTemplate"
import Result from "@/components/utils/Result"
export default {
  name: "MyTemplateList",
  emits: ["choseTemplate"],
  components: {
    "template-card": TemplateCard,
    "one-template": OneTemplate,
    "edit-template": CreateNewTemplate,
    "no-template-result": Result
  },
  data () {
    return {
      isChoose: false,
      templateList: [],
      previewVis: false,
      editVis: false,
      currentTemplateInfo: {},
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      total: 1
    }
  },
  async mounted () {
    // 判断是否是在创建任务选择模板时，跳入此组件路由
    if (this.$route.query.isChoose) {
      this.isChoose = this.$route.query.isChoose
    }
    await this.getTemplateList()
  },
  methods: {
    async getTemplateList() {
      let userId = JSON.parse(window.sessionStorage.getItem('user')).user_id
      const { data: res } = await this.$http.post('/template/getTemplateList?userId=' + userId, this.queryInfo)
      if (res.code == 200) {
        this.templateList = res.data
      } else {
        if (!this.isChoose){
          this.$message.error(res.message)
        }
      }
      // 获取分页总数
      let arg = {
        pageSize: this.queryInfo.pageSize,
        userId: JSON.parse(window.sessionStorage.getItem('user')).user_id,
        pageKind: "templateList"
      }
      const { data: res2 } = await this.$http.get("/template/getPageTotalNum", {params: arg});
      this.total = res2.data
    },
    // 预览模板
    previewTemplate(info) {
      this.currentTemplateInfo = JSON.parse(info)
      this.previewVis = true
    },
    // 选择使用模板
    choseTemplate(templateId) {
      this.$emit("choseTemplate", templateId)
    },
    // 编辑模板
    editTemplate(info) {
      this.currentTemplateInfo = JSON.parse(info)
      this.editVis = true
    },
    // 结束编辑
    finishEdit() {
      this.editVis = false
      this.getTemplateList()
    },
    // 删除模板
    async deleteTemplate(info) {
      this.currentTemplateInfo = JSON.parse(info)
      const { data: res } = await this.$http.post('/template/deleteTemplate', this.currentTemplateInfo)
      if (res.code == 200 ) {
        this.$message.success(res.message)
        await  this.getTemplateList()
      } else {
        this.$message.error(res.message)
        await  this.getTemplateList()
      }
    },
    // 发布/召回模板
    async changePublishStatus(info) {
      info = JSON.parse(info)
      const { data: res } = await this.$http.post('/template/changePublishStatus', qs.stringify(info))
      if (res.code == 200 ) {
        this.$message.success(res.message)
        await  this.getTemplateList()
      } else {
        this.$message.error(res.message)
        await  this.getTemplateList()
      }
    },
    // 当页面大小变化时触发
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTemplateList()
    },
    // 当页面编号变化时触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTemplateList()
    }
  }
}
</script>

<style scoped>
/*主要卡片区*/
.main_card {
  margin-top: 20px;
  width: 100%;
  height: 90%;
}
</style>
