<template>
  用户管理界面
</template>

<script>
export default {
  name: 'UserManage'
}
</script>

<style scoped>

</style>
