<template>
  <div>
    <!--  顶部面包屑-->
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/homepage' }"
        >返回主页</el-breadcrumb-item
      >
      <el-breadcrumb-item>任务管理</el-breadcrumb-item>
      <el-breadcrumb-item>任务列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-container style="flex-direction: row; flex-wrap: wrap">
      <el-card
        class="my-box-card"
        v-for="(item, index) in TaskList"
        :key="index"
      >
        <div class="clearfix">
          <span style="font-size: 17px">{{ item.task_title }}</span>
          <el-button
            v-if="item.task_status == 1"
            type="primary"
            style="float: right; padding: 3px 0; width: 80px"
            @click="drawTask(item)"
          >
            领取任务
          </el-button>
          <el-button
            type="info"
            v-else
            style="float: right; padding: 3px 0; width: 80px"
            plain
            :disabled="true"
          >
            领取结束
          </el-button>
        </div>
        <div class="mytext item">
          {{ "发布者：" + item.user_name }}
        </div>

        <div class="mytext item">
          {{ "任务类型：" + item.task_kind }}
        </div>
        <div class="mytext item">
          {{ "发布时间：" + item.task_publish_time }}
        </div>
        <div class="mytext item">
          {{ "每题积分奖励： " + item.task_award }}
        </div>
        <div class="mytext item">
          {{ "时间限制： " + item.task_limit_time + "(小时)" }}
        </div>
        <div class="mytext item">
          {{ "任务要求：" + item.task_require }}
        </div>
        <div class="mytext item">
          {{ "任务描述：" + item.task_desc }}
        </div>
        <el-button type="text" style="float: right" @click="lookDetail(item)"
          >查看详情</el-button
        >
      </el-card>
    </el-container>

    <!-- 分页 -->
    <el-row justify="center" style="width: 100%; margin-top: 20px">
      <el-pagination
        v-model:currentPage="pageTaskList.pageNum"
        :page-sizes="[3, 6, 12, 18]"
        :page-size="pageTaskList.pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>

    <!-- <el-dialog title="提示" v-model="drawSuccess" width="20%" center>
      <span>领取成功</span>
      <span class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog title="提示" v-model="drawLittle" width="20%" center>
      <span>任务数量不足，以为您领取最大数量</span>
      <span class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog title="提示" v-model="drawFail" width="20%" center>
      <span>任务已被领取完</span>
      <span class="dialog-footer"> </span>
    </el-dialog> -->

    <!--  查看任务详情的对话框-->
    <el-dialog v-model="detailVis" width="40%">
      <one-task-info :task-info="taskInfo"></one-task-info>
      <template #footer>
        <el-row justify="center">
          <el-button @click="detailVis = false">退出查看</el-button>
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import OneTaskInfo from "@/components/task/OneTaskInfo";
export default {
  components: {
    "one-task-info": OneTaskInfo,
  },
  data() {
    return {
      radio: "1",
      detailVis: false,
      // 当前任务详情
      taskInfo: {},
      drawSuccess: false,
      drawLittle: false,
      drawFail: false,
      pageTaskList: {
        pageNum: 1,
        pageSize: 6,
      },
      total: 10,
      TaskList: [],
      dictionary: {
        1: "图片",
        2: "文本",
        3: "音频",
        4: "视频",
      },
    };
  },
  methods: {
    lookDetail(item) {
      console.log(item);
      this.detailVis = true;
      this.taskInfo = {
        taskTitle: item.task_title,
        taskType: item.task_kind,
        publisher: item.user_name,
        taskReward: item.task_award,
        taskDesc: item.task_desc,
        taskReq: item.task_require,
      };
    },
    // 当页面大小变化时触发
    handleSizeChange(newSize) {
      this.pageTaskList.pageSize = newSize;
      this.init();
    },
    // 当页面编号变化时触发
    handleCurrentChange(newPage) {
      this.pageTaskList.pageNum = newPage;
      this.init();
    },
    async drawTask(item) {
      console.log(item);

      const data = {
        user_id: JSON.parse(window.sessionStorage.getItem("user")).user_id,
        task_id: item.task_id,
        start_time: this.jsDateFormatter(new Date()),
      };
      console.log(data);
      const res = await this.$http.post(
        "/task/annotater/manageTask/drawTask",
        data
      );

      console.log(res);
      console.log(res.data.code);
      if (res.data.code == 200) {
        this.$message.success({
          message: res.data.message,
          center: true,
          duration: 1500,
        });
      } else if (res.data.code == 500) {
        this.$message.error({
          message: res.data.message,
          center: true,
          duration: 1500,
        });
      }
    },
    jsDateFormatter(dateInput) {
      // dateInput 是一个 js 的 Date 对象
      var year = dateInput.getFullYear();
      var month = dateInput.getMonth() + 1;
      var theDate = dateInput.getDate();

      var hour = dateInput.getHours();
      var minute = dateInput.getMinutes();

      if (month < 10) {
        month = "0" + month;
      }

      if (theDate < 10) {
        theDate = "0" + theDate;
      }

      if (hour < 10) {
        hour = "0" + hour;
      }

      if (minute < 10) {
        minute = "0" + minute;
      }

      return year + "-" + month + "-" + theDate + " " + hour + ":" + minute;
    },
    compare(a,b){
      return a.task_status - b.task_status;
    },
    async init() {
      const res = await this.$http.post(
        "/task/annotater/manageTask/TaskList",
        this.pageTaskList
      );

      console.log(res);
      console.log(res.data.message);
      this.TaskList = res.data.data;
      this.TaskList.sort(this.compare)
      this.TaskList.forEach((item, index) => {
        item.task_kind = this.dictionary[item.task_kind];
      });
      console.log(this.TaskList);
      this.total = Number(res.data.message);
    },
  },

  async created() {
    this.init();
  },
};
</script>

<style>
.mytext {
  font-size: 16px;
  padding: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*想省略几行就写几*/
  -webkit-box-orient: vertical;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.my-box-card {
  width: 380px;
  height: 340px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 30px;
}
</style>
