<template>


  <article id="app" class="tpl-wrap">
    <section
      style="min-width: 1280px"
      data-fid="MFsjWEwr2G"
      class="plat-section-outter-box container-wrapper"
    >
      <div
        class="t-space-container plat-section-space"
        style="width: 1280px; margin: auto"
      >
        <div
          id="MFsjWEwr2G"
          class="
            plat-section-inner-box
            a-wrap-module
            report-wrap-module report-scroll-module
          "
          data-container="section"
          data-module="RichText"
          scrollshow="true"
        >
          <div class="act-rich-style-init">
            <div class="act-rich-render-content">
              <p ><span style="font-size: 14px"> </span></p>
              <p style="text-align: center">
                <span style="font-size: 24px"
                ><strong>西交众包平台用户协议及隐私政策</strong></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left"></p>
              <p style="text-align:left"></p>
              <p style="text-align:left"></p>
              <p style="text-align:left"><span style="font-size: 14px;">版本更新提示：</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >感谢您使用西交众包平台！本次西交众包平台（以下称“我们”）依据相关法律法规和技术规范更新了一些内容，主要包括：<strong
                >增加同城功能下的发布内容展示开关（第四部分第4.14条）、缩短了客服响应期限（第十部分第2条）。</strong
                ></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >为了更好的保护您的个人信息，建议您仔细阅读更新后的《西交众包平台隐私政策》，尤其是以黑色加粗的条款。如您对本隐私政策条款有任何异议或疑问，您可通过本《西交众包平台隐私政策》第十条“联系我们”中公布的联系方式与我们沟通。</strong
                ></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>《西交众包平台隐私政策》目录</strong></span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">一、 引言</span></p>
              <p style="text-align:left"><span style="font-size: 14px">二、 关于我们</span></p>
              <p style="text-align:left"><span style="font-size: 14px">三、 名词解释</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >四、 我们如何收集和使用您的个人信息</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >五、 我们如何共享、转让、公开披露您的个人信息</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >六、 关于使用Cookie和同类技术</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >七、 我们对您个人信息的存储与保护</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">八、 您管理个人信息的权利</span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">九、 我们对未成年人的保护</span>
              </p>
              <p style="text-align:left"><span style="font-size: 14px">十、 联系我们</span></p>
              <p style="text-align:left"><span style="font-size: 14px">十一、 本政策如何更新</span></p>
              <p style="text-align:left"><span style="font-size: 14px">十二、 争议解决</span></p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"><strong>一、引言</strong></span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.本《西交众包平台隐私政策》（以下称“本隐私政策”）适用于西交众包平台的全部产品和服务，包括西交众包平台网站、iOS端应用程序、安卓端应用程序等全部终端客户端。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >特别提醒：由于西交众包平台的产品和服务较多，为您提供的产品和服务内容也有所不同，本隐私政策为西交众包平台统一适用的一般性隐私政策条款。针对西交众包平台的某些特定产品/服务，西交众包平台还将制定特定隐私政策，您应在充分阅读并同意特定隐私政策的全部内容后再使用该特定产品/服务。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >2.请您在使用西交众包平台各项产品和/或服务前，仔细阅读并充分理解本隐私政策的全部内容。一旦您使用或继续使用西交众包平台的产品/服务，即表示您同意我们按照本隐私政策使用和处理您的相关信息。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >3.我们可能会不时依据法律法规或业务调整对本隐私政策进行修订。当本隐私政策发生变更后，我们会在版本更新后通过在显著位置提示或推送通知、消息等形式向您展示变更后的内容。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >4.您需理解，只有在您确认并同意变更后的《西交众包平台隐私政策》，我们才会依据变更后的隐私政策收集、使用、处理和存储您的个人信息；您有权拒绝同意变更后的隐私政策，但请您知悉，一旦您拒绝同意变更后的隐私政策，可能导致您不能或不能继续完整使用西交众包平台的相关服务和功能，或者无法达到我们拟达到的服务效果。</strong
                ></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>二、关于我们</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.西交众包平台由西交小组数码科技有限公司（以下称“西交小组”）及其关联公司提供产品运营和服务。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.关联公司是指在现在、将来控制西交小组数码科技有限公司、受西交小组数码科技有限公司控制或与西交小组数码科技有限公司处于共同控制下的公司、机构。控制指通过所有权、有投票权的股份、合同、实际运营关联或其他被依法认定的方式直接或间接地拥有影响被控制对象管理/经营的能力。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.我们的主要运营主体的基本信息如下：西交小组数码科技有限公司，成立于2005年8月12日，联系电话为021-25099888，注册地址为上海市杨浦区政立路489号801室，常用办公地址为上海市杨浦区政立路485号国正中心3号楼。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>三、名词解释</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >除非另有约定，本隐私政策中使用到的名词，通常仅具有以下定义：</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.<strong>个人信息</strong>（出自于GB/T
                  35273-2020《信息安全技术 个人信息安全规范》）<strong
                  >：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括自然人的基本资料（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱地址）、个人身份信息（包括身份证件号码）、个人生物识别信息（包括指纹、面部特征）、网络身份标识信息（包括系统账号、IP地址、个人数字证书等）、个人财产信息（包括银行账号、口令、交易和消费记录、虚拟货币、虚拟交易、兑换码等虚拟财产信息）、通讯录信息、个人上网记录（包括网站浏览记录、软件使用记录、使用中的软件列表）、个人常用设备信息（包括硬件序列号、硬件型号、设备MAC地址、操作系统类型、软件列表、唯一设备识别码）、个人位置信息（包括大概地理位置、精准定位信息）。我们实际具体收集的个人信息种类以下文描述为准。</strong
                  ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.<strong>个人敏感信息</strong>（出自于GB/T
                  35273-2020《信息安全技术 个人信息安全规范》）<strong
                  >：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中涉及的个人敏感信息包括您的个人财产信息、个人身份信息、个人生物识别信息、网络身份标识信息、通讯录信息、精准定位信息、收货地址。我们实际具体收集的个人敏感信息种类以下文描述为准。</strong
                  ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.<strong>设备：</strong>是指可用于访问我们的产品和/或服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.<strong>唯一设备识别码</strong>（专属ID或UUID）：是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（如IMEI/android
                  ID/IDFA/OpenUDID/GUID/SIM卡IMSI信息等）。唯一设备识别码有多种用途，其中可在不能使用Cookie（例如在移动应用程序中）时用以提供广告。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >5.<strong>IP地址：</strong>每台上网的设备都会指定一个编号，称为互联网协议(IP)地址。这些编号通常都是根据地理区域指定的。IP
                  地址通常可用于识别设备连接至互联网时所在的位置。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >6.<strong>SSL：</strong>SSL（Secure Socket
                  Layer）又称为安全套接层，是在传输通信协议（TCP/IP）上实现的一种安全协议。SSL支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >7.<strong>Cookie：</strong>Cookie是包含字符串的小文件，在您登入和使用网站或其他网络内容时发送、存放在您的计算机、移动设备或其他装置内（通常经过加密）。Cookie同类技术是可用于与Cookie类似用途的其他技术，例如：Web
                  Beacon、Proxy、嵌入式脚本等。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >8.<strong>账号：</strong>当您注册账号并向我们提供一些个人信息，您就可以更好的使用我们的服务。当您访问西交众包平台时，系统会利用这些账号信息对您进行身份验证，以防止未经授权的人员访问您的账号。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >9.<strong>匿名化：</strong>是指通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >10.<strong>去标识化：</strong>是指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或关联个人信息主体的过程。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >11.<strong>服务器日志：</strong>通常情况下，我们的服务器会自动记录您在访问网站时所发出的网页请求。这些“服务器日志”通常包括您的网络请求、互联网协议地址、浏览器类型、浏览器语言、请求的日期和时间及可以唯一识别您的浏览器的一个或多个Cookie。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>四、我们如何收集和使用您的个人信息</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.西交众包平台依据法律法规以及遵循正当、合法、必要的原则而收集和使用您在使用服务过程中主动提供或由于产品和/或服务需要而产生的个人信息。如果我们欲将您的个人信息用于本隐私政策未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们会及时以合理的方式向您告知，并在使用前再次征得您的同意。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.我们收集和使用的您的个人信息类型包括两种：<strong
                >第一种：我们产品与/或服务的核心业务功能所必需的信息：此类信息为产品与/或服务正常运行的必备信息，您须授权我们收集。如您拒绝提供，您将无法正常使用我们的产品与/或服务；第二种：附加业务功能可能需要收集的信息：此信息为非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.<strong>我们需要特别提醒您的是：由于我们的产品和服务较多，为您提供的内容也不同，因此核心业务功能（包括其收集的您的个人信息类型）也会因产品/服务的内容不同而有所区别，具体以产品/服务实际提供为准。除此之外，您理解并同意，我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，包括技术，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人信息或变更个人信息使用目的或方式。如果某一需要收集您的个人信息的功能或产品/服务未能在本隐私政策中予以说明的，我们会通过更新本政策、页面提示、弹窗、网站公告等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您明示同意后收集。</strong>在此过程中，如果您对相关事宜有任何疑惑的，可以通过本隐私政策第十条提供的方式联系我们，我们会尽快为您作出解答。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.通常情况下，我们会在以下场景中收集和使用您的个人信息：</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.1账号注册</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong
                >您注册并登录西交众包平台账号时，需要按照我们的指引完成一系列注册程序，在此过程中，您需要向我们提供以下信息：账号昵称、手机号码或电子邮箱（个人敏感信息，用于接受验证码匹配个人身份）。您只有提供真实准确的上述信息，才能成功注册西交众包平台账号并使用产品和/或服务的核心功能。如果您选择不提供上述为实现西交众包平台核心产品功能的必备信息，或将导致我们无法为您提供该核心产品功能。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）我们的产品支持您使用第三方平台的账号（微博账号/QQ账号）进行登录，如您使用第三方平台的账号登录的，<strong>我们将根据您的授权获取该第三方账号下的相关信息（包括：用户名、昵称、头像等）以及身份验证信息（个人敏感信息）</strong>。我们承诺，收集上述信息是用于为您提供账号登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们平台，但不影响我们提供的其他产品和服务的正常使用。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （3）同时，您需理解，手机号码或电子邮箱地址和验证码匹配结果属于您的个人敏感信息，我们收集该类信息是基于法律法规的相关要求，如您拒绝提供可能导致您无法注册账号并使用相关产品功能，请您谨慎考虑后再选择是否提供。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.2搜索</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >当您在我们的产品中使用搜索功能时，我们需要收集您的一些信息，其中包括如下个人信息：您的设备信息、您的日志信息，包括您搜索的字或词、您的浏览记录和时间、您搜索的时间以及次数。我们收集这些信息是为了向您快速匹配您所需要的内容以及您可能感兴趣的内容，同时亦为了改进我们的产品和服务。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.3视频展示和播放</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）我们的产品和/或服务为您提供视频展示和播放服务，在此过程中，我们需要收集您的一些信息，包括如下个人信息：设备型号、设备名称、唯一设备识别码、浏览器类型和设置、语言设置、操作系统和应用程序版本、登录IP地址、接入网络的方式、网络质量数据、移动网络信息、产品版本号、网络日志信息（如操作日志、服务日志）。同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供视频展示和播放服务，如您拒绝提供上述权限将可能导致您无法使用我们的相关产品与服务。请您理解，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）当您使用安卓设备登录我们的产品并使用视频展示和播放时，您可以随时通过使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。安卓端西交众包平台APP的电话信息访问权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台查看电话信息”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.4客户服务</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >当您向西交众包平台发起投诉、申诉或进行咨询时，为了您的账号与系统安全，我们可能需要您先行提供账号信息，并与您之前的个人信息相匹配以验证您的用户身份。<strong>同时，为了方便与您联系或帮助您解决问题，我们可能还需要您提供姓名、手机号码、电子邮件及其他联系方式等个人信息（个人敏感信息）。另外，我们还会收集您与我们的沟通信息（包括文字/图片/音视频/通话记录形式）、与您的需求相关联的其他必要信息。</strong>我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.5图文、视频分享</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您在使用评论、弹幕、分享、发帖（包括投稿、动态、笔记和相簿）功能时，我们会收集您主动提供的上述图文、视频内容信息，同时我们还将为便于您分享视频和图片而经过您的同意后访问您的手机相册权限。</strong
                >同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。例如iOS端西交众包平台APP的相册权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台访问相册权限”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的对您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.6扫描二维码、拍摄照片（含拍摄视频服务、直播服务）</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用设备相机扫描二维码、拍摄照片、拍摄视频（包括直播）时，我们将访问您的设备相机相关权限，并收集您提供的基于扫描二维码、拍摄照片、拍摄视频后向我们上传的图片、视频信息。</strong
                >如您拒绝提供该权限和内容的，仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。西交众包平台APP的相机权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问相机”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">4.7录制视频、直播、实时语音</span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong>当您使用录制视频、直播或实时语音（包括小游戏的实时对战语音）服务时我们将获取访问您的设备录音（麦克风）相关权限，我们需要收集您的语音信息、语音交互信息（个人信息）。</strong>如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。西交众包平台APP的麦克风权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问麦克风权限”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）<strong>当您使用插入直播背景音乐服务时我们将获取访问您的设备存储文件相关权限，我们需要读取您存储的音频文件（个人信息）。</strong>如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。安卓端西交众包平台APP的存储权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台使用文件存储和访问功能”。iOS端（仅限iOS9.3及以上）西交众包平台APP的存储权限（媒体库）设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问媒体库”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >您开启上述权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.8预约、商品上线提醒</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用预约（例如追番）、商品上线（例如票务预订、手办预定）提醒功能时我们将访问您的设备日历相关权限，我们需要收集您的日历信息（个人信息）。</strong
                >如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。西交众包平台APP的日历权限设置路径为：我的—设置—隐私权限设置-允许西交众包平台访问日历信息”。您开启上述权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.9商品交易</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong>当您在我们的产品与/或服务中购买商品（例如在会员购中购买手办）或服务的，您需要提供一些与完成交易相关联的信息，包括如下：交易的商品或服务信息、收货人信息（收货人姓名、收货地址及其联系电话）（个人敏感信息）。</strong>此外，我们也可能会再收集一些其他与订单相关的信息，包括：<strong>交易金额、下单时间、订单编号、订单状态、支付方式、支付流水号、支付状态</strong>，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。<strong
                >进一步的，如果您的交易为跨境商品购买交易的，依据相关法律法规，我们还需要收集您的个人身份信息，包括身份证号码和身份证正反面照片（个人敏感信息）。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）当您成功下单后，如商品系第三方卖家提供的或商品需要第三方配送的，我们为了顺利、安全、准确向您完成货物的交付及配送，<strong>商家及第三方配送公司在发货及配送环节内不可避免地会获知您的相关配送信息（个人信息）</strong>。我们向您承诺，我们会以最大努力保障您的个人信息安全，严格要求商家及第三方配送公司对您的个人信息保密，只以配送之目的获悉和使用，不得对外泄露或做其他任何用途。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.10电子签约</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong>您使用我们的部分产品与/或服务前需通过在线电子签约系统完成电子签约，在使用在线电子签约系统时，依据相关法律法规，我们需要收集您的个人身份信息、个人生物识别信息以及个人财产信息，包括如下：电子合同文本、签署方信息（签署方姓名、住址及其联系电话）（个人敏感信息）、签署方的身份证号码（个人敏感信息）、面部识别特征（个人敏感信息）、银行账户（个人信息）。</strong>此外，我们也可能会再收集一些其他与电子签约相关的信息，包括：<strong>合同金额、合同期限、签约时间、合同编号、合同状态、支付方式、支付流水号、支付状态</strong>，我们收集这些信息是为了帮助您顺利完成电子签约、保障您的签约安全、查询合同信息、提供合同管理服务等。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）在您使用在线电子签约系统时，为了完成实名认证服务，我们将依据相关法律法规，根据不同的认证方式按需<strong>向在线电子签约系统供应商提供您的个人身份信息、个人生物识别信息以及个人财产信息，包括如下：签署方信息（签署方姓名、联系电话）（个人敏感信息）、签署方的身份证号码（个人敏感信息）、面部识别特征（个人敏感信息）、银行账户（个人信息）；为了准确验证您的身份，在线电子签约系统供应商需将上述信息提供给合法存有您个人信息的第三方机构（有关政府部门、电信运营商、中国银联、支付宝）进行比对核验。</strong>我们向您承诺，我们会以最大努力保障您的个人信息安全，严格要求在线电子签约系统供应商对您的个人信息保密，只以实名认证之目的获悉和使用，不得对无关方泄露或做其他任何用途。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.11支付</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您在西交众包平台上开通大会员服务、订购商品、充值B币、购买金瓜子等发生支付行为时，需要使用到支付功能。在支付过程中，我们可能会收集您的第三方支付渠道的user
                    ID（例如支付宝user ID、微信open ID、QQ钱包open
                    ID或银行卡信息）（个人敏感信息）。上述信息为我们向您提供您购买商品和/或服务所必须的信息，我们无法仅根据该信息获得您的个人财产信息，也不能识别特定自然人身份信息。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.12定向推荐、维护/改进我们的产品与/或服务之必需</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）我们会基于收集的信息，对您的偏好、习惯、位置作特征分析和用户画像，以便为您提供更适合的定制化服务，例如向您展现或推荐相关程度更高（而非普遍推送）的搜索结果、视频内容、线下活动、信息流或者广告/推广信息结果。为此，我们需要收集的信息包括您的设备信息（例如硬件型号、设备MAC地址、IMEI、IDFA、IMSI、必要的软件列表）、浏览器型号、日志信息、浏览记录、点赞/分享/评论/互动的对象、搜索的字词、<strong>个人位置信息（其中精准定位信息为个人敏感信息）</strong>。例如：推荐您可能喜欢的番剧、广告、当前城市的漫展等。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >为保障您接收信息的自主性，当您对我们推荐的个性化内容不感兴趣或希望减少某些信息推荐时，您可以在app内通过点击推荐内容右下角的按钮，并选择相关理由，进行精准屏蔽。当您对我们推荐的个性化广告不感兴趣或希望不再接收此类个性化广告，您可以使用我们的产品与/或服务的相关功能设置页面开启/关闭个性化广告推荐。西交众包平台APP的个性化广告推荐的设置路径为：“我的—设置—隐私权限设置—允许西交众包平台个性化展现广告推荐”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >同时，如您不希望接收到我们为您推送的个性化内容，您可以随时使用我们的产品与/或服务的相关功能设置页面开启/关闭推送。西交众包平台APP的内容推送设置路径为：“我的—设置—推送设置—推送我可能感兴趣的内容”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）<strong>特别地，为了满足会员购票务业务定位您所在位置周边的线下活动信息功能，我们将获取您设备的IP地址以及您的个人位置信息（其中精准定位信息为个人敏感信息），您可以授权提供给我们您的位置信息（您知悉并同意，在某些特定业务场景下，为了提高业务处理能力、降低成本或其他方面考虑，我们可能会使用有能力的第三方定位SDK来获取您的相关信息，例如：使用“腾讯定位”SDK获取您的IMEI信息。但未经您的同意，我们不会主动将前述信息传输至该第三方），以便我们向您推荐所在地区的最新消息或更适合您的服务内容等。</strong>如您决定退出我们基于位置信息作出的个性化推荐/定向推送的，您可通过您设备的相关功能设置开启/取消该权限。西交众包平台APP的位置使用权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问位置信息”</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （3）更进一步的，我们可能会收集您使用我们的产品与/或服务时的搜索记录、浏览记录、收藏记录、观看时长、订单信息、设备信息、点赞/分享/评论/互动的对象、您的偏向网络行为、兴趣偏好等信息形成用户画像用于帮助使我们更加了解您如何接入和使用我们的产品与/或服务、维护和改进我们的产品与/或服务或对您作出其他方面的回应。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （4）<strong>如您使用iOS设备登录我们的产品并且您的设备系统为iOS14及以上时，您可以选择关闭精准定位信息（个人敏感信息），此时我们将仅获取您的大概地理位置（个人信息），关闭精准定位信息不会影响您正常使用该功能。</strong>您也可以随时通过您的设备系统的相关功能设置页面开启/取消该权限。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.13动态、小程序</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>
                    （1）当您开启了您的设备定位功能并同时在发布动态或者运行小程序（小游戏）时选择使用我们基于地理位置提供的定位功能时，我们可能会收集有关您的个人位置信息（其中精准定位信息为个人敏感信息）。</strong
                >如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。西交众包平台APP的位置权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台访问位置信息”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）<strong>如您使用iOS设备登录我们的产品并且您的设备系统为iOS14及以上时，您可以选择关闭精准定位信息（个人敏感信息），此时我们将仅获取您的大概地理位置（个人信息），关闭精准定位信息不会影响您正常使用该功能。</strong>您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.14同城</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong
                >当您开启了您的设备定位功能并同时使用同城服务时，我们会向您展示您周边的动态信息、显示您与动态发布者发布位置的距离以及将您发布的动态信息推送至同城页面，为此我们可能会收集有关您的个人位置信息（其中精准定位信息为个人敏感信息）以及您设备的IP地址。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >如您拒绝提供个人位置信息并不影响您正常使用本功能，但会使您在使用同城浏览服务时无法获得您与动态发布者发布位置的距离。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消位置信息权限。西交众包平台APP的位置权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台访问位置信息”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >我们收集IP地址信息是为了向您提供展示同城信息的服务，如您拒绝提供上述权限将可能导致您无法使用我们的相关产品与服务。请您理解，单独的IP地址等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）<strong>如您使用iOS设备登录我们的产品并且您的设备系统为iOS14及以上时，您可以选择关闭精准定位信息（个人敏感信息），此时我们将仅获取您的大概地理位置（个人信息），关闭精准定位信息不会影响您正常使用本功能，仅会使您在使用同城浏览服务时无法获得您与动态发布者发布位置的距离。</strong>您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （3）<strong>您可以拒绝将您发布的动态信息推送至同城页面，您拒绝将您发布的动态信息展示在同城页面并不影响您正常使用本功能，但会使您的动态信息无法被其他同城用户浏览且其他同城用户无法获得与您的发布位置的距离。</strong>您可以随时通过我们的产品与/或服务的相关功能设置页面开启/取消上述权限。西交众包平台APP的位置权限设置路径为：“我的—设置—隐私权限设置-允许我发布的内容在同城中展示”。您开启该权限即视为您授权我们可以在同城功能中展示您投稿信息以及其他同城用户与您的发布距离（其中精准定位信息为个人敏感信息）；但当您取消该授权后，我们将不再展示该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.15认证服务</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >我们为个人用户提供在西交众包平台平台的身份认证服务。当您申请西交众包平台为您进行身份认证时，我们需要收集您的姓名、身份证件号码、有关身份证明材料（个人敏感信息），以证实您的个人身份。</strong
                >我们会在对您个人信息的真实性进行审核后，会为您标记认证标识，并提供认证账号的专属服务。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.16视频及截图缓存</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用移动设备登录我们的产品并使用视频缓存、截图缓存功能时，我们可能会获取有关您设备的存储权限。</strong
                >如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。西交众包平台APP的存储权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台使用文件存储和访问功能”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.17投屏</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用iOS设备登录我们的产品并使用投屏功能时，我们可能会获取有关您设备所处本地网络的访问权限，并收集该本地网络中的设备信息（包括硬件型号、生产厂家、设备解码器信息）。</strong
                >如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，<strong>如果您的设备系统为iOS14及以上时，</strong>您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。iOS端西交众包平台APP的本地网络访问权限设置路径为：“我的—设置—隐私权限设置-允许西交众包平台访问本地网络”。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.18捷径</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用iOS设备登录我们的产品并使用捷径功能时，我们可能会获取您设备的Siri使用权限，并收集您的语音信息、语音交互信息（个人信息）。</strong
                >如您拒绝提供仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，<strong>如果您的设备系统为iOS10及以上时，</strong>您也可以随时通过您的设备系统或使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。iOS端西交众包平台APP的Siri使用权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问Siri”。您开启上述权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">4.19隐藏消息列表</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >当您使用隐藏消息列表功能（本功能可以让您在多个设备上同时登录并运行我们的产品时，选择仅在某一台设备显示消息列表并隐藏其他设备的消息列表）时，我们可能会搜集您的设备目前正在使用中的软件列表。</strong
                >请您理解，单独的使用中的软件列表无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.20使用蓝牙设备、线下展会互动小游戏</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>
                    （1）当您使用蓝牙设备并开启蓝牙功能时，我们可能会搜集您的设备目前正在使用中的蓝牙设备连接状态，并提供包括蓝牙耳机音量调节等功能。</strong
                >请您理解，单独的蓝牙设备连接状态无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>
                    （2）当您参加JiaolijiaoliWorld线下展会并开启蓝牙功能时，我们会提供相应的展会任务（如探寻和捕获身边的UP主）以及互动游戏。为此，我们需要搜集您的蓝牙连接状态并扫描周围的蓝牙列表。</strong
                >请您理解，单独的蓝牙连接状态、周围蓝牙列表等无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >如您不希望使用上述功能，您可以随时通过您的设备系统的相关功能设置页面开启/取消蓝牙功能。您开启设备蓝牙功能即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；但当您关闭设备蓝牙功能后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >同时，<strong>如果您使用iOS设备使用上述功能并且您的设备系统为iOS13.1及以上时，</strong>您也可以随时通过使用我们的产品与/或服务的相关功能设置页面开启/取消该权限。iOS端西交众包平台APP的Siri使用权限设置路径为：“我的—设置—隐私权限设置—允许西交众包平台访问蓝牙”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.21从第三方间接收集的您的个人信息</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）<strong>您理解并知悉，您向外部第三方（西交众包平台旗下关联公司不在此限）提供的个人信息，或外部第三方收集的您的个人信息，我们无法获取，更不会使用非常规方式（如：恶意干预对方系列APP数据）擅自以软件程序获得您的个人信息。</strong>西交众包平台可能因业务发展的需要而确实需要从第三方间接收集（如共享等）您的个人信息的，且由我们直接或共同为您提供产品或服务的，我们（或第三方）在收集前会向您明示共享的您个人信息的来源、类型、使用目的、方式和所用于的业务功能、授权同意范围（如果使用方式和范围超出您在第三方原授权范围的，我们会再次征得您的授权同意）。我们的某些产品或服务由第三方业务合作伙伴提供或共同提供时，为了必要且合理的开展业务，我们可能会从部分业务合作伙伴处间接收集的您的部分信息、其他方使用我们的产品与/或服务时所提供有关您的信息。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）我们的专业安全团队对个人信息将进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.22出于其他合理且必要的目的</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）如前文所述，<strong
                >如果某一需要收集您的个人信息的功能或产品/服务未能在本隐私政策中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们将在收集和使用您的个人信息前，通过更新本隐私政策、页面提示、弹窗、站内信、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您明示同意后收集和使用。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）<strong
                >您理解并同意，在以下情况下，我们无需取得您的授权同意即可收集和使用您的个人信息：</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  a)与国家安全、国防安全有关的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  b)与公共安全、公共卫生、重大公共利益有关的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  c)与犯罪侦查、起诉、审判和判决执行等直接相关的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  d)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  e)所收集的信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  f)根据与您签订和履行相关协议或其他书面文件所必需的；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  g)用于维护我们的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  h)有权机关的要求、法律法规等规定的其他情形。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >五、我们如何共享、转让、公开披露您的个人信息</strong
                ></span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">1.共享</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  我们重视对您的个人信息的保护，您的个人信息是我们为您提供产品与/或服务的重要依据和组成部分，对于您的个人信息，我们仅在本隐私政策所述目的和范围内或根据法律法规的要求收集和使用，并严格保密。<strong
                >通常情况下，我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.1事先已获得您的明确授权或同意；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.2根据适用的法律法规、法律程序、政府的强制命令或司法裁定而需共享您的个人信息；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.3在法律要求或允许的范围内，为了保护西交众包平台及其用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.4您的个人信息可能会在我们的关联公司之间共享。我们只会共享必要的个人信息，且这种共享亦受本隐私政策声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.5<strong
                >为了向您提供更完善、优质的产品和服务，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。同时，我们会与合作伙伴签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理您的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。如果您拒绝我们的合作伙伴在提供服务时收集为提供服务所必须的个人信息，将可能导致您无法在我们的平台中使用该第三方服务。通常我们的合作伙伴有如下几类：</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）为我们的产品与/或服务提供功能支持的服务提供商：例如提供支付服务的支付机构、提供配送服务的第三方公司和其他服务提供商（如互联网电视播控牌照方），我们共享信息的目的仅为实现我们产品与/或服务的功能；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）我们平台的第三方商家：<strong>为实现您购买产品或服务的需求（包括享受售后服务），我们会将您的订单信息与交易相关的信息共享给我们平台的第三方商家。</strong>例如，当您在西交众包平台会员购订购演出票务时，西交众包平台会向该演出的主办方提供您的必要信息，以便向您寄送演出门票。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （3）<strong
                >第三方SDK类服务商：我们的产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。目前我们产品中包含的第三方SDK服务请具体查阅<a
                  target="_self"
                >《西交众包平台第三方SDK目录》</a
                >。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （4）委托我们进行信息推广和广告投放的合作伙伴：<strong
                >您确认我们有权与委托我们进行信息推广和广告投放的合作伙伴共享我们使用您的相关信息集合形成的间接用户画像、去标识化或匿名化处理后的分析/统计类信息（例如「位于上海的二十代男性，喜欢音乐」），以帮助其进行广告或决策建议、提高广告有效触达率、进一步了解用户需求。我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息。</strong
                ></span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">2.转让</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  除非获取您的明确同意，我们不会将您的个人信息转让给任何公司、组织或个人。如果发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束。如果本隐私政策中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。</span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">3.公开披露</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.1公开披露是指向社会或不特定人群发布信息的行为。除了因需要对违规账号、欺诈行为等进行处罚公告、公布中奖/获胜者等名单时脱敏展示相关信息等必要事宜而进行的必要披露外，我们不会对您的个人信息进行公开披露，如具备合理事由确需公开披露的，我们会在公开披露前向您告知公开披露的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的授权同意后再公开披露，但法律法规另有规定的或本政策另有约定的除外。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.2对于公开披露的您的个人信息，我们会在收到公开披露申请后第一时间且审慎审查其正当性、合理性、合法性，并在公开披露时和公开披露后采取最严格个人信息安全保护措施和手段对其进行保护。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.共享、转让、公开披露您的个人信息的例外<br />
                  <strong
                  >请您知悉，根据法律法规的规定，在下述情况下，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</strong
                  ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.1<strong>与国家安全、国防安全直接相关的；</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.2<strong
                >与公共安全、公共卫生、重大公共利益直接相关的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.3<strong
                >与犯罪侦查、起诉、审判和判决执行等直接相关的；或根据法律法规的要求、行政机关或公检法等有权机关的要求的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.4<strong
                >出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.5<strong
                >个人信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.6<strong
                >根据与您签订和履行相关协议或其他书面文件所必需的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.7<strong>法律法规等规定的其他情形。</strong></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>六、关于使用Cookie和同类技术</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.在您使用我们的产品与/或服务时，我们可能会使用Cookie和同类技术收集您的一些个人信息，包括：您访问网站的习惯、您的浏览信息、您的登录信息，Cookie和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注册、登录）、便于您查看使用历史（如视频观看历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信息和账号安全性、提升我们的产品和服务等。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.如果您拒绝我们使用Cookie及同类技术收集和使用您的相关信息，您可在浏览器具备该功能的前提下，通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。<strong
                >您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。</strong
                ></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>七、我们对您个人信息的存储与保护</strong></span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">1.信息存储</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.1<strong
                >存储地点：我们依照法律法规的规定，仅会将收集到的有关您的个人信息存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人信息或向境外提供个人信息的场景。如果确有必要进行数据的跨境传输，我们会单独向您明确告知（包括数据出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等），并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.2<strong
                >存储期限：我们在为您提供满足产品和服务目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册及会员功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续保存您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。此外，我们或对您的相关信息保存至相关法律所规定的必要期限（例如《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.3<strong
                >在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。</strong
                ></span
                >
              </p>
              <p style="text-align:left"><span style="font-size: 14px">2.信息保护</span></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.1您的个人信息安全对于我们至关重要。我们将严格遵守相关法律法规，采取业内认可的合理可行的措施，保护您的个人信息。防止信息遭到未经授权的访问、披露、使用、修改，避免信息损坏或丢失。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.2<strong
                >我们使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如使用SSL对数据进行加密保护；我们已建立访问控制机制，确保只有授权人员才可以访问个人信息；我们不时的举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.3为防止安全事故的发生，我们成立了专门的数据安全部门并制定了妥善的预警机制和应急预案。一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.4同时，我们还设立西交众包平台安全应急响应中心（https://security.bilibili.com），由专业的安全技术和运营团队负责维护，便于及时有效的响应、处置各类安全漏洞和突发事件，联合相关部门进行安全事件溯源和打击。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>八、您管理个人信息的权利</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong
                >我们理解您对个人信息的关注，并尽全力确保您对于自己个人信息访问、更正、删除以及撤回授权的权利，以使您拥有充分的能力保障您的隐私和安全。</strong
                >您的权利包括：</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">1.您有权访问您的个人信息</span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  您可以按照我们提供的产品和服务的相关说明（或设置），对您已提供给我们的相关个人信息进行查阅。包括：</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.1账号信息：您可以通过相关产品页面随时登陆您的个人中心，以访问您账号中的个人资料信息，包括：头像、昵称、UID、二维码名片、性别、出生年月、个人签名等；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.2使用信息：您可以通过相关产品页面随时查阅您的使用信息，包括：动态发布内容、投稿内容、收藏记录、历史记录、订单信息、地址信息、账单记录等；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.3其他信息：如您在访问过程中遇到操作问题需获取其他前述无法获知的个人信息内容，您可通过本隐私政策提供的方式联系我们。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.<strong>您有权更正/修改您的个人信息</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  当您发现您提供给我们的个人信息存在登记错误、不完整或有更新的，您可在我们的产品和/或服务中更正/修改您的个人信息。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.1对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作设置，您可以直接进行更正/修改，例如：“头像/昵称/性别/出生年月/个人签名”在西交众包平台iOS客户端中的更正/修改路径为：点击头像-编辑资料。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.2对于您在行使上述权利过程中遇到的困难，或者其他可能目前无法向您提供在线自行更正/修改服务的，经过对您身份的验证，且更正/修改不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或修改，或在特定情况下，尤其是数据错误时，通过我们公布的反馈与报错等措施将您的更正/修改申请提交给我们，要求我们更正或修改您的数据，但法律法规另有规定的除外。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.<strong>您有权删除您的个人信息</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.1对于您的部分个人信息，您也可以自行通过我们提供的相关产品和服务的功能页面，主动删除您提供信息。例如：“历史记录”信息在西交众包平台iOS客户端中的删除路径为：我的-历史记录-编辑。您也可以自主删除您发布的视频、动态、图片等。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.2当发生以下情况时，您可以直接要求我们删除您的个人信息，但已做匿名化处理或法律法规另有规定的除外：</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （1）当我们处理个人信息的行为违反法律法规时；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （2）当我们收集、使用您的个人信息，却未征得您的同意时；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （3）当我们处理个人信息的行为违反了与您的约定时；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （4）当您注销了西交众包平台账号时；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  （5）当我们终止服务及运营时。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >4.<strong>您有权撤回您对个人信息的授权</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  如前文所述，我们提供的产品和服务的部分功能需要获得您使用设备的相关权限（包括：位置、相机、麦克风、日程安排等，具体以产品实际获取的功能为准）。您可以在授权后随时撤回（或停止）对该权限的继续授权。例如您可以通过iOS设备中的“设置-隐私-照片”来关闭您对手机相册的授权。您也可以通过注销账号的方式，永久撤回我们继续收集您个人信息的全部授权。您需理解，当您撤回授权后，我们无法继续为您提供撤回授权所对应的特定功能和/或服务。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >5.<strong>您有权注销您的账号</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">
                  您可以通过在线申请注销或客服或通过其他我们公示的方式申请注销您的账号。当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务；且该账号在西交众包平台及旗下的其他产品与服务使用期间已产生的但未消耗完毕的权益及未来的逾期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）；西交众包平台账号注销完成后，将无法恢复。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >更多关于西交众包平台账号注销的流程、条件等事项请详见</span
                ><a
                target="_self"
              ><span style="font-size: 14px">《账号注销协议》</span></a
              ><span style="font-size: 14px">。</span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >如您在谨慎考虑后仍执意决定注销您的西交众包平台账号的，您可以在您使用的我们的产品与/或服务的相关功能设置页面或根据操作指引向我们提交注销申请，例如iOS端西交众包平台APP的注销路径为：“我的—设置—隐私权限设置-账号注销”。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >如果您在处置您的个人信息时有任何疑问，您可以通过本隐私政策第十条“联系我们”中公示的联系方式与我们沟通解决。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>九、我们对未成年人的保护</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.未成年人使用我们的产品与/或服务前应取得其监护人的同意。<strong>如您为未成年人，在使用我们的产品与/或服务前，应在监护人监护、指导下共同阅读本隐私政策且应在监护人明确同意和指导下使用我们的产品与/或服务、提交个人信息。</strong>我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律法规允许、监护人明确同意或保护您的权益所必要的情况下收集、使用或公开披露未成年人的个人信息。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，您可以通过本隐私政策第十条“联系我们”中公示的联系方式与我们沟通解决。如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会尽快删除相关数据。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>十、联系我们</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.如您对本《西交众包平台隐私政策》的内容或使用我们的服务时遇到的与隐私保护相关的事宜有任何疑问或进行咨询或投诉时，您均可以通过如下任一方式与我们取得联系：</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.1您可找到我们平台上产品功能页面中在线客服与其联系或者在线提交反馈意见；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.2您可以向我们开通的help@bilibili.com客服邮箱发送邮件与我们联系；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.3您可以写信至：上海市杨浦区政立路485号国正中心3号楼
                  法务部（收）；邮编：200082。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.我们会在收到您的意见及建议后，并在验证您的用户身份后的15个工作日内尽快向您回复。<strong
                >此外，您理解并知悉，在与个人信息有关的如下情形下，我们将无法回复您的请求：</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.1<strong>与国家安全、国防安全有关的；</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.2<strong
                >与公共安全、公共卫生、重大公共利益有关的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.3<strong>与犯罪侦查、起诉和审判等有关的；</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.4<strong
                >有充分证据表明您存在主观恶意或滥用权利的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.5<strong
                >响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.6<strong>涉及商业秘密的；</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.7<strong>法律法规等规定的其他情形。</strong></span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>十一、本政策如何更新</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本《隐私政策》的条款，该等更新构成本《隐私政策》的一部分。未经您明确同意，我们不会削减您按照本政策所应享有的权利。<strong
                >如该等更新造成您在本《隐私政策》下权利的实质减少或重大变更，我们将在本政策生效前通过在显著位置提示或向您发送推送消息或以其他方式通知您，若您继续使用我们的服务，即表示您充分阅读、理解并同意受经修订的《隐私政策》的约束。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px">2.重大变更包括但不限于：</span>
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.1我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.2我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.3个人信息共享、转让或公开披露的主要对象发生变化；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.4您参与个人信息处理方面的权利及其行使方式发生重大变化；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.5我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.6个人信息安全影响评估报告表明存在高风险时。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                ><strong>十二、争议解决</strong></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >1.<strong
                >本隐私政策的解释及争议解决均应适用中华人民共和国大陆地区法律。任何因本隐私政策以及我们处理您个人信息事宜引起的争议，您同意向中国广州仲裁委员会提起仲裁解决；该仲裁一裁终局，对双方均具有法律约束力。</strong
                ></span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >2.如果您认为我们处理个人信息的行为损害了您的合法权益，您也可以选择向有关政府部门进行反映。</span
                >
              </p>
              <p style="text-align:left">
                <span style="font-size: 14px"
                >3.本隐私政策的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。</span
                >
              </p>
              <p style="text-align:left"></p>
              <p style="text-align:left"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>

</template>

<script setup>

</script>

<style scoped>
html {
  height: 100%;
  width: 100%;
}

.home-bottom {
  bottom: 0;
  height: 38px;
  width: 100%;
  background-color: #fdf0e0;
}
.home-bottom-inform {
  font-size: 10px;
  align-content: center;
  color: #e79229;
}

.top {
  height: 20%;
  width: 100%;
  background-size: cover;
  display: flex;

  flex-direction: column;
}
</style>
