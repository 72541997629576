<template>
  <div>
    <el-row>
      <el-col :span="24">
        <h3 style="font-weight: bolder;color: #ce764e;font-size: larger">所有任务详细信息列表:</h3>
        <div class="grid-content bg-purple">
          <el-table height="250" :data="tableData.filter((data) =>!search || data.name.toLowerCase().includes(search.toLowerCase()))" style="width: 100%" border :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column label="发布日期" prop="date" sortable />
            <el-table-column label="任务名称" prop="name" sortable />
            <el-table-column label="领取用户数" prop="number" sortable />
            <el-table-column label="总数据量" prop="total" sortable />
            <el-table-column label="状态" prop="state" sortable  />
            <el-table-column  align="right">
              <template #header>
                <el-input v-model="search" size="mini" placeholder="Search" />
              </template>
              <template #default="scope">
                <el-button size="mini" type="success" @click="handleEdit(scope.$index, scope.row)">结果导出</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tableData: [
          {
            date: '2016-05-03',
            name: '解决一道数学题',
            number:'20/200',
            total:'100',
            time:'2021/12/12',
            state:'未分配完'
          },
          {
            date: '2016-06-03',
            name: '软件开发算法题',
            number:'20/200',
            total:'100',
            time:'2021/12/12',
            state:'已分配'
          },
          {
            date: '2016-07-03',
            name: '软件开发算法题',
            number:'20/200',
            total:'100',
            time:'2021/12/12',
            state:'待审核'
          },
          {
            date: '2016-05-03',
            name: 'Java开发算法题',
            number:'20/200',
            total:'100',
            time:'2021/12/12',
            state:'已结束'
          },
        ],
        search: '',
        form: {
          name: '',
          desc: '',
          number:'',
          reward:'',
          maxNumber:'',
          date1: '',
          date2: ''
        },
      }
    },
    methods: {
      handleEdit(index, row) {
        console.log(index, row)
      },
      handleDelete(index, row) {
        console.log(index, row)
      },
    },
  }
</script>

<style scoped>

</style>
