<template>
  <div>
    <my-component></my-component>
  </div>
</template>

<script>
export default {
  name: 'TestComponent'
}
</script>

<style scoped>
</style>
