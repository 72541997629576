<template>
<!--  顶部面包屑-->
    <el-breadcrumb v-if="!isChoose">
      <el-breadcrumb-item :to="{ path: '/homepage' }">返回主页</el-breadcrumb-item>
      <el-breadcrumb-item>模板管理</el-breadcrumb-item>
      <el-breadcrumb-item>常用模板</el-breadcrumb-item>
    </el-breadcrumb>
<!--  没有公开模板时，显示提示页面-->
<div v-if="templateList.length==0">
  <no-template-result
    status="403" view-button button-content="我去发布" enable-route
    route-path="/template/all"
    title="模板库为空" sub-title="目前还没有可用的公共模板，您可以发布您创建的模板~">
  </no-template-result>
</div>
<!--  有公开模板时，显示公开模板-->
  <el-row justify="center" style="height: 100%" v-else>
<!--    卡片视图区-->
    <div class="main_card">
<!--      模板列表-->
      <div>
        <el-row justify="space-between" style="height: 100%; width: 100%">
          <!--        循环显示每一个模板的小卡片-->
          <template-card v-for="(templateInfo, idx) in templateList" style="height: 305px"
                         :key="idx"
                         :view-publisher="true"
                         :view-edit-time="true"
                         :view-operate="true"
                         :use-operate="isChoose"
                         :template-info="templateInfo"
                         @choseTemplate="choseTemplate"
                         @previewDetail="preview">
          </template-card>
        </el-row>
      </div>
      <!--      分页区-->
      <div>
        <el-row justify="center">
          <el-pagination
            v-model:currentPage="queryInfo.pageNum"
            :page-sizes="[1, 5, 10, 20]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </div>
    </div>
  </el-row>
<!--  预览模板详情对话框-->
  <el-dialog
    center
    v-model="previewVis"
    width="80%"
  >
    <template #footer>
      <one-template
        :template-info="currentTemplateInfo">
      </one-template>
      <el-row justify="center">
        <el-button @click="previewVis = false">退出预览</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import TemplateCard from "@/components/template/common/TemplateCard";
import OneTemplate from "@/components/template/common/OneTemplate"
import Result from "@/components/utils/Result"
export default {
  name: "CommonTemplates",
  emits: ["choseTemplate"],
  components: {
    "template-card": TemplateCard,
    "one-template": OneTemplate,
    "no-template-result": Result
  },
  data () {
    return {
      isChoose: false,
      templateList: [],
      previewVis: false,
      currentTemplateInfo: {},
      queryInfo: {
        pageNum: 1,
        pageSize: 5
      },
      total: 100
    }
  },
  async mounted () {
    // 判断是否是在创建任务选择模板时，跳入此组件路由
    if (this.$route.query.isChoose) {
      this.isChoose = this.$route.query.isChoose
    }
    await this.getTemplateList()
  },
  methods: {
     async getTemplateList() {
      const { data: res } = await this.$http.post('/template/getDefaultTemplateList', this.queryInfo)
      if (res.code == 200) {
        if (!this.isChoose){
          this.$message.success('获取公共模板成功')
        }
        this.templateList = res.data
      } else {
        if (!this.isChoose){
          this.$message.error('获取公开模板失败')
        }
      }
       // 获取分页总数
       let arg = {
         userId: JSON.parse(window.sessionStorage.getItem('user')).user_id,
         pageSize: this.queryInfo.pageSize,
         pageKind: "defaultTemplateList"
       }
       const { data: res2 } = await this.$http.get("/template/getPageTotalNum", {params: arg});
       this.total = res2.data
    },
    // 预览模板
    preview(info) {
      this.previewVis = true
      this.currentTemplateInfo = JSON.parse(info)
    },
    // 选择使用模板
    choseTemplate(templateId) {
       this.$emit("choseTemplate", templateId)
    },
    // 当页面大小变化时触发
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTemplateList()
    },
    // 当页面编号变化时触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTemplateList()
    }
  }
}
</script>

<style scoped>
/*主要卡片区*/
.main_card {
  margin-top: 20px;
  width: 100%;
  height: 90%;
}
</style>
