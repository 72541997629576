<template>
  <div class="header">
<!--    用户中心下拉菜单-->
   <el-dropdown>
      <span class="el-dropdown-link" style="height: 100%; font-size: 15px">
        {{ userInfo.realName}},您好!<el-icon><user-filled /></el-icon>
      </span>
     <template v-slot:dropdown>
       <el-dropdown-menu>
         <!--        用户姓名-->
         <el-dropdown-item icon="el-icon-s-custom">
           <span @click="gotoPersonal">个人信息</span>
         </el-dropdown-item>
         <!--        退出登录-->
         <el-dropdown-item icon="el-icon-caret-left">
           <span @click="logout">退出登录</span>
         </el-dropdown-item>
       </el-dropdown-menu>
     </template>
    </el-dropdown>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'

export default {
  name: 'Header',
  props: ['isCollapsed'],
  mounted () {
    //this.getPersonalInfo(token)
    this.userInfo.realName=JSON.parse(window.sessionStorage.getItem('user')).user_name;
    // this.getUnreadMessageNum()
  },
  data () {
    return {
      // 用户信息对话框可见性
      userInfoVisible: false,
      // 未读消息个数
      unreadMessageNum: Number,
      // 用户信息
      userInfo: {
        // tel: '15378142100',
        // mail: 'xx@yy.com',
        realName: '',
        userName: '',
        // roles: '角色'
      },

    }
  },
  methods: {
    // 退出登录
    logout () {
      // 清空sessionStorage
      window.sessionStorage.clear()
      // 强制导航到登录页面
      this.$router.push('/login')
    },
    // 获得当前未读消息数
    async getUnreadMessageNum () {
      const { data: res } = await this.$http.get('http://127.0.0.1:9528/message')
      if (res.meta.code === 200) {
        this.unreadMessageNum = res.data
      } else {
        this.$message.error(res.meta.message)
      }
    },
    // 前往查看通知消息页面
    gotoMessagePage () {
      this.$router.push('/message')
    },
    gotoPersonal(){
      this.$router.push('/info')
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
}
.divider{
  height: 8px;
}
</style>
