<!--该组件是用于显示模板主要信息的卡片-->
<template>
  <el-card
    class="template_card">
    <!--          模板名称-->
    <el-row class="template_title" justify="center" :gutter="20">
      {{templateInfo.templateName}}
    </el-row>
<!--    模板信息-->
    <el-row v-if="viewPublishStatus" justify="center">
      <el-tag size="mini" effect="dark" type="success" v-if="templateInfo.publicAccess">已公开</el-tag>
      <el-tag size="mini" effect="dark" type="warning" v-else>未公开</el-tag>
    </el-row>
    <el-row v-if="templateInfo.publicAccess" justify="center">
      <el-tag effect="dark" size="mini" type="danger" v-if="viewPublisher">模板作者: {{templateInfo.userName}}</el-tag>
    </el-row>
    <el-row justify="center">
      <el-col :offset="2" v-if="viewType">所属类别: {{templateInfo.contentKind}}</el-col>
      <el-col :offset="2" v-if="viewEditTime" >修改时间: {{templateInfo.editTime}}</el-col>
    </el-row>
    <!--          模板描述-->
    <el-divider style="margin: 10px"></el-divider>
    <el-scrollbar height="80px">
      <div class="template_desc">{{templateInfo.templateDesc}}</div>
    </el-scrollbar>
    <!--          操作按钮-->
    <el-divider style="margin: 10px"></el-divider>
    <el-row justify="center">
<!--      预览-->
      <el-button style="margin: 2px" type="success" size="mini" v-if="viewOperate" @click="preview">预览</el-button>
<!--      发布-->
      <el-popconfirm title="发布后，其他人能使用该模板，您确认发布吗？" @confirm="changePublicStatus" v-if="publishOperate&&!templateInfo.publicAccess">
        <template #reference>
          <el-button style="margin: 2px" type="info" size="mini">发布</el-button>
        </template>
      </el-popconfirm>
<!--      召回-->
      <el-popconfirm title="召回后其他人无法查看该模板，您确定召回吗？" @confirm="changePublicStatus" v-if="recallOperate&&templateInfo.publicAccess">
        <template #reference>
          <el-button style="margin: 2px" type="info" size="mini">召回</el-button>
        </template>
      </el-popconfirm>
<!--      使用-->
      <el-button style="margin: 2px" type="primary" size="mini" v-if="useOperate" @click="chose">使用</el-button>
<!--      编辑-->
      <el-button style="margin: 2px" type="primary" size="mini" v-if="editOperate" @click="edit">编辑</el-button>
<!--      删除-->
      <el-popconfirm title="操作不可撤回，请问您确定删除改模板吗?" @confirm="dele" v-if="deleteOperate">
        <template #reference>
          <el-button style="margin: 2px" type="danger" size="mini">删除</el-button>
        </template>
      </el-popconfirm>
    </el-row>
  </el-card>
</template>

<script>

export default {
  name: "TemplateCard",
  emits: ["previewDetail", "editTemplate", "deleteTemplate", "choseTemplate", "changePublicStatus"],
  props: {
    templateInfo: {
      type: Object,
      require: true
    },
    viewOperate: {
      type: Boolean,
      default: false
    },
    publishOperate: {
      type: Boolean,
      default: false
    },
    recallOperate: {
      type: Boolean,
      default: false
    },
    useOperate: {
      type: Boolean,
      default: false
    },
    deleteOperate: {
      type: Boolean,
      default: false
    },
    editOperate: {
      type: Boolean,
      default: false
    },
    viewPublisher: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: Boolean,
      default: true
    },
    viewEditTime: {
      type: Boolean,
      default: false
    },
    viewPublishStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 查看详情
    preview () {
      let info = JSON.stringify(this.templateInfo)
      this.$emit("previewDetail", info)
    },
    changePublicStatus() {
      let info = {
        templateId: this.templateInfo.templateId,
        publicAccess: !this.templateInfo.publicAccess
      }
      this.$emit("changePublicStatus", JSON.stringify(info))
    },
    dele () {
      let info = JSON.stringify(this.templateInfo)
      this.$emit("deleteTemplate", info)
    },
    edit () {
      let info = JSON.stringify(this.templateInfo)
      this.$emit("editTemplate", info)
    },
    chose () {
      let info = this.templateInfo.templateId
      this.$emit("choseTemplate", info)
    }
  }
}
</script>

<style scoped>
/*模板卡片*/
.template_card {
  width: 300px;
  height: auto;
  margin: 10px;
}
/*模板名称*/
.template_title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 6px;
}
/*模板描述*/
.template_desc {
  font-size: 12px;
}
/*模板海报*/
.template_poster{
  height: 50px;
}
</style>
