<template>
  <el-row justify="center" style="font-size: 25px; font-weight: bold; margin-bottom: 30px">欢迎使用众包标注平台！</el-row>
  <el-row justify="center" style="height: 100%">
    <el-carousel height="440px" indicator-position="outside" style="width: 95%; height: 100%">
      <el-carousel-item v-for="item in imgsUrl" :key="item">
        <el-image :src="item"></el-image>
      </el-carousel-item>
    </el-carousel>
  </el-row>
</template>

<script>
export default {
  name: 'HomePage',
  mounted () {
  },
  data () {
    return{
      imgsUrl: [
        '/imgs/homepage/1.png',
        '/imgs/homepage/2.png',
        '/imgs/homepage/3.png'
      ]
    }
  }
}
</script>

<style Lang="less" scoped>
.el-image {
  width: 100%;
  height: 100%;
}
</style>
