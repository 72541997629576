<template>
  <!--  顶部面包屑-->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }"
    >返回主页</el-breadcrumb-item
    >
    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
    <el-breadcrumb-item>验收任务</el-breadcrumb-item>
  </el-breadcrumb>
  <div style="width: 80%;margin-left: 50px; margin-top: 30px">
    <div>
      <div class="grid-content bg-purple">
<!--        表格-->
        <el-row justify="center">
          <el-table :data="tableData.filter((data) =>!search || data.name.toLowerCase().includes(search.toLowerCase()))"
                    style="width: 100%" border :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column align="center" label="发布日期" :show-overflow-tooltip="true" prop="task_publish_time" sortable width="160px" />
            <el-table-column align="center" label="任务" :show-overflow-tooltip="true" prop="task_title" sortable width="140px"/>
            <el-table-column align="center" label="单次领取数" :show-overflow-tooltip="true" prop="task_max_distribution"  sortable />
            <el-table-column align="center" label="标注次数" :show-overflow-tooltip="true" prop="task_mark_count" sortable width="110px"/>
            <el-table-column align="center" label="限制时间" :show-overflow-tooltip="true" prop="task_limit_time" sortable />
            <el-table-column align="center" label="状态" :show-overflow-tooltip="true" prop="task_status" sortable width="100">
            </el-table-column>
            <el-table-column align="center" width="70">
              <template #header>
                操作
                <!-- <el-input v-model="search" size="mini" placeholder="Search" /> -->
              </template>
              <template #default="scope">
                <!--                查看任务的领取信息记录-->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="查看任务的领取情况"
                  placement="top"
                  :enterable="false">
                  <el-button icon="el-icon-view" circle size="mini" type="primary" @click="handleDetail(scope.$index, scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
<!--        分页-->
        <el-row justify="center">
          <el-pagination style="margin-top: 1.25rem;" background layout="prev, pager, next" :page-size="leftParams.pageSize"
                         @current-change="currentChange1" :total="total1">
          </el-pagination>
        </el-row>
      </div>
    </div>
<!--    查看领取详情对话框-->
    <el-dialog v-model="detailVis" title="领取详情" width="80%" center>
        <div class="grid-content bg-purple">
          <el-table :data="tableData2.filter((data) =>!search || data.name.toLowerCase().includes(search.toLowerCase()))"
                    style="width: 100%" border :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column align="center" label="标注者" prop="user_name" sortable />
            <el-table-column align="center" label="样本数" prop="draw_num" sortable />
            <el-table-column align="center" label="采样数" prop="sample" sortable >

              <template v-slot:default="slotProps">
                <el-input-number v-model="slotProps.row.sample" :controls="false" style="width: 100%" @input="((val)=>{changeSample(val, slotProps.row.draw_id)})" />
              </template>
            </el-table-column>
            <el-table-column align="center" label="异常率(%)" prop="exception" sortable>
            <template #default="scope">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="scope.row.exception"
                :status="'exception'"
              />
            </template>
            </el-table-column>
            <el-table-column align="center" label="正确率(%)" prop="right_rate" sortable>
            <template #default="scope">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="scope.row.right_rate"
                status="success"
              />
            </template>
            </el-table-column>
            <el-table-column align="center" label="获得积分" prop="credit" sortable />

            <el-table-column align="center">
              <template #header>
                操作
              </template>
              <template #default="scope">
<!--                查看异常-->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="查看异常样本标注结果"
                  placement="top"
                  :enterable="false"
                >
                  <el-button circle size="mini"
                             icon="el-icon-view"
                             type="success"
                             :disabled="scope.row.draw_status == 'WX'||!(scope.row.draw_status == 'YWC' && scope.row.exception!=0)"
                             @click="handleExceptionList(scope.$index, scope.row)" >
                  </el-button>
                </el-tooltip>
<!--                开始抽样   -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="开始抽样"
                  placement="top"

                  :enterable="false"
                >
                  <el-button circle size="mini"
                             type="primary"
                             icon="el-icon-thumb"
                             :disabled="!(scope.row.draw_status == 'YWC' && scope.row.draw_status != 'WX')"
                             @click="handleSample(scope.$index, scope.row)" >
                  </el-button>
                </el-tooltip>
<!--                结束审核   -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="结束审核"
                  placement="top"
                  :enterable="false"
                >
                  <el-button circle size="mini"
                             type="danger"
                             icon="el-icon-check"
                             :disabled="!(scope.row.credit == undefined && scope.row.draw_status=='YSH')"
                             @click="closeSample(scope.$index, scope.row)" >
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-row justify="center">
            <el-pagination style="margin-top: 1.25rem;" background layout="prev, pager, next"
                           :page-size="rightParams.pageSize" @current-change="currentChange2" :total="total2">
            </el-pagination>
          </el-row>
        </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="detailVis = false">退出</el-button>
      </span>
      </template>
    </el-dialog>
  </div>

  <template v-if="dialogTaskExceptionVisible">
    <el-dialog
      center
      @closed="getDrawList"
      v-model="dialogTaskExceptionVisible"
      width="80%" destroy-on-close
    >
      <template #footer>
        <task-exception
          :button-enable="true"
          :templateInfo="currentTemplateInfo"
          :markInfoList="markInfoList"
          :current="current"
          :taskInfo="taskInfo">
        </task-exception>
        <el-row justify="center">
          <el-button size="mini" @click="dialogTaskExceptionVisible = false">未作弊</el-button>
          <el-button size="mini" @click="showUnpass" type="danger">判定作弊</el-button>
        </el-row>
      </template>
    </el-dialog>
  </template>

<template v-if="dialogUnpassVisible">
    <el-dialog
      center
      title="判定理由"
      v-model="dialogUnpassVisible"
      width="30%" destroy-on-close append-to-body
    >
      <el-row>
        <el-col>
          <el-input type="textarea" v-model="unpassReason" placeholder="请输入判定为作弊的原因"></el-input>
        </el-col>
      </el-row>
      <template #footer>
        <el-row justify="center">
          <el-button @click="dialogUnpassVisible = false">取消</el-button>
          <el-button @click="submitUnpass">确定</el-button>
        </el-row>
      </template>
    </el-dialog>
  </template>


  <template v-if="dialogTaskSampleVisible">
    <el-dialog
      center
      @closed="getDrawList"
      v-model="dialogTaskSampleVisible"
      width="80%" destroy-on-close append-to-body
    >
      <template #footer>
        <task-sample
          :templateInfo="sampleCurrentTemplateInfo"
          :markInfoList="sampleMarkInfoList"
          :current="sampleCurrent"
          :closeInfo="closeInfo"
          :taskInfo="sampleTaskInfo">
        </task-sample>
      </template>
    </el-dialog>
  </template>

</template>
<script>
import TaskException from '@/components/template/common/OneTemplate'
import TaskSample from './TaskSample'
  export default {
    components: {
    "task-exception": TaskException,
    "task-sample": TaskSample,
      closeInfo:{
        type: Object,
        default: {

        }
      },
  },
    data() {
      return {
        detailVis: false,
        tableData: [],
        total1: 1000,
        tableData2: [],
        total2: 10,
        search: '',
        leftParams: {
          currentPage: 1,
          pageSize: 5,
          keyword: ''
        },
        rightParams: {
          currentPage: 1,
          pageSize: 5,
          taskId: ''
        },
        form: {
          name: '',
          desc: '',
          number: '',
          reward: '',
          maxNumber: '',
          date1: '',
          date2: ''
        },
        dialogTaskExceptionVisible:false,
        currentTemplateInfo: {},
        markInfoList:[],
        current:0,
        taskInfo:{},
        title:'',
        dialogUnpassVisible:false,
        unpassReason:'',
        drawId:'',

        dialogTaskSampleVisible:false,
        sampleCurrentTemplateInfo: {},
        sampleMarkInfoList:[],
        sampleCurrent:0,
        sampleTaskInfo:{},
        closeInfo: ''
      }
    },
    created() {
      this.loadTaskList();
    },
    methods: {
      async closeSample(index,row){
        let userId=JSON.parse(window.sessionStorage.getItem("user")).user_id
        let data = {
          tdrawId:row.draw_id,
          userId:userId
        }
        // 调用后端接口
        //let _this = this;
        const { data: res } = await this.$http.get('/task/rightRate', {params: data})
        if (res.status == 200) {
          this.$message.success("结束成功")
        } else {
          this.$message.error(res.msg)
        }
        await this.getDrawList()
      },
      loadTaskList: function() {
        let _this = this;
        this.$http({
          method: "get",
          url: "/task/taskList?userId="+JSON.parse(window.sessionStorage.getItem("user")).user_id,
          params: this.leftParams
        }).then(function(result) {
          console.log(result.data)
          if (result.data.status == 200) {
            // _this.$message({
            //   showClose: true,
            //   message: '成功',
            //   type: 'success'
            // });
            _this.tableData = result.data.data.list
            _this.total1 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },
      // 右侧列表
      async handleDetail(index, row) {
        let _this = this;
        this.rightParams.taskId = row.task_id;
        // 根据id查询所有的右侧列表
        await this.getDrawList()
        this.detailVis = true
      },
      async getDrawList() {
        let _this = this
        await this.$http({
          method: "get",
          url: "/task/drawList",
          params: this.rightParams
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            _this.tableData2 = result.data.data.list
            _this.total2 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },
      // 右侧列表
      handleDetailHasTask(index, row) {
        let _this = this;
        // 根据id查询所有的右侧列表
        this.$http({
          method: "get",
          url: "/task/drawList",
          params: this.rightParams
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            _this.tableData2 = result.data.data.list
            _this.total2 = result.data.data.total
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },

      handleExceptionList(index, row) {
        this.title = '查询异常'
        this.drawId = row.draw_id
        let _this = this;
        this.$http({
          method: "get",
          url: "/task/queryException",
          params: {tdrawId:row.draw_id}
        }).then(function(result) {
          if (result.data.status == 200) {
            //_this.markInfoList = result.data.data
            if(result.data.data.length>0){
              var taskId = result.data.data[0].taskId
              var markIdList = []
              for(let i=0;i<result.data.data.length;i++){
                  markIdList.push(result.data.data[i].markId)
              }
              _this.handlecurrentTemplateInfo(taskId,markIdList)
            }else{
              _this.dialogTaskExceptionVisible = true
            }
          }
        })
        // let _this = this;
        // // 根据id查询所有的右侧列表
        // this.$http({
        //   method: "get",
        //   url: "/task/cancel",
        //   params: {
        //     "drawId": row.draw_id
        //   }
        // }).then(function(result) {
        //   if (result.data.status == 200) {
        //     _this.$message({
        //       showClose: true,
        //       message: '成功',
        //       type: 'success'
        //     });
        //     this.rightParams.currentPage = 1;
        //     this.handleDetailHasTask();
        //   } else {
        //     _this.$message({
        //       showClose: true,
        //       message: '很遗憾:' + result.data.msg,
        //       type: 'error'
        //     });
        //   }
        // })
      },
      handlecurrentTemplateInfo(taskId, markIdList) {
        const _this = this;
        //获取
        _this.$http({
          method: "get",
          url: "/task/getDrawDetailById",
          params: {taskId:taskId,markIdList:markIdList.join(',')}
        }).then(function(result) {
            console.log(result)
            if (result.data.status == 200) {
              let res = result.data.data
              let json = {
                  markInfoList:res.markInfoList
              }
              _this.markInfoList = res.markInfoList
              _this.currentTemplateInfo =res.template
               _this.taskInfo =res.taskInfo
              if(res.currentIndex != null){
                  _this.current =res.currentIndex
              }else{
                  _this.current =0
              }
              _this.string2Int()
            }
            _this.dialogTaskExceptionVisible = true
          })
      },
      showUnpass(){
        this.unpassReason = ''
          this.dialogUnpassVisible = true
      },
      async submitUnpass(){
        let data = {unpass_reason:this.unpassReason,tdrawId:this.drawId}
        // 调用后端接口
        const { data: res } = await this.$http.get('/task/updateDrawStatus', {params: data})
        if (res.status == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.dialogUnpassVisible = false
        this.dialogTaskExceptionVisible = false
      },
      handleSample(index, row) {
        let _this = this;
        // 根据id查询所有的右侧列表
        this.$http({
          method: "get",
          url: "/task/sample",
          params: {
            "drawId": row.draw_id,
            "sampleNum": row.sample
          }
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            if(result.data.data.length>0){
              var taskId = result.data.data[0].taskId
              var markIdList = []
              for(let i=0;i<result.data.data.length;i++){
                  markIdList.push(result.data.data[i].markId)
              }
              _this.closeInfo ={userId:row.user_id,tdrawId:row.draw_id,sampleNum:row.sample}
              _this.handlecurrentTemplateInfoSample(taskId,markIdList)
            }else{
              _this.dialogTaskSampleVisible = true
            }
          }
        })
      },
      handlecurrentTemplateInfoSample(taskId, markIdList) {
        const _this = this;
        //获取
        _this.$http({
          method: "get",
          url: "/task/getDrawDetailById",
          params: {taskId:taskId,markIdList:markIdList.join(',')}
        }).then(function(result) {
            console.log(result)
            if (result.data.status == 200) {
              let res = result.data.data
              _this.sampleMarkInfoList = res.markInfoList
              _this.sampleCurrentTemplateInfo =res.template
               _this.sampleTaskInfo =res.taskInfo
              if(res.currentIndex != null){
                  _this.sampleCurrent =res.currentIndex
              }else{
                  _this.sampleCurrent =0
              }
              _this.string2Int2()
            }
            _this.dialogTaskSampleVisible = true
          })
      },
      // 撤回
      handleCancel(index, row) {
        let _this = this;
        // 根据id查询所有的右侧列表
        this.$http({
          method: "get",
          url: "/task/cancel",
          params: {
            "drawId": row.draw_id
          }
        }).then(function(result) {
          if (result.data.status == 200) {
            _this.$message({
              showClose: true,
              message: '成功',
              type: 'success'
            });
            this.rightParams.currentPage = 1;
            this.handleDetailHasTask();
          } else {
            _this.$message({
              showClose: true,
              message: '很遗憾:' + result.data.msg,
              type: 'error'
            });
          }
        })
      },

      currentChange1: function(val) {
        this.leftParams.currentPage = val;
        this.loadTaskList();
        this.tableData2 = [];
      },

      currentChange2: function(val) {
        this.rightParams.currentPage = val;
        this.handleDetailHasTask();
      },
      string2Int () {
        // 处理attrValueList,把select的String改为int
        for (let i = 0; i < this.markInfoList.length; i++) {
          // 该样本的逐个attr（题目）
          for (let j = 0; j < this.markInfoList[i].markDetailList.length; j++) {
            // 只改select
            if (this.currentTemplateInfo.attrs[j].attrType == 'select') {
              // 每一个元素由String转为Number
              // 单选需要把数组转换为数字
              if (!this.currentTemplateInfo.attrs[j].attributeProperty.allowMulti) {
                this.markInfoList[i].markDetailList[j].attrValueList = parseInt(this.markInfoList[i].markDetailList[j].attrValueList[0])
              }
              // 多选只用转换类型即可
              else {
                for (let k = 0; k < this.markInfoList[i].markDetailList[j].attrValueList.length; k++) {
                  this.markInfoList[i].markDetailList[j].attrValueList[k] = parseInt(this.markInfoList[i].markDetailList[j].attrValueList[k])
                }
              }
            }
          }
        }
      },
      string2Int2 () {
        // 处理attrValueList,把select的String改为int
        for (let i = 0; i < this.sampleMarkInfoList.length; i++) {
          // 该样本的逐个attr（题目）
          for (let j = 0; j < this.sampleMarkInfoList[i].markDetailList.length; j++) {
            // 只改select
            if (this.sampleCurrentTemplateInfo.attrs[j].attrType == 'select') {
              // 每一个元素由String转为Number
              // 单选需要把数组转换为数字
              if (!this.sampleCurrentTemplateInfo.attrs[j].attributeProperty.allowMulti) {
                this.sampleMarkInfoList[i].markDetailList[j].attrValueList = parseInt(this.sampleMarkInfoList[i].markDetailList[j].attrValueList[0])
              }
              // 多选只用转换类型即可
              else {
                for (let k = 0; k < this.sampleMarkInfoList[i].markDetailList[j].attrValueList.length; k++) {
                  this.sampleMarkInfoList[i].markDetailList[j].attrValueList[k] = parseInt(this.sampleMarkInfoList[i].markDetailList[j].attrValueList[k])
                }
              }
            }
          }
        }
      },
    },
  }
</script>

<style scoped>

</style>
