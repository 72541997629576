<template>
  <div class="container">
      <div id="login">
    <div class="security-login" style="background-color: rgba(255, 255, 255, 0.5);">
      <div class="login_container_alpha"
        style="
          margin-top: 10%;
          width: 40%;
          min-width: 400px;
          max-width: 600px;
          box-shadow: rgba(0, 0, 0, 4) 5px 15px 20px;
          border-radius: 5px;
          padding: 20px;
          height: 380px;
        "
      >
        <div class="avatar_box">
          <img src="../../assets/imgs/logo.png" style="height: 100px;border-radius: 50px;margin-left:-5px;margin-top:-3px" />
        </div>

        <div class="login_title">
          <el-row type="flex" justify="center">
            众包数据标注平台
          </el-row>
        </div>

        <a-tabs v-model:activeKey="activeKey" centered style="margin-top: 15px">
          <a-tab-pane key="1" tab="密码登录">
            <a-form
              ref="formRef"
              :model="user"
              :rules="rules"
              :wrapper-col="wrapperCol"
            >
              <a-form-item name="u_id">
                <a-input v-model:value="user.u_id">
                  <template #prefix>昵称：</template>
                </a-input>
              </a-form-item>

              <a-form-item name="u_pwd">
                <a-input-password v-model:value="user.u_pwd">
                  <template #prefix>密码：</template>
                </a-input-password>
              </a-form-item>
              <a-form-item>
                <label
                  style="
                    font-size: 12px;
                    margin-bottom: 0px;
                    margin-top: -25px;
                    margin-left: 10px;
                    float: left;
                    font-family: 黑体;
                    color: #9f1119;
                    cursor: pointer;
                  "
                >
                  <input
                    name="remember"
                    type="checkbox"
                    id="remember"
                    style=""
                  />
                  记住密码</label
                >

                <div
                  @click="find"
                  style="
                    font-size: 12px;
                    margin-bottom: 0px;
                    margin-top: -25px;

                    font-family: 黑体;
                    float: right;
                    color: #9f1119;
                    cursor: pointer;
                  "
                >
                  忘记密码？
                </div>
              </a-form-item>

              <a-form-item
                :wrapper-col="wrapperCol"
                style="padding-bottom: 50px; margin-top: -30px"
              >
                <a-button
                  type="primary"
                  block
                  @click="login"
                  style="
                    background: #39c64e;
                    border: none;
                    width: 35%;
                    float: left;
                  "
                >登录</a-button
                >
                <a-button
                  type="default"
                  block
                  @click="register"
                  style="width: 35%; float: right"
                >注册</a-button
                >
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="邮箱登录" force-render>
            <a-form
              ref="formRef"
              :model="user"
              :rules="rules"
              :wrapper-col="wrapperCol"
            >
              <a-form-item name="u_eml">
                <a-input
                  v-model:value="user.u_eml"
                  type="email"
                  name="email"
                  id="emailAd"
                >
                  <template #prefix>邮箱：</template>
                </a-input>
              </a-form-item>

              <a-form-item name="code">
                <a-input v-model:value="user.code" id="code" name="code">
                  <template #prefix>验证码：</template>
                </a-input>
              </a-form-item>
            </a-form>
            <a-form-item> </a-form-item>
            <a-form-item
              :wrapper-col="wrapperCol"
              style="padding-bottom: 50px; margin-top: -30px"
            >
              <a-button
                :disabled="user.u_eml == ''"
                type="primary"
                id="sendCode"
                @click="sendMessage"
                block
                class="btn btn-primary"
                style="
                  background: #39c64e;
                  border: none;
                  width: 35%;
                  float: left;
                "
              >发送验证码</a-button
              >
              <a-button
                type="default"
                block
                @click="emailLogin"
                style="width: 35%; float: right"
              >登录</a-button
              >
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="xieyi">
        <div

          style="
          font-size: 15px;
          text-align: center;
          margin-top: 35%;
          color:#000000;
          font-family: 黑体;

        "
        >
          登录即代表同意
          <a
            @click="goToXieyi"
            target="_blank"
            style="cursor: pointer; font-size: 15px; color: #237119 ;"
          >《用户协议和隐私政策》</a
          >
        </div>
      </div>
      <div class="home-bottom">
        <div class="home-bottom-inform" style="text-align: center;font-size:10px">
          商务合作： 15236149131@xjtu.edu.cn
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;内容合作
          ：lkm@163.com/15236149131
        </div>
        <div class="home-bottom-inform" style="text-align: center;font-size:10px">
          京ICP备16021487号-5 京公网安备11010802027588号
        </div>
      </div>
    </div>



  </div>
  </div>
</template>
<script setup>
import { ref, reactive, watch } from "vue";
import { message } from 'ant-design-vue'
import { useRouter } from "vue-router";
import axios from 'axios'
import {post,get} from '../../main.js';
const router = useRouter(); //获取路由器
const activeKey = ref("1"); //ref函数用于将数封装为单向绑定（数据-》界面）
const formRef = ref(); //此变量会自动与元素中ref="formRef"产生关联，以后此变量就表示元素对象
const registerRef = ref();

//reactive函数用于将数据封装为双向绑定（数据《==》界面）
const user = reactive({
  u_id: "",
  u_pwd: "",
  u_eml: "",
  code: "",
});

const emlUser = reactive({
  u_eml: "",
  code: "",
});

const validatePwd2 = async (rule, value) => {
  if (value === "") {
    return Promise.reject("请输入确认密码！");
  } else if (value !== newUser.u_pwd) {
    return Promise.reject("两次输入密码不一致!");
  } else {
    return Promise.resolve();
  }
};

const rules = {
  u_id: [
    {
      required: true,
      message: "请输入昵称！",
      trigger: "blur",
    },
    {
      min: 3,
      max: 20,
      message: "昵称长度为3-20！",
      trigger: "blur",
    },
  ],
  u_name: [
    {
      required: true,
      message: "请输入姓名！",
      trigger: "blur",
    },
  ],
  u_pwd: [
    {
      required: true,
      message: "请输入密码！",
      trigger: "blur",
    },
  ],
  u_pwd2: [
    {
      validator: validatePwd2,
      trigger: "blur",
    },
  ],
};
const login = () => {
  var r_id = "";
  var user_temp = {
    u_id: user.u_id,
    u_pwd: encode64(user.u_pwd),
    //u_pwd: user.u_pwd,
  };
  window.sessionStorage.setItem('pwd',user_temp.u_pwd);
  formRef.value
    .validate()
    .then(() => {
      //then方法仅接收成功操作的结果
      post("/user/login/getRole", user_temp).then((res) => {
        r_id = res.data.data;
        console.log(r_id);

        post("/user/login", user_temp).then((res) => {

         if(res.data.message!="fail"){
           message.success("登录成功！");

           if (document.getElementById("remember").checked) {
             console.log("近到本地存储了");
             localStorage.setItem(user.u_id, user.u_pwd);
           }

           const token = res.data.data; //获取返回的令牌
           console.log(token);
           //将令牌存储于本地
           window.sessionStorage.setItem("userInfo",token);
           getPersonalInfo(token);

         }
         else{
            message.error("账号或密码错误！")
         }

        });
      });
    })
    .catch((error) => {
      message.error("输入信息缺失或不正确！");
    });
};

// 前端密码加密
var keyStr = 'ABCDEFGHIJKLMNOP' + 'QRSTUVWXYZabcdef' + 'ghijklmnopqrstuv' + 'wxyz0123456789+/' + '='
function encode64 (input) {
  var output = ''
  var chr1; var chr2; var chr3 = ''
  var enc1; var enc2; var enc3; var enc4 = ''
  var i = 0
  do {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4)
    chr1 = chr2 = chr3 = ''
    enc1 = enc2 = enc3 = enc4 = ''
  } while (i < input.length)
  return output
}

//发送验证码
const sendMessage = () => {
  //var emailAd = document.getElementById("emailAd");
  console.log("+++++++++++++++++++++++++" + user.u_eml);
  if (user.u_eml == "") {
    alert("邮箱不能为空");
    return;
  }
  var email_reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

  if (!email_reg.test(user.u_eml)) {
    alert("请输入正确的邮箱地址");
    return;
  }

  get("/user/login/getCode", { email: user.u_eml}).then((ref) => {
    message.success("验证码发送成功^ ^");
    console.log("进来了");
    if (ref.data.message == "success") {
      var wait = 60;
      var button = document.getElementById("sendCode");
      (function time(o) {
        if (wait == 0) {
          o.disabled = false;
          o.innerHTML = "发送验证码";
          wait = 60;
        } else {
          o.disabled = true;
          o.innerHTML = wait + "秒后";
          wait--;
          setTimeout(function () {
            time(o);
          }, 1000);
        }
      })(button);
    } else {
      console.log("data.failure");
    }
  });
};

const emailLogin = () => {
  var email = user.u_eml;
  var code = user.code;
  console.log(email);
  console.log(code);
  if (code.value == "") {
    alert("验证码不能为空");
    return;
  }
  get("/user/login/emailLogin",
      {
        email: user.u_eml,
        code: user.code,
      }
  ).then((ref) => {
    if (ref.data.message == "success") {
      getUidAndUpwd();
    } else {
      message.error("您的验证码错误");
      return;
    }
  });
};

const getUidAndUpwd = () => {
  var r_id = "";
  post("/user/login/getRole3", user).then((res) => {
    r_id = res.data;
    post("/user/login/email", user).then((res) => {
      message.success("登录成功！");
      const token = res.data.data; //获取返回的令牌

      //将令牌存储于本地
      window.sessionStorage.setItem("userInfo",token);
      getPersonalInfo(token);
      //router.push({ path: "/home" });
    });
  });
};

watch(
  () => user.u_id,
  (newVal) => {
    // console.log("==>",newVal);
    var t = localStorage.getItem(newVal);
    if (t != "" && t != null) {
      document.getElementById("remember").checked = true;
    } else {
      document.getElementById("remember").checked = false;
    }
    user.u_pwd = t || "";
  }
);

const find = () => {
  //window.location.href = "forget";
  router.push({ path: "/forget" });
};

const register = () => {
  //window.location.href = "register"
  // ;
  router.push({ path: "/register" });
};
//一行24列
const wrapperCol = {
  offset: 5,
  span: 14,
};
var currUserInfo = ref({});
const getPersonalInfo = (token) => {
  get("/user/personal",{token:token}).then((res) => {

    currUserInfo = res.data.data;
    console.log(currUserInfo);
    setCurrentUser(currUserInfo);
    console.log(window.sessionStorage.getItem('user'));
    toHome();
  });
};
const setCurrentUser = (user) =>{
  window.sessionStorage.setItem('user',JSON.stringify(user));
}
const toHome =()=>{
  router.push({ path: "/home" });
}
const goToXieyi = () => {
  router.push({ path: "/xieyi" });
};
</script>

<style scoped>
#login {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  min-width: 900px;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/imgs/login/bg1.png");
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.5);
}
.security-login {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.home-bottom {
  height: 42px;
  width: 100%;
  margin-top:3%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index:5;
  position:fixed;bottom:0;
}
.home-bottom-inform {
  font-size: 10px;
  align-content: center;
  /*color: #e79229;*/
}
.ant-tabs-tab-active .ant-tabs-tab {
  color: #9f1119;
}
.avatar_box {
  height: 105px;
  width: 105px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 6px;
  box-shadow: 0 0 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.login_title{
  margin-top: 60px;
  font-size: 25px;
  font-weight: bold;
  font-family: Microsoft YaHei;
  text-shadow: 1px 1px 0 #E4FFFF;
}
.login_container_alpha {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
.xieyi{
  z-index:6;
}
/*伪类选择器隐藏滚轮*/
::-webkit-scrollbar {
  display: none;
}
</style>
