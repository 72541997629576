import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import installAntd from './plugins/antd'
import axios from 'axios'
//导入echarts
import * as echarts from 'echarts'

const app = createApp(App)
app.use(installElementPlus)
app.use(installAntd)
app.use(echarts)
app.use(store).use(router).mount('#app')

// app.config.globalProperties.baseUrl="http://hydrion-qlz.nat300.top/"
//   app.config.globalProperties.baseUrl="http://localhost:8080/"
app.config.globalProperties.baseUrl="http://zbsj.hydrion-qlz.top:8080/"

// 全局配置axios
app.config.globalProperties.$http = axios.create({
  baseURL: app.config.globalProperties.baseUrl,
  //baseURL: 'http://localhost:8080/',
  timeout: 4000,
  // withCredentials: true
})

router.beforeEach((to, from, next) => {
  // ...
  if(to.path=='/login'||to.path=='/xieyi'||to.path=='/register'||to.path=='/forget'||to.path=='/verify'){
    next()
  }else{
    const token = window.sessionStorage.getItem('userInfo')
    if(token!=null){
      next()
    }
    else{
      next('/login')
    }
  }
})

export function get(url, params) {
  return app.config.globalProperties.$http.get(url, { params }).catch(e => {
    console.log(e);
  })
}

export function post(url, data) {
  return app.config.globalProperties.$http.post(url, data).catch(e => {
    console.log(e);
  })
}
