<template>
  <div>
    <!--  顶部面包屑-->
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/homepage' }"
        >返回主页</el-breadcrumb-item
      >
      <el-breadcrumb-item>任务管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的任务</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="height: 40px; margin-top: 25px">
      <el-select
        v-model="status"
        filterable
        placeholder="请选择"
        style="padding-left: 25px"
        clearable
        @change="changeStatus($event)"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <el-container style="flex-direction: row; flex-wrap: wrap">
      <el-card
        class="box-card"
        v-for="(item, index) in drawListFilter"
        :key="index"
      >
        <div class="clearfix">
          <span style="font-size: 17px">
            {{ item.task_title }}
            <el-tag :type="item.labelType" effect="dark" size="mini">
              {{ item.draw_status }}
            </el-tag>
          </span>
          <el-button
            type="primary"
            size="medium"
            style="
              float: right;
              padding: 3px 0;
              font-size: 14px;
              padding-left: 10px;
            "
            @click="lookMarkDetail(item)"
            :disabled="item.draw_status=='未完成' || item.draw_status=='超时退回'"
          >
            查看详情 <i class="el-icon-arrow-right el-icon--right"> </i>
          </el-button>
        </div>
        <div class="mytext item">
          {{ "发布者：" + item.user_name }}
        </div>
        <div class="text item">
          {{ "任务类型：" + item.task_kind }}
        </div>
        <div class="text item">
          {{ "每题积分奖励：" + item.task_award }}
        </div>
        <div class="text item">
          {{ "领取时间：" + item.start_time }}
        </div>
        <div class="text item">
          {{ "截止时间：" + item.expect_end_time }}
        </div>
        <div class="mytext item">
          {{ "任务要求：" + item.task_require }}
        </div>
        <div class="text item" title="lyn">
          {{ "任务描述：" + item.task_desc }}
        </div>
      </el-card>
    </el-container>

    <!-- 分页 -->
    <el-row justify="center" style="width: 100%; margin-top: 20px">
      <el-pagination
        v-model:currentPage="pageTaskList.pageNum"
        :page-sizes="[3, 6, 12, 18]"
        :page-size="pageTaskList.pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
    <!-- dialogTable -->
    <el-dialog
      title="标注详情"
      v-model="dialogTableVisible"
      width="860px"
      custom-class="height：500px;"
      @closed="restoreMarkPageNum()"
      :center="true"
    >
      <div class="dialog">
        <el-table :data="markDetail">
          <el-table-column
            type="index"
            label="序号"
            width="60"
            :index="indexMethod"
            align="center"
          >
          </el-table-column>

          <el-table-column
            property="start_time"
            label="开始时间"
            width="175"
            align="center"
          ></el-table-column>
          <el-table-column
            property="end_time"
            label="结束时间"
            width="175"
            align="center"
          ></el-table-column>
          <el-table-column
            property="warning"
            label="异常"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            property="mark_status"
            label="状态"
            width="125"
            align="center"
          ></el-table-column>
          <el-table-column width="205" align="center">
            <template #default="scope">
              <el-button
                type="index"
                icon="el-icon-search"
                circle
                style="height: 30px"
                @click="goToDetail(item,scope.row)"
                title="查看标注详情"
              ></el-button>
              <el-button
                type="index"
                icon="el-icon-chat-square"
                circle
                style="height: 30px"
                :disabled="scope.row.mark_status!='不通过'"
                @click="viewUnpassedReason(scope.row)"
                title="未通过原因"
              ></el-button>
            </template>

          </el-table-column>
        </el-table>
      </div>
      <!-- mark分页 -->
      <el-row justify="center" style="width: 100%; margin-top: 20px">
        <el-pagination
          v-model:currentPage="pageMarkList.pageNum"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageMarkList.pageSize"
          layout="sizes, prev, pager, next"
          :total="MarkTotal"
          @size-change="handleSizeChangeMark"
          @current-change="handleCurrentChangeMark"
        >
        </el-pagination>
      </el-row>
    </el-dialog>

    <!-- templateDialog -->
    <el-dialog
      title="标注详情"
      v-model="templateTableVisible"
      width="840px"
      custom-class="height：500px;"
      :center="true"
    >
      <one-template
        v-if="templateTableVisible"
        :templateInfo="drawDetailInfo.template"
        :taskInfo="drawDetailInfo.taskInfo"
        :markInfoList="drawDetailInfo.markInfoList"
        :current="drawDetailInfo.currentIndex"
      ></one-template>
    </el-dialog>

    <!-- unpassedReasonDialog -->
    <el-dialog
      title="未通过原因"
      v-model="unpassedReasonVisible"
      width="240px"
      custom-class="height：500px;"
      :center="true"
    >
     {{unpassedReason}}
    </el-dialog>
  </div>
</template>

<script>
import oneTemplate from "../../../template/common/OneTemplate.vue";

export default {
  components:{
    "one-template":oneTemplate
  },
  data() {
    return {
      unpassedReason:"",
      unpassedReasonVisible:false,
      templateTableVisible:false,
      drawDetailInfo:{},
      markDetail: [],
      dialogTableVisible: false,
      pageTaskList: {
        pageNum: 1,
        pageSize: 6,
      },
      pageMarkList: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 6,
      MarkTotal: 10,

      options: [
        {
          value: "未完成",
          label: "未完成",
        },
        {
          value: "已完成",
          label: "已完成",
        },
        {
          value: "已审核",
          label: "已审核",
        },
        {
          value: "无效",
          label: "无效",
        },
        {
          value: "超时退回",
          label: "超时退回",
        },
      ],
      status: "",
      drawList: [],
      drawListFilter: [], //用来展示的数组
      item: {},
      dictionaryType: {
        1: "图片",
        2: "文本",
        3: "音频",
        4: "视频",
      },
      dictionaryStatus: {
        WX: "无效",
        WWC: "未完成",
        YWC: "已完成",
        YSH: "已审核",
        CSTH: "超时退回",
      },
      dictionaryMarkStatus: {
        BTG: "不通过",
        DBZ: "待标注",
        DYS: "待验收",
        YTG: "已通过",
      },
      dictionaryWarning: {
        0: "否",
        1: "是",
      },
    };
  },
  methods: {
    async viewUnpassedReason(row){
      this.unpassedReasonVisible=true
      console.log(row)
      const res = await this.$http.get("/task/annotater/manageTask/getUnPassReason?markId="+row.mark_id)
      this.unpassedReason = res.data.message

    },
    changeStatus(event) {
      console.log(event);
      if (event == "") {
        this.drawListFilter = this.drawList;
      } else {
        // 否则给根据event去筛选
        this.drawListFilter = this.drawList.filter(
          (item, index) => item.draw_status == event
        );
      }
    },
    indexMethod(index) {
      return (
        index + 1 + (this.pageMarkList.pageNum - 1) * this.pageMarkList.pageSize
      );
    },
    restoreMarkPageNum() {
      this.pageMarkList.pageNum = 1;
    },
    async lookMarkDetail(item) {
      this.dialogTableVisible = true;
      this.item = item;
      const drawDetailRes=await this.$http.get("/task/annotater/labelTask/getDrawDetail?drawId=" + item.draw_id);
      this.drawDetailInfo=drawDetailRes.data.data
      console.log(this.drawDetailInfo)
      this.string2Int()

      const res = await this.$http.post(
        "/task/annotater/manageTask/markDetail?draw_id=" + item.draw_id,
        this.pageMarkList
      );

      console.log(item.draw_id);
      console.log(res);
      const markDetail = res.data.data;

      this.MarkTotal = Number(res.data.message);

      markDetail.forEach((item, index) => {
        console.log(item.mark_status);
        item.mark_status = this.dictionaryMarkStatus[item.mark_status];
        item.warning = this.dictionaryWarning[item.warning];
      });

      this.markDetail = res.data.data;
    },
    string2Int () {
      // 处理attrValueList,把select的String改为int
      for (let i = 0; i < this.drawDetailInfo.markInfoList.length; i++) {
        // 该样本的逐个attr（题目）
        for (let j = 0; j < this.drawDetailInfo.markInfoList[i].markDetailList.length; j++) {
          // 只改select
          if (this.drawDetailInfo.template.attrs[j].attrType == 'select') {
            // 每一个元素由String转为Number
            // 单选需要把数组转换为数字
            if (!this.drawDetailInfo.template.attrs[j].attributeProperty.allowMulti) {
              this.drawDetailInfo.markInfoList[i].markDetailList[j].attrValueList = parseInt(this.drawDetailInfo.markInfoList[i].markDetailList[j].attrValueList[0])
            }
            // 多选只用转换类型即可
            else {
              for (let k = 0; k < this.drawDetailInfo.markInfoList[i].markDetailList[j].attrValueList.length; k++) {
                this.drawDetailInfo.markInfoList[i].markDetailList[j].attrValueList[k] = parseInt(this.drawDetailInfo.markInfoList[i].markDetailList[j].attrValueList[k])
              }
            }
          }
        }
      }
    },
    // 当页面大小变化时触发
    handleSizeChange(newSize) {
      this.pageTaskList.pageSize = newSize;
      this.init();
    },
    // 当页面编号变化时触发
    handleCurrentChange(newPage) {
      this.pageTaskList.pageNum = newPage;
      this.init();
    },
    // 当页面大小变化时触发查看mark
    handleSizeChangeMark(newSize) {
      this.pageMarkList.pageSize = newSize;
      this.lookMarkDetail(this.item);
    },
    // 当页面编号变化时触发查看mark
    handleCurrentChangeMark(newPage) {
      this.pageMarkList.pageNum = newPage;
      this.lookMarkDetail(this.item);
    },
    async init() {
      const res = await this.$http.post(
        "/task/annotater/manageTask/MyTask?userId=" +
          JSON.parse(window.sessionStorage.getItem("user")).user_id,
        this.pageTaskList
      );

      // const data = {
      //   taskTitle: item.task_title,
      //   taskType: item.task_kind,
      //   publisher: item.publisher,
      //   taskReward: item.task_award,
      //   taskDesc: item.task_desc,
      //   taskReq: item.task_require,
      // };
      // const res = await this.$http.get(
      //   "/task/annotater/manageTask/TaskList",
      //   data
      // );

      console.log(res);
      const drawList = res.data.data;
      this.total = Number(res.data.message);

      drawList.forEach((item, index) => {
        console.log(item.draw_status);
        item.task_kind = this.dictionaryType[item.task_kind];
        item.draw_status = this.dictionaryStatus[item.draw_status];
        console.log(item.draw_status);
      });

      console.log(drawList);
      this.drawList = drawList;

      //从后端请求数据赋值给drawList;
      this.drawListFilter = this.drawList;
      this.drawList.forEach((item) => {
        if (item.draw_status == "未完成") {
          item.labelType = "warning";
        } else if (item.draw_status == "已完成") {
          item.labelType = "primary";
        } else if (item.draw_status == "已审核") {
          item.labelType = "success";
        } else if (item.draw_status == "无效") {
          item.labelType = "info";
        } else if (item.draw_status == "超时退回") {
          item.labelType = "error";
        }
      });
      console.log(this.drawList);
    },
    goToDetail(item,row){
      console.log(this.markDetail)
      this.markDetail.forEach((markInfo,index)=>{
        console.log(markInfo.markId,row.mark_id)
        if (markInfo.mark_id == row.mark_id){
          this.drawDetailInfo.currentIndex = index;
        }
      })
      console.log("===================="+this.drawDetailInfo.currentIndex )

      console.log(item,row)
      this.templateTableVisible=true
    },

  },
  async created() {
    this.init();
  },
};
</script>

<style>
.text {
  font-size: 16px;
  padding: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*想省略几行就写几*/
  -webkit-box-orient: vertical;
}

.item {
  margin-bottom: 1px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 380px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 30px;
  height: 300px;
}
.dialog {
  height: 54vh;
  overflow: auto;
}
</style>
