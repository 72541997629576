<!--该组件用于渲染单独一个任务的信息-->
<template>
  <div>
<!--    任务标题-->
    <el-row justify="center" class="task_title">{{taskInfo.taskTitle}}</el-row>
<!--    任务类别，发布者，奖励-->
    <el-row justify="center" gutter="10">
      <el-col span="6"><el-tag type="success" effect="dark">{{taskInfo.taskType}}</el-tag></el-col>
      <el-col span="6"><el-tag type="primary" effect="dark">{{taskInfo.publisher}}</el-tag></el-col>
      <el-col span="6"><el-tag type="danger" effect="dark">{{taskInfo.taskReward}}积分/样本</el-tag></el-col>
    </el-row>
<!--    任务描述-->
   <el-divider style="font-size: 15px">任务描述</el-divider>
    <el-scrollbar :height="80">
      <el-row style="margin-left: 30px; margin-right: 30px">{{taskInfo.taskDesc}}</el-row>
    </el-scrollbar>
<!--    任务要求-->
   <el-divider style="font-size: 15px">标注要求</el-divider>
    <el-scrollbar :height="60">
      <el-row style="margin-left: 30px; margin-right: 30px">{{taskInfo.taskReq}}</el-row>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'TaskInfo',
  props: {
    taskInfo: {
      type: Object,
      default: {
        taskTitle: "猫狗二分类数据标注",
        taskType: "图片分类",
        publisher: "西安交通大学软件学院",
        taskReward: 10,
        taskDesc: "如果你能够在规定时间内完成标注任务，您将获得丰厚的积分奖励，同时有机会与本系统开发团队面对面交流哦！",
        taskReq: "请您认真批注，一旦发现作弊，将不予结算积分，同时全平台通报~",
      }
    }
  }
}
</script>

<style scoped>
.task_title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
