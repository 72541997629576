<template>
  <!--  顶部面包屑-->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/homepage' }"
    >返回主页</el-breadcrumb-item
    >
    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
    <el-breadcrumb-item>创建任务</el-breadcrumb-item>
  </el-breadcrumb>
  <!--    步骤条-->
  <el-row justify="center" style="margin-top: 10px; margin-bottom: 20px; width: 100%">
    <el-steps :active="active" finish-status="success" :space="400" align-center>
      <el-step @click="active=0" title="步骤一" description="请填写该任务的相关信息"></el-step>
      <el-step @click="active=1" title="步骤二" description="为该任务选择一个合适的标注模板"></el-step>
      <el-step @click="active=2" title="步骤三" description="上传需要标注的数据文件"></el-step>
    </el-steps>
  </el-row>
    <el-row justify="center">
        <!--步骤一-->
        <div v-show="active === 0" style="width: 70%">
          <el-form label-width="180px" style="padding: 20px; width: 100%">
            <el-form-item label="任务标题">
              <el-input
                v-model="taskTitle"
                maxlength="20"
                show-word-limit
                placeholder="请输入任务标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="样本类型">
              <el-select v-model="type" placeholder="请选择标注的数据类型">
                <el-option label="图像" value="1"></el-option>
                <el-option label="文本" value="2"></el-option>
                <el-option label="语音" value="3"></el-option>
                <el-option label="视频" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务需求">
              <el-input
                v-model="taskRequire"
                maxlength="50"
                show-word-limit
                placeholder="请输入任务需求"
              ></el-input>
            </el-form-item>
            <el-form-item label="任务描述">
              <el-input
                v-model="taskDesc"
                type="textarea"
                maxlength="70"
                show-word-limit
                placeholder="请输入任务描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="每题积分">
              <el-input
                v-model="taskAward"
                placeholder="请输入完成每题后，标注着将获得的获得积分(单位：积分)"
              ></el-input>
            </el-form-item>
            <el-form-item label="标注限时">
              <el-input
                v-model="taskLimitTime"
                placeholder="请输入每次领取的标注的限制完成时间（小时）"
              ></el-input>
            </el-form-item>
            <el-form-item label="标注次数">
              <el-input
                v-model="taskMarkCount"
                placeholder="请输入单个样本需要被标注的次数"
              ></el-input>
            </el-form-item>
            <el-form-item label="领取数量">
              <el-input
                v-model="taskMaxDistribution"
                placeholder="请输入每次领取时分发的样本数量"
              ></el-input>
            </el-form-item>
            <el-form-item label="异常时间">
              <el-input
                v-model="taskExceptionTime"
                placeholder="请输入每个样本标注时，需要的最短用时(s)，超过后将自动标为异常"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row justify="center">
                <el-button size="mini" type="primary" @click="next">下一步骤</el-button>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
        <!--步骤二-->
        <div v-show="active === 1" style="width: 100%">
          <el-row justify="center">
            <el-collapse v-model="activeName" accordion style="width: 90%">
              <el-collapse-item name="1">
                <template #title>
                  <div @click="viewPublicTemplate" style="font-size: 18px; font-weight: bold">常用模板</div>
                </template>
                <router-view @choseTemplate="choosedTemplate"></router-view>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template #title>
                  <div @click="viewPrivateTemplate" style="font-size: 18px; font-weight: bold">个人模板</div>
                </template>
                <router-view @choseTemplate="choosedTemplate"></router-view>
              </el-collapse-item>
            </el-collapse>
          </el-row>
          <el-row justify="center">
            <el-button size="mini" type="danger" @click="before">上一步骤</el-button>
            <el-button size="mini" type="primary" @click="next">下一步骤</el-button>
          </el-row>
        </div>
        <!--步骤三-->
        <div v-show="active === 2"
          class="grid-content bg-purple"
        >
          <el-row justify="center">
            <el-form label-width="120px" style="padding: 20px">
              <el-form-item>
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  accept=".zip"
                  :action="uploadPath"
                  list-type="list"
                  :auto-upload="true"
                  :on-success="showResult"
                >
                  <el-button slot="trigger" size="middle" type="info">选择标注内容的文件或图片</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-row justify="center">
                  <el-button size="mini" type="danger" @click="before">上一步骤</el-button>
                </el-row>
              </el-form-item>
            </el-form>
          </el-row>
        </div>
        <!--显示发布结果-->
        <div v-show="active === 3" style="width: 100%">
          <result status="success"
                  title="创建任务成功"
                  sub-title="恭喜，您已成功新建任务并上传了需要标注的数据！"
                  view-button
                  button-content="去看看该任务"
                  enable-route
                  route-path="/task/all"
          ></result>
        </div>
    </el-row>
<!--  选择模板后，发布任务对话框-->
  <el-dialog
    v-model="publishVis"
    title="发布任务"
    width="30%"
    center>
    您已完成所有步骤，请问您确认发布任务吗？发布完成后，请继续在下以步骤中上传您需要标注的数据文件！
    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="publishVis = false" type="info">暂不发布</el-button>
        <el-button size="mini" type="success" @click="publishTask">发布任务</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Result from "@/components/utils/Result"
export default {
  components: {
    "result": Result
  },
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      active: 0,
      activeName: "",
      type: "",
      publisher: "2", //发布者
      fileList: [],
      taskTitle: "",
      taskRequire: "",
      taskDesc: "",
      taskLimitTime: "",
      taskAward: "",
      taskMarkCount: "",
      taskMaxDistribution: "",
      taskExceptionTime: "",
      templateId: "1",
      publishVis: false,
      uploadPath:this.baseUrl+"uploadFile"
    };
  },
  methods: {
    /*添加任务*/
    async publishTask() {
      //this.templateId=window.sessionStorage.getItem("templateId")
      let form = new FormData();
      form.append("publisher", JSON.parse(window.sessionStorage.getItem('user')).user_id);
      form.append("type", this.type);
      form.append("templateId", this.templateId);
      form.append("taskTitle", this.taskTitle);
      form.append("taskDesc", this.taskDesc);
      form.append("taskAward", this.taskAward);
      form.append("taskRequire", this.taskRequire);
      form.append("taskLimitTime", this.taskLimitTime);
      form.append("taskMarkCount", this.taskMarkCount);
      form.append("taskMaxDistribution", this.taskMaxDistribution);
      form.append("taskExceptionTime", this.taskExceptionTime);
      for (let i=0;i<this.fileList.length;i++){
        form.append("files",this.fileList[i].raw,this.fileList[i].name);
      }
      let _this = this;
      await this.$http({
        method: "post",
        url: "/task/addTask",
        headers:{
          'Content-type':'multipart/form-data'
        },
        data: form,
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.$message({
            showClose: true,
            message: "恭喜你:" + result.data.msg,
            type: "success",
          });
          let TYPE = {
            1:'img',
            2:'text',
            3:'audio',
            4:'video'
          }
          _this.uploadPath = _this.uploadPath + '?taskId=' + result.data.data + '&type=' +TYPE[_this.type]
          _this.active++;
        } else {
          _this.$message({
            showClose: true,
            message: "很遗憾:" + result.data.msg,
            type: "error",
          });
        }
        // 关闭对话框
        _this.publishVis = false
      });
    },
    next() {
      //下一步
      if (this.active++ > 2) this.active = 0;
    }, //上一步
    before() {
      if (this.active-- > 2) this.active = 2;
      console.log(this.templateId);
    },
     back() {
      this.active = 0;
    },
    pre(file, fileList) {
      console.log(file.raw);
      this.fileList.push({"name":file.name,"raw":file.raw});
    },
    choosedTemplate(templateId) {
      this.templateId = templateId
      this.publishVis = true
    },
    viewPublicTemplate() {
      this.$router.push({
        path: '/task/create/choosePublicTemplate',
        query: {isChoose: true}
      })
    },
    viewPrivateTemplate() {
      this.$router.push({
        path: '/task/create/choosePrivateTemplate',
        query: {isChoose: true}
      })
    },
    showResult () {
      this.active ++
    }
  }
};
</script>

<style>
.image {
  width: 100%;
  display: block;
}
</style>
