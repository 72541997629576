<template>
  <div v-if="readyFlag">
    <one-template
      annotate="ture"
      :task-info="taskInfo"
      :draw-id="drawId"
      :current="currentIndex"
      :mark-info-list="markInfoList"
      :template-info="templateInfo"></one-template>
  </div>
</template>

<script>
import OneTemplate from "@/components/template/common/OneTemplate"
export default {
  name: "AnnotateTask",
  components: {
    "one-template": OneTemplate
  },
  async mounted() {
    this.drawId =  this.$route.query.drawId
    const { data: res } = await this.$http.get("/task/annotater/labelTask/getDrawDetail?drawId=" + this.drawId)
    if (res.code == 200 ) {
      this.$message.success('获取标注内容信息成功！')
      // 处理taskInfo
      this.taskInfo = {
        taskTitle: res.data.taskInfo.taskTitle,
        taskType: res.data.taskInfo.taskKind,
        publisher: res.data.taskInfo.publisher,
        taskReward: res.data.taskInfo.taskAward,
        taskDesc: res.data.taskInfo.taskDesc,
        taskReq: res.data.taskInfo.taskRequire,
        taskExceptionTime: res.data.taskInfo.taskExceptionTime // 单位为：s
      }
      this.templateInfo = res.data.template
      this.markInfoList = res.data.markInfoList
      this.currentIndex = res.data.currentIndex
      // 为还未标注过的每一个样本添加markDetailList骨干信息
      for (let i = this.currentIndex; i < this.markInfoList.length; i++){
        // 处理markDetailList,为其添加骨架
        let markDetailList = []
        for (let j = 0; j < this.templateInfo.attrs.length; j++){
          let markDetailItem = {
            markId: this.markInfoList[i].markId,
            attrName: this.templateInfo.attrs[j].attrName,
            attrNameId: this.templateInfo.attrs[j].attrId,
            attrValueList: [], // 存放标注结果的位置,
            attrType: this.templateInfo.attrs[j].attrType
          }
          markDetailList.push(markDetailItem)
        }
        this.markInfoList[i].markDetailList = markDetailList
      }
      // String to Int
      this.string2Int()
      this.readyFlag = true
    }
    else {
      this.$message.error('获取标注内容信息失败！')
    }
  },
  data () {
    return {
      readyFlag: false,
      drawId: '',
      taskInfo: {},
      templateInfo: {},
      markInfoList: [],
      currentIndex: undefined, // 本次标注开始时的第一个未标注样本位置,
      exceptionTime: null
    }
  },
  methods: {
    // 将String转为Int
    string2Int () {
      // 处理attrValueList,把select的String改为int
      for (let i = 0; i < this.currentIndex; i++) {
        // 该样本的逐个attr（题目）
        for (let j = 0; j < this.markInfoList[i].markDetailList.length; j++) {
          // 只改select
          if (this.templateInfo.attrs[j].attrType == 'select') {
            // 每一个元素由String转为Number
            // 单选需要把数组转换为数字
            if (!this.templateInfo.attrs[j].attributeProperty.allowMulti) {
              this.markInfoList[i].markDetailList[j].attrValueList = parseInt(this.markInfoList[i].markDetailList[j].attrValueList[0])
            }
            // 多选只用转换类型即可
            else {
              for (let k = 0; k < this.markInfoList[i].markDetailList[j].attrValueList.length; k++) {
                this.markInfoList[i].markDetailList[j].attrValueList[k] = parseInt(this.markInfoList[i].markDetailList[j].attrValueList[k])
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
